import { render, staticRenderFns } from "./PushNotificationsTable.vue?vue&type=template&id=53971b24&scoped=true&"
import script from "./PushNotificationsTable.vue?vue&type=script&lang=js&"
export * from "./PushNotificationsTable.vue?vue&type=script&lang=js&"
import style0 from "./PushNotificationsTable.vue?vue&type=style&index=0&id=53971b24&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53971b24",
  null
  
)

export default component.exports