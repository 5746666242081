<template>
  <v-main class="whiteBackground">
    <v-card flat class="adminVerificationCard">
      <div class="adminVerificationCardHeader">
        <h2>{{ adminKyc.firstName }}</h2>

        <p>{{ userMeta.tierCategory }}</p>
      </div>

      <!-- cycle through KYCs -->
      <div class="kyclist">
        <div v-for="(kyc, i) in kycs" :key="i">
          <div class="kycListItem">
            <div>
              <h4>
                {{ kyc.title }}
                <v-chip small :class="kyc.status.toLowerCase()">{{
                  kyc.status.toUpperCase()
                }}</v-chip>
              </h4>
              <div v-if="kyc.title == 'Utility Bill'">
                <v-btn
                  v-if="kyc.data != null"
                  class="my-3"
                  outlined
                  color="#0582d2"
                  @click="showUtilityBill"
                  >{{ buttonText }}</v-btn
                >
                <img
                  v-if="showBill"
                  width="400px"
                  :src="'data:image/jpg;base64,' + kyc.data"
                />
              </div>

              <div class="mb-4" v-if="kyc.title == 'Business Information'">
                <v-btn
                  v-if="kyc.data != null"
                  class="my-3"
                  outlined
                  color="#0582d2"
                  @click="showBusinessPhoto"
                  >{{ businessText }}</v-btn
                >
                <img
                  v-if="showBusiness"
                  width="400px"
                  :src="'data:image/png;base64,' + kyc.data"
                />

                <div class="businessDetails">
                  <p class="font-weight-medium">Business Name</p>
                  <p>{{ kyc.businessName }}</p>
                </div>

                <div class="businessDetails">
                  <p class="font-weight-medium">Address</p>
                  <p>{{ kyc.address }}</p>
                </div>

                <div class="businessDetails">
                  <p class="font-weight-medium">State, City, LGA</p>
                  <p>{{ kyc.state }} {{ kyc.city }} {{ kyc.lga }}</p>
                </div>
              </div>
            </div>

            <div
              v-if="
                kyc.title == 'Utility Bill' ||
                kyc.title == 'Business Information'
              "
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="black"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    text
                    :loading="verifyloading"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="verifyBill(kyc.title)">
                    Verify
                  </v-list-item>
                  <v-list-item @click="declineBill(kyc.title)">
                    Decline
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <v-dialog persistent v-model="actionutil" width="580px">
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card class="pa-6">
            <div class="my-3 d-flex justify-space-between">
              <h2>{{ actionTitle }} Reason</h2>
              <v-btn fab small depressed @click="actionutil = !actionutil"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>

            <v-text-field
              style="height: 55px"
              v-model="reason"
              dense
              outlined
              :placeholder="
                actionTitle == 'Approve'
                  ? 'e.g the utility bill matches user details'
                  : 'e.g the utility bill does not match user details'
              "
            ></v-text-field>
            <span class="red--text">{{ error }}</span>
            <div class="d-flex justify-end mb-4">
              <v-btn
                @click="actionutil = !actionutil"
                outlined
                large
                color="black"
                class="mr-2 mb-2"
                >Close</v-btn
              >
              <v-btn
                :loading="verifyloading"
                @click="actionBill()"
                large
                color="#0582d2"
                class="white--text"
                >{{ actionTitle }}</v-btn
              >
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <successScreen
            :buttonText="'Continue'"
            @closeSuccess="closeSuccess"
            :title="'Utility bill ' + actionTitle + 'd successfully'"
            :description="
              'You have successfully ' + actionTitle + 'd this utility bill'
            "
          />
        </v-window-item>
      </v-window>
    </v-dialog>

    <v-dialog persistent v-model="businessutil" width="580px">
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card class="pa-6">
            <div class="my-3 d-flex justify-space-between">
              <h2>{{ actionTitle }} Reason</h2>
              <v-btn fab small depressed @click="businessutil = !businessutil"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>

            <v-text-field
              style="height: 55px"
              v-model="reason"
              dense
              outlined
              :placeholder="
                actionTitle == 'Approve'
                  ? 'e.g the Business matches user details'
                  : 'e.g the Business does not match user details'
              "
            ></v-text-field>
            <span class="red--text">{{ error }}</span>
            <div class="d-flex justify-end mb-4">
              <v-btn
                @click="businessutil = !businessutil"
                outlined
                large
                color="black"
                class="mr-2 mb-2"
                >Close</v-btn
              >
              <v-btn
                :loading="verifyloading"
                @click="actionBusiness()"
                large
                color="#0582d2"
                class="white--text"
                >{{ actionTitle }}</v-btn
              >
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <successScreen
            :buttonText="'Continue'"
            @closeSuccess="businessutil = false"
            :title="'Business registration ' + actionTitle + 'd successfully'"
            :description="
              'You have successfully ' + actionTitle + 'd this business'
            "
          />
        </v-window-item>
      </v-window>
    </v-dialog>
  </v-main>
</template>



<script>
import axios from "axios";
import successScreen from "@/components/Modal/successScreen.vue";
import { HEADERS, POSTURL } from "@/constants/constants";

export default {
  data: () => ({
    level: "Level 1",
    kycs: [
      { title: "Phone Number", status: "verified", data: "2348121172330" },
      {
        title: "Email address",
        status: "verified",
        data: "clipscenaedu@gmail.com",
      },
      { title: "BVN", status: "pending", data: "No available data" },
      {
        title: "Government-issued ID",
        status: "pending",
        data: "International_passport.jpg",
        link: "link",
      },
      {
        title: "Live Photo",
        status: "pending",
        data: "Live_photo.jpg",
        link: "link",
      },
      {
        title: "Utility Bill",
        status: "pending",
        data: "Nepa_bill.jpg",
        link: "link",
      },
      {
        title: "Business Information",
        status: "pending",
        data: "Certificate.jpg",
        link: "link",
        businessName: "Fidget Media",
        address: "Km 7, rainbow busstop",
        state: "Lagos",
        city: "Ikeja",
        lga: "Alapere",
      },
    ],
    showBill: false,
    showBusiness: false,
    buttonText: "Show Utility Bill",
    businessText: "Show Business Photo",
    verifyloading: false,
    actionTitle: "",
    actionutil: false,
    reason: "",
    businessutil: false,
    error: "",
    step: 1,
    userMeta: JSON.parse(sessionStorage.getItem("selectedUser")),
  }),
  props: {
    adminKyc: {
      type: Object,
    },
  },
  components: {
    successScreen,
  },

  mounted() {
    this.kycs = [
      {
        title: "Phone Number",
        status: "verified",
        data: this.adminKyc.phoneNumber,
      },
      {
        title: "Email address",
        status: this.adminKyc.emailVerification,
        data: this.adminKyc.email,
      },
      { title: "BVN", status: this.adminKyc.bvnVerification, data: "" },
      {
        title: "Government-issued ID",
        status: this.adminKyc.govtIdVerification,
        data: "",
        link: "",
      },
      {
        title: "Live Photo",
        status: this.adminKyc.livePhotoVerification,
        data: "",
        link: "",
      },
      {
        title: "Utility Bill",
        status: this.adminKyc.utilityBillVerification,
        data: this.adminKyc.uploadedUtilBill,
        link: "",
      },
      {
        title: "Business Information",
        status:
          this.adminKyc.livePhotoVerification || this.adminKyc.cacVerification,
        data: this.adminKyc.livePhoto,
        link: "",
        businessName: "",
        address: "",
        state: "",
        city: "",
        lga: "",
      },
    ];
  },

  methods: {
    verifyBill(type) {
      if (type === "Utility Bill") {
        this.actionutil = true;
        this.actionTitle = "Approve";
      } else {
        this.businessutil = true;
        this.actionTitle = "Approve";
      }
    },

    declineBill(type) {
      if (type === "Utility Bill") {
        this.actionutil = true;
        this.actionTitle = "Decline";
      } else {
        this.businessutil = true;
        this.actionTitle = "Decline";
      }
    },

    actionBill() {
      if (this.reason == "") {
        this.error = "You need to enter a reason";
        return;
      }
      this.verifyloading = true;
      axios({
        method: "POST",
        url: POSTURL + "profiling/walletmgt/validate-util-bill",
        headers: HEADERS,
        data: {
          walletNo: this.adminKyc.phoneNumber,
          actionCode: this.actionTitle == "Decline" ? 0 : 1,
          actionDesc: this.reason,
        },
      })
        .then(() => {
          this.verifyloading = false;
          this.step++;
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch(() => {
          this.$swal({
            icon: "warning",
            title: "Utility bill not " + this.actionTitle + "d",
            text:
              "An error occurred while trying to " +
              this.actionTitle +
              " this Utility bill. Try again later",
            confirmButtonText: `<p class="brandcolor">Ok</p>`,
          });
          this.verifyloading = false;
        });
    },

    actionBusiness() {
      if (this.reason == "") {
        this.error = "You need to enter a reason";
        return;
      }
      this.verifyloading = true;
      alert("ah")
      axios({
        method: "POST",
        url: POSTURL + "profiling/walletmgt/validate-tier4-livephoto",
        headers: HEADERS,
        data: {
          walletNo: this.adminKyc.phoneNumber,
          actionCode: this.actionTitle == "Decline" ? 0 : 1,
          actionDesc: this.reason,
        },
      })
        .then(() => {
          this.verifyloading = false;
          this.step++;
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch(() => {
          this.$swal({
            icon: "warning",
            title: "Business Registration not " + this.actionTitle + "d",
            text:
              "An error occurred while trying to " +
              this.actionTitle +
              " this Business. Try again later",
            confirmButtonText: `<p class="brandcolor">Ok</p>`,
          });
          this.verifyloading = false;
        });
    },

    showUtilityBill() {
      this.showBill = !this.showBill;
      if (this.showBill == true) {
        this.buttonText = "Hide Utility Bill";
        this.businessText = "Hide Business Photo";
      } else {
        this.buttonText = "Show Utility Bill";
        this.businessText = "Show Business Photo";
      }
    },

    showBusinessPhoto() {
      this.showBusiness = !this.showBusiness;
      if (this.showBusiness == true) {
        this.businessText = "Hide Business Photo";
      } else {
        this.businessText = "Show Business Photo";
      }
    },
    closeSuccess() {
      this.actionutil = !this.actionutil;
      this.location.reload();
    },
  },
};
</script>

<style scoped>
.whiteBackground {
  background: white;
}
.adminVerificationCard {
  border: 1px solid #dedfe3;
  margin: 10px;
  padding: 27px 20px;
  max-width: 629px;
  margin: auto;
}

.levelSelection {
  font-size: 16px;
}

.kyclist {
  padding: 20px 0px 0px 0px;
}

.verified {
  background: #e7f9e8 !important;
  color: #068b03;
  text-transform: uppercase;
  font-size: 10px;
}

.pending {
  background: #fef3c7 !important;
  color: #f59e0b;
  text-transform: uppercase;
  font-size: 10px;
}

.link {
  text-decoration: underline;
  color: #0582d2;
  cursor: pointer;
}

.kycListItem {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #dedfe3;
}

@media only screen and (min-width: 400px) {
  .adminVerificationCardHeader {
    display: flex;
    justify-content: space-between;
  }
  .kycListItem {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    border-bottom: 1px solid #dedfe3;
  }

  .kyclist {
    padding: 32px 0px 32px 0px;
  }

  .businessDetails {
    width: 400px;
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 0px 0px;
  }

  .businessDetails p {
    margin: 0;
    padding: 0;
  }
}
</style>
