<template>
  <div>
    <v-card class="pa-6">
      <HeaderComponent :title="'Create Log'" @closeBtn="closeModal" />
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-form @submit.prevent="nextStep">
            <div>
              <label>Transaction Reference</label>
              <v-text-field v-model="transactionreference" outlined dense />
            </div>

            <div>
              <label>Customer Wallet number</label>
              <v-text-field v-model="walletnumber" outlined dense />
            </div>

            <div>
              <label>Comment</label>
              <v-textarea
                v-model="comment"
                outlined
                dense
                style="margin-bottom: 100px"
              />
            </div>

            <v-btn
              depressed
              x-large
              :color="BRANDCOLOR"
              block
              class="white--text rounded-lg"
              @click="nextStep"
              :loading="loading"
            >
              Next</v-btn
            >
          </v-form>
        </v-window-item>

        <v-window-item :value="2">
          <SuccessScreen
            :title="'Issue has been successfully Logged.'"
            :description="'Your withdrawal has been processed successfully. The amount will be reflected in your account shortly.'"
            :buttonText="'View Logs'"
            :closebtn="false"
            @closeSucess="closeSuccess"
        /></v-window-item>
      </v-window>
    </v-card>
  </div>
</template>
  
  
<script>
import HeaderComponent from "@/components/Misc/HeaderComponent.vue";
import { ADMINUSER, BRANDCOLOR } from "@/constants/constants";
import api from "@/services/api";
import { handleError, handleResponse } from "@/utils/handleErrors";
import SuccessScreen from "@/components/Modal/successScreen.vue";
export default {
  name: "CreateLogForm",
  components: {
    HeaderComponent,
    SuccessScreen,
  },
  data() {
    return {
      BRANDCOLOR: BRANDCOLOR,
      disabled: true,
      loading: false,
      transactionreference: "",
      walletnumber: "",
      comment: "",
      step: 1,
    };
  },
  methods: {
    async nextStep() {
      try {
        this.loading = true;
        const response = await api.postRequest(
          "local-transfer/localtransgermgt/external-reversal-log-reversal",
          {
            walletNo: this.walletnumber,
            transactionId: this.transactionreference,
            emailAddress: ADMINUSER.email,
          }
        );
        this.loading = false;
        handleResponse(response, { reload: false });
        if (response.data.statusCode === 200) {
         this.step++
        }
      } catch (error) {
        this.step = 2
        this.loading = false;
        handleError(error.message);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },

     closeSuccess() {
      this.$router.push("/logged-issues");
    },
  },
};
</script>