<template>
  <v-main>
    <div class="transactionsAdminCard">
      <h2>Recent Transactions</h2>
      <table>
        <thead class="tableHeading">
          <tr>
            <th class="font-weight-medium">Type</th>
            <th class="font-weight-medium">Title</th>
            <th class="font-weight-medium">Sender</th>
            <th class="font-weight-medium">Receiver</th>
            <th class="font-weight-medium">Amount</th>
          </tr>
        </thead>

        <tbody v-if="transactions.length === 0">
          <tr v-for="(t, i) in 8" :key="i" class="text-center">
            <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
            <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
            <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
            <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
            <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          </tr>
        </tbody>

        <tbody
          v-for="(t, i) in transactions && transactions.slice(0, 8)"
          :key="i"
        >
          <tr @click="viewTransaction(t.id)">
            <td>
              {{ (t.transactionType ? t.transactionType : "") | formatTitle }}
            </td>
            <td>
              {{ (t.transactionDesc ? t.transactionDesc : "") | formatTitle }}
            </td>
            <td>{{ t.walletNo | formatNumber }}</td>
            <td>{{ t.receiver | formatNumber}}</td>
            <td>NGN {{ t.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-main>
</template>



<script>
import Vue from "vue";
import { formatNumber, formatTitle } from "../../utils/formatter";
Vue.filter("formatTitle", formatTitle);
Vue.filter("formatNumber", formatNumber);
export default {
  props: {
    transactions: {
      type: Array,
    },
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .transactionsAdminCard {
    padding: 24px 16px;
    background: #f8f8f9;
    margin: auto;
    max-width: 96%;
    border-radius: 8px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

@media only screen and (min-width: 600px) {
  .transactionsAdminCard {
    padding: 24px 16px;
    background: #f8f8f9;
    margin: auto;
    max-width: 96%;
    border-radius: 8px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}
table {
  width: 100%;
}

.tableHeading th {
  font-size: 14px;
  padding: 20px 0px 0px 20px !important;
  text-align: start;
}

td {
  font-size: 14px;
  padding: 20px 0px 0px 20px !important;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}
.v-date-picker-title {
  color: pink !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  background: red;
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
  background: red;
}
</style>