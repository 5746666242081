<template>
  <div class="py-8 tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th class="font-weight-medium">Type</th>
          <th class="font-weight-medium">Title</th>
          <th class="font-weight-medium">Sender</th>
          <th class="font-weight-medium">Receiver</th>
          <th class="font-weight-medium">Amount</th>
          <th class="font-weight-medium">Date Created</th>
        </tr>
      </thead>

      <tbody v-for="(t, i) in tables" :key="i">
        <tr @click="viewUser(t.title)" class="text-center">
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
    
    
    
    
    
    <script>
import slugify from "slugify";

export default {
  data() {
    return {
      showBalance: true,
      page: 1,
      tables: [
        {
          fullname: "Lorem Ipsum",
          emailAddress: "Lorem Ipsum",
          phoneNumber: "0212121122",
          date: "May 29 2022",
          accountLevel: "Level 1",
        },

        {
          fullname: "Lorem Ipsum",
          emailAddress: "lorem ipsum",
          phoneNumber: "0212121122",
          date: "May 29 2022",
          accountLevel: "Level 1",
        },

        {
          fullname: "Lorem Ipsum",
          emailAddress: "lorem ipsum",
          phoneNumber: "0212121122",
          date: "May 29 2022",
          accountLevel: "Level 1",
        },
        {
          fullname: "Lorem Ipsum",
          emailAddress: "lorem ipsum",
          phoneNumber: "0212121122",
          date: "May 29 2022",
          accountLevel: "Level 1",
        },
        {
          fullname: "Lorem Ipsum",
          emailAddress: "lorem ipsum",
          phoneNumber: "0212121122",
          date: "May 29 2022",
          accountLevel: "Level 1",
        },
        {
          fullname: "Lorem Ipsum",
          emailAddress: "lorem ipsum",
          phoneNumber: "09121172330",
          date: "May 29 2022",
          accountLevel: "Level 1",
        },
      ],
    };
  },
  computed: {},
  methods: {
    viewUser(link) {
      link = slugify(link, {
        replacement: "-",
        remove: /[$*_+~.()'"!\-:@]/g,
        lower: true,
      });
      this.$router.push("/admin/user/" + link);
    },
  },
};
</script>
    
    <style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px !important;
  background: #f9fafb;
}

td {
  font-size: 14px;
  padding: 10px !important;
  text-align: center;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}
.v-date-picker-title {
  color: pink !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  background: red;
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
  background: red;
}

.checkBoxPosition {
  margin: 14px 0px 0px 11px;
}
</style>
    