<template>
  <DashboardLayout>
    <RolesBody
      :admin="admin.adminUsers"
      :roles="admin.roles"
      :loading="admin.loading"
      :groups="admin.groups"
      :rolespermission="admin.rolespermission"
      :grouptypes="admin.grouptypes"
      :permissions="admin.permissions"
    />
  </DashboardLayout>
</template>

  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import RolesBody from "@/components/Roles/RolesBody.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    RolesBody,
  },
  computed: {
    ...mapState(["admin"]),
  },

  created() {
    this.$store.dispatch("admin/fetchAdminUsers");
    this.$store.dispatch("admin/fetchAllRoles");
    this.$store.dispatch("admin/fetchAllGroups");
    this.$store.dispatch("admin/fetchPermissions")
  },
};
</script>
  
  
  
  
  
  
  <style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>