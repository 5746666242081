<template>
  <v-main>
    <div class="reconcileContainer">
      <div class="reconcile__title__flex">
        <h1>Reconciliation</h1>
        <v-btn
          @click="handleReconcile"
          depressed
          :color="BRANDCOLOR"
          class="white--text"
        >
          Reconcile</v-btn
        >
      </div>
      <div class="stat-grid">
        <StatCard
          title="Total wallet balance"
          :value="parseFloat(totalWalletBalance)"
          :type="'currency'"
        />
        <StatCard
          title="Total commission earned"
          :value="parseFloat(totalCommissionEarned)"
          :type="'currency'"
        />
      </div>

      <TableComponent :columns="columns">
        <tbody v-for="(transaction, i) in transactions" :key="i">
          <tr>
            <td>{{ transaction.processId }}</td>
            <td>{{ transaction.totalCommissionEarned }}</td>
            <td>{{ transaction.createdBy }}</td>
            <td>{{ transaction.totalWalletBalance }}</td>
            <td>
              {{ transaction.createdDate | formatDate }}
            </td>
            <td>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showTransaction(transaction)">
                    Reconcile
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </TableComponent>
    </div>

    <v-dialog persistent max-width="500px" v-model="reconcile">
      <ReconcileModal
        @closeModal="closeModal"
        :totalWalletBalance="totalWalletBalance"
        :totalCommissionEarned="totalCommissionEarned"
        :transaction="transaction"
      />
    </v-dialog>
  </v-main>
</template>
      
      
      
<script>
import Vue from "vue";
import { BRANDCOLOR } from "@/constants/constants";
import StatCard from "../Dashboard/Cards/StatCard.vue";
import TableComponent from "../Misc/TableComponent.vue";
import ReconcileModal from "./Modals/ReconcileModal.vue";
import { dateFormatter } from "@/utils/formatter";
Vue.filter("formatDate", dateFormatter);

export default {
  props: {
    totalCommissionEarned: {
      type: [String, Number],
    },
    totalWalletBalance: {
      type: [String, Number],
    },
    transactions: {
      type: Array,
    },
    processId: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    StatCard,
    TableComponent,
    ReconcileModal,
  },
  data() {
    return {
      BRANDCOLOR,
      columns: [
        "Transaction Id",
        "Total Commissions Earned",
        "Created By",
        "Total Wallet Balance",
        "Date",
        "Action",
      ],
      crowdfunding: [],
      reconcile: false,
      transaction: {},
    };
  },
  methods: {
    handleReconcile() {
      this.reconcile = !this.reconcile;
    },
    closeModal() {
      this.reconcile = !this.reconcile;
    },
    showTransaction(transaction) {
      this.transaction = transaction;
      this.reconcile = !this.reconcile;
    },
  },
};
</script>
      
<style scoped>
.reconcile__title__flex {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 20px 0px;
}
.stat-grid {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
      
      
      