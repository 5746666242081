const APIURL = "https://proxy.kuleanpay.com/api/"; //change to proxy.kuleanpay.com/api when pushing to production

export const GETURL = APIURL + "get?url=";
export const POSTURL = APIURL + "post?url=";
export const LOGINURL = APIURL + "login?url=";
export const DELETEURL = APIURL + "delete?url=";
export const UPLOADURL = APIURL + "upload?url=";
export const BRANDCOLOR = "#0582D2";
export const TOKEN = sessionStorage.getItem("adminToken");
export const HEADERS = {
  channel: "Web",
  Accept: "*/*",
  "Content-Type": "application/json",
  Authorization: `Bearer ${TOKEN}`,
};

export const ADMINHEADERS = {
  channel: "",
  Accept: "*/*",
  "Content-Type": "application/json",
  Authorization: `Bearer ${TOKEN}`,
};

export const MONTH = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const ALERTCONFIG = {
  confirmButtonColor: "white",
  confirmButtonTextColor: "#0066f5",
  cancelButtonColor: "#000000",
  background: "#0284c7",
  color: "#ffffff",
  confirmButtonText: `<p class=brandcolor>Ok</p>`,
};

export const TOASTCONFIG = {
  position: "top-right",
  timeout: 2500,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: false,
  rtl: false,
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true,
};

export const TIERLEVELS = ["All", "Tier 1", "Tier 2", "Tier 3", "Tier 4"];

export const FILTERDATES = [
  "All",
  "Last 7 days",
  "Last 14 days",
  "Last 30 days",
];

export const FILTERPAYMENTS = ["All", "Deposit", "Withdrawal"];

export const ADMINUSER = JSON.parse(sessionStorage.getItem("userData"));
export const ADMINROLES = JSON.parse(sessionStorage.getItem("adminroles"));
