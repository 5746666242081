<template>
  <v-main class="whiteBackground">
    <div class="text-right mr-3 my-5">
      <v-btn
        @click="createpermission = true"
        class="white--text rounded-lg"
        large
        color="#0582D2"
        depressed
      >
        <v-icon>mdi-plus</v-icon> Create Group
      </v-btn>
    </div>
    <div class="roleCards">
      <v-card v-for="(role, i) in roles" :key="i" class="roleCard" flat>
        <h2>{{ role }}</h2>

        <div class="actionButtons">
          <v-btn small text @click="viewPermission(role)">View</v-btn>
          <v-btn small color="#1C6BFF" text @click="editPermission(role)"
            >Edit</v-btn
          >
          <v-btn
            :loading="deleteloading"
            @click="deleteGroup(role)"
            small
            color="#EF4444"
            text
            >Delete</v-btn
          >
        </div>
      </v-card>
    </div>

    <v-dialog max-width="580px" v-model="createpermission"
      ><CreatePermission
        :grouptypes="grouptypes"
        @closeModal="createpermission = false"
        :permissions="permissions"
      />
    </v-dialog>

    <!--View permission -->
    <v-dialog max-width="500px" v-model="viewpermission">
      <ViewPermission
        :permissions="rolepermissions"
        :role="role"
        @closeModal="viewpermission = false"
      />
    </v-dialog>

    <!--Edit permission -->
    <v-dialog max-width="500px" v-model="editpermission">
      <EditPermission
        :permissions="permissions"
        :role="role"
        @closeModal="editpermission = false"
        :rolepermissions="rolepermissions"
      />
    </v-dialog>

    <!--Delete permission -->
    <v-dialog v-model="areyousure" max-width="580px">
      <AreYouSure
        :loading="deleteloading"
        :title="`Delete Group: ${role}`"
        :description="'Are you sure you want to delete this user group? Deletions are permanent and irreversible'"
        @handleProceed="handleDelete"
        @closeModal="areyousure = false"
      />
    </v-dialog>
  </v-main>
</template>



<script>
import { handleError, handleResponse } from "@/utils/handleErrors";
import CreatePermission from "./Dialogs/CreatePermission.vue";
import ViewPermission from "./Dialogs/ViewPermission.vue";
import api from "@/services/api";
import AreYouSure from "../Misc/AreYouSure.vue";
import EditPermission from "./Dialogs/EditPermission.vue";

export default {
  data: () => ({
    createpermission: false,
    viewpermission: false,
    editpermission: false,
    role: "",
    rolepermissions: [],
    deleteloading: false,
    areyousure: false,
  }),
  props: {
    roles: {
      type: Array,
    },
    rolespermission: {
      type: [Object, Array],
    },
    grouptypes: {
      type: Array,
    },
    permissions: {
      type: Array,
    },
    groups: {
      type: Array,
    },
  },
  components: { CreatePermission, ViewPermission, AreYouSure, EditPermission },
  methods: {
    viewPermission(role) {
      this.role = role;
      this.viewpermission = true;
      this.rolepermissions = this.rolespermission[0][role];
    },

    editPermission(role) {
      this.role = role;
      this.editpermission = true;
      this.rolepermissions = this.rolespermission[0][role];
    },

    deleteGroup(e) {
      this.role = e;
      this.areyousure = true;
    },

    async handleDelete() {
      try {
        this.deleteloading = true;
        const response = await api.postRequest(
          "profiling/usermgt/delete-group",
          {
            userGroupName: this.role,
          }
        );

        handleResponse(response, { reload: true });
        this.deleteloading = false;
      } catch (error) {
        handleError(error.message);
        this.deleteloading = false;
      }
    },
  },
};
</script>


<style scoped>
.whiteBackground {
  background: white;
}

.createPermissionCard {
  padding: 40px;
}

.closeButton {
  margin: -10px 0px 0px 0px;
}

@media only screen and (max-width: 600px) {
  .roleCards {
    padding: 10px;
  }
  .roleCard {
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px;
    margin: 0px 0px 20px 0px;
  }
  .roleCard h2 {
    font-size: 23px;
  }

  .actionButtons {
    margin: 35px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .roleCard {
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px;
    margin: 8px;
  }

  .roleCards {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    padding: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .roleCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    padding: 0px;
  }
}

@media only screen and (min-width: 996px) {
  .roleCard {
    height: 126px;
  }
  .roleCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    padding: 0px;
  }
  .actionButtons {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 0px 0px;
  }
}
</style>