<template>
  <DashboardLayout>
    <ChangePasswordBody />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import ChangePasswordBody from "@/components/ChangePassword/ChangePasswordBody.vue";

export default {
  components: {
    DashboardLayout,
    ChangePasswordBody,
  },
};
</script>
  
  
  

  
<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>