<template>
  <div class="py-5 d-flex horizontalScroll">
    <v-btn text @click="prevPagination(page)"
      ><v-icon>mdi-chevron-left</v-icon>Previous</v-btn
    >
    <div
      class="mt-2 mx-2"
      v-for="number in Math.floor(pagination / 10)"
      :key="number"
    >
      <div
        @click="setPagination(number-1)"
        :class="number - 1 === page ? 'pagination' : ''" 
        small
        width="2px"
        outlined
      >
        {{ number }}
      </div>
      <!-- the minus one is because pagination starts from zero -->
    </div>
    <v-btn @click="nextPagination(page)" text>
      Next <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>


<script>
export default {
  name: "PaginationComponent",
  props: {
    pagination: {
      type: Number,
    },
    number: {
      type: Number,
    },
    page: {
      type: Number,
    },
  },

  methods: {
    prevPagination(e) {
      this.$emit("prevPagination", e);
    },

    setPagination(e) {
      this.$emit("setPagination", e);
    },

    nextPagination(e) {
      this.$emit("nextPagination", e);
    },
  },
};
</script>



<style scoped>
.horizontalScroll {
  white-space: nowrap;
  cursor: pointer;
  overflow-x: auto;
}

.pagination {
  border: 1px solid #0066f5;
  padding: 0px 10px;
  color: #0066f5;
  cursor: pointer;
}
</style>