<template>
  <v-card class="pa-4">
    <ModalHeader :title="'Select Permissions'" @closeModal="closeModal" />

    <div>
      <v-checkbox
        v-for="(permission, i) in permissions"
        :key="i"
        :value="permission.roleId"
        :label="permission.userRole | unslugify"
        v-model="selectedpermissions"
        @change="handleSelect"
      />
    </div>
  </v-card>
</template>


<script>
import Vue from "vue";
import ModalHeader from "@/elements/ModalHeader.vue";
import { unslugifyString } from "@/utils/formatter";

Vue.filter("unslugify", unslugifyString);

export default {
  props: {
    permissions: {
      type: Array,
    },
  },
  data() {
    return {
      selectedpermissions: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    handleSelect() {
      this.$emit("handleSelect", this.selectedpermissions);
    },
  },
  components: { ModalHeader },
};
</script>