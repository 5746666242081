<template>
  <v-main class="whiteBackground">
    <v-card flat class="singleProfileCard">
        <div class="pl-3">
        <v-avatar size="70px" color="#f0f0f0">
            <span v-if="!loading">{{ adminKyc.firstName.slice(0,2) }}</span>
        </v-avatar>
        </div>

        <div class="mt-3">
           <ul  class="detailsList">
            <li><p class="font-weight-medium">First Name</p><p> <v-progress-circular v-if="loading" size="20" width="2" indeterminate></v-progress-circular>{{ adminKyc.firstName }}</p></li>
            <li ><p class="font-weight-medium">Last Name</p> <p> <v-progress-circular v-if="loading" size="20" width="2" indeterminate></v-progress-circular> {{adminKyc.lastName}}</p></li>
            <li><p class="font-weight-medium">Email address</p> <p><v-progress-circular v-if="loading" size="20" width="2" indeterminate></v-progress-circular> {{adminKyc.email}}</p></li>
            <li><p class="font-weight-medium">Phone Number</p> <p> <v-progress-circular v-if="loading" size="20" width="2" indeterminate></v-progress-circular> {{adminKyc.phoneNumber}}</p></li>
           
           </ul>
        </div>
    </v-card>
  </v-main>
</template>

<script>
export default {
    data:()=>({
    }),

    props: {
      adminKyc:{
        type:Object
      },
      loading:{
        type:Boolean
      }
    },
}
</script>





<style scoped>


    .whiteBackground{
        background:white
    }
    .detailsList li{
        list-style: none;
        display:flex;
        justify-content: space-between;
    }

    .detailsList li p{
       word-break: break-all;
    }




    @media only screen and (max-width:600px){
        .singleProfileCard{
            padding:24px 10px 24px 0px ;
            border:1px solid #DEDFE3;
            margin:0px 10px 0px 0px
        }
    }


    @media only screen and (min-width:600px){
        .singleProfileCard{
            padding:24px 40px;
            border:1px solid #DEDFE3;
            margin:0px 20px 0px 20px;
            max-width: 629px;
            margin:auto
        }
    }





</style>
