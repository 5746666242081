<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="
          tab === 'withdrawal'
            ? project.cashOutRequestApprovalStatus.toLowerCase()
            : project.approvalStatus.toLowerCase()
        "
        depressed
        v-bind="attrs"
        v-on="on"
      >
        {{
          tab === "withdrawal"
            ? project.cashOutRequestApprovalStatus
            : project.approvalStatus
        }}
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item class="approve" @click="approveCampaign()">
        <span class="approve"
          ><v-icon color="#068B03">mdi-check</v-icon> Approve</span
        >
      </v-list-item>
      <v-list-item class="decline">
        <span class="decline"
          ><v-icon color="#dc2626">mdi-close</v-icon> Decline</span
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>


<script>
import Vue from "vue";
import { statusColor } from "../../utils/statusColor";
import { ADMINUSER, POSTURL, HEADERS } from "@/constants/constants";
import axios from "axios";
import { handleError } from "@/utils/handleErrors";

Vue.filter("statusColor", statusColor);

export default {
  name: "StatusSelect",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    items: {
      type: Array,
    },
    project: {
      type: Object,
    },
    tab: {
      type: String,
    },
  },
  methods: {
    approveCampaign() {
      this.loading = true;
      axios({
        method: "POST",
        url:
          POSTURL +
          "settlements/onetimesettlementmgt/crowd-funding/create-transaction-approval",
        data: {
          adminWalletNo: ADMINUSER.phone,
          walletNo: this.project.walletNo,
          transactionId: this.project.transactionId,
        },
        headers: HEADERS,
      })
        .then(() => {
          this.loading = false;
          location.reload();
        })
        .catch((error) => {
          this.loading = false;
          handleError(error.message);
        });
    },
  },
};
</script>



<style scoped>
.approve {
  color: #068b03;
}
.decline {
  color: #dc2626;
}

.approved {
  color: #068b03;
  background: #cde8cd !important;
  pointer-events: none;
}

.in-review {
  color: #f59e0b;
  background: #fef3c7 !important;
}

.pending {
  color: #f59e0b;
  background: #fef3c7 !important;
  pointer-events: none;
}

.completed {
  color: #068b03;
  background: #cde8cd !important;
  pointer-events: none;
}
</style>