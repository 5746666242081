<template>
  <DashboardLayout>
    <StatementBody />
  </DashboardLayout>
</template>
    
    
    
    
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import { mapState } from "vuex";
import StatementBody from "@/components/Statements/StatementBody.vue";

export default {
  components: {
    DashboardLayout,
    StatementBody,
  },
  computed: {
    ...mapState(["admin"]),
  },
};
</script>
    
    
    
