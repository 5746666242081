<template>
  <DashboardLayout>
    <SingleUserBody :adminKyc="users.singleUserKyc" :loading="users.loading" />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import SingleUserBody from "@/components/SingleUser/SingleUserBody.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    SingleUserBody,
  },
  computed: {
    ...mapState(["users"]),
  },

  created() {
    this.$store.dispatch(
      "users/fetchSingleUserKyc",
      this.$route.query.phoneNumber
    );
  },
};
</script>
  
  
  
  
  
  
  <style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>