<template>
  <v-card class="pa-6">
    <v-window v-model="step">
      <v-window-item :value="1">
        <InitiateReversalForm
          @nextStep="nextStep"
          @closeModal="closeModal"
          :transactiondetails="transactiondetails"
        />
      </v-window-item>
      <v-window-item :value="2">
        <ReversalForm
          @closeModal="closeModal"
          @prevStep="prevStep"
          :amount="transactiondetails.reversalAmount"
          :processId="processId"
        />
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import ReversalForm from "./ReversalForm.vue";
import InitiateReversalForm from "./InitiateReversalForm.vue";

export default {
  components: {
    ReversalForm,
    InitiateReversalForm,
  },
  props: {
    transactiondetails: {
      type: Object,
    },
  },
  data() {
    return {
      step: 1,
      amount: "",
      processId: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    prevStep() {
      this.step--;
    },
    nextStep(data) {
      this.amount = data.reversalAmount;
      this.processId = data.processId;
      this.step++;
    },
  },
};
</script>


<style scoped>
</style>