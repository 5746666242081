<template>
  <DashboardLayout>
    <PromotionsComponent
      :promotions="promotion.promotions"
      :loading="promotion.loading"
    />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import PromotionsComponent from "../components/Promotions/PromotionsComponent.vue";
import { mapState } from "vuex";

export default {
  name: "PromotionsPage",
  components: {
    DashboardLayout,
    PromotionsComponent,
  },
  computed: {
    ...mapState(["promotion"]),
  },
  created() {
    this.$store.dispatch("promotion/fetchPromotions");
  },
};
</script>
  
  
  
  
<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>