<template>
  <div>
    <HeaderComponent :title="'Funds Withdrawal'" @closeBtn="closeModal" />

    <div class="my-3">
      <CenterCard :title="'Wallet Balance'" :value="'N320,000'" />
    </div>
    <v-form @submit.prevent="nextStep">
      <div>
        <label>Amount to be deducted</label>
        <v-text-field
          v-model="amount"
          outlined
          dense
          type="number"
          input-mode="numeric"
          style="height: 55px"
        />
      </div>

      <div>
        <label>Destination Wallet number</label>
        <v-text-field
          v-model="walletnumber"
          outlined
          dense
          type="number"
          input-mode="numeric"
          style="height: 55px"
        />
      </div>

      <div class="mb-6">
        <label>Reason for deduction </label>
        <v-textarea
          v-model="reason"
          outlined
          dense
          height="100px"
          style="margin-bottom: 60px"
        />
      </div>

      <div>
        <label>Upload Evidence (Providus)</label>
        <DragAndDrop />
      </div>

      <div class="mt-3">
        <label>Authorization ID</label>
        <v-text-field
          v-model="authorizationKey"
          outlined
          dense
          style="height: 55px"
        />
      </div>

      <div>
        <label>OTP</label>
        <v-text-field
          v-model="otp"
          outlined
          dense
          type="number"
          input-mode="numeric"
          style="height: 55px"
        />

        <button class="mb-3" small text :color="BRANDCOLOR">Resend OTP</button>
      </div>

      <div class="grid">
        <v-btn
          depressed
          x-large
          color="#F0F5FD"
          class="blue--text rounded-lg"
          @click="nextStep"
        >
          Back</v-btn
        >

        <v-btn
          depressed
          x-large
          :color="BRANDCOLOR"
          class="white--text rounded-lg"
          @click="nextStep"
          :loading="loading"
        >
          Submit</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
  
  
<script>
import HeaderComponent from "@/components/Misc/HeaderComponent.vue";
import { ADMINUSER, BRANDCOLOR } from "@/constants/constants";
import api from "@/services/api";
import { handleError, handleResponse } from "@/utils/handleErrors";
import DragAndDrop from "@/components/Misc/DragAndDrop.vue";
import CenterCard from "@/components/Dashboard/Cards/CenterCard.vue";
export default {
  components: {
    HeaderComponent,
    DragAndDrop,
    CenterCard,
  },
  data() {
    return {
      BRANDCOLOR: BRANDCOLOR,
      disabled: true,
      loading: false,
      referencenumber: "",
      requestId:"",
      otp:"",
      amount:"",
      authorizationKey:"",
      reason:""
    };
  },
  methods: {
    async nextStep() {
      try {
        this.loading = true;
        const response = await api.postRequest(
          "local-transfer/localtransgermgt/external-reversal-process-reversal-external",
          {
            requestId: this.requestId,
            otp: this.otp,
            amount: this.amount,
            authorizationKey: this.authorizationKey,
            uploadedEvidence: this.evidence,
            reasonForDeduction: this.reason,
            accountToDebit: "",
            accountToCredit: "",
            emailAddress: ADMINUSER.email,
            processId: this.processId,
          }
        );
        this.loading = false;
        handleResponse(response, { reload: false });
        const data = response.data.data;
        if (response.data.statusCode === 200) {
          this.$emit("nextStep", data);
        }
      } catch (error) {
        this.loading = false;
        handleError(error.message);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>


<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>