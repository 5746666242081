<template>
  <v-main>
    <div class="transactionsContainer">
      <h1>Payments</h1>
    </div>

    <v-card flat class="mr-4" color="white">
      <div class="d-flex">
        <div>
          <v-text-field
            style="max-width: 590px"
            class="searchField mt-5"
            label="Search Payments"
            single-line
            prepend-inner-icon="mdi-magnify"
          />
        </div>
        <v-spacer></v-spacer>

        <div class="mr-4 mt-7">
          <!-- Payment type filter -->
          <MenuFilter
            @filter="setPaymentType"
            :type="'Payment Type'"
            :title="paymentType"
            :data="FILTERPAYMENTS"
          />
        </div>
      </div>

      <PaymentLoading v-if="loading" />
      <PaymentTable
        v-if="!loading"
        :transactions="filteredTransactions"
        :total="total"
        :page="page"
        :loading="loading"
      />
    </v-card>
  </v-main>
</template>
    
    
    
    <script>
import PaymentTable from "./PaymentTable.vue";
import PaymentLoading from "./PaymentLoading.vue";
import { FILTERPAYMENTS } from "@/constants/constants";
import MenuFilter from "@/elements/MenuFilter.vue";
export default {
  data() {
    return {
      search: "",
      paymentType: "",
      FILTERPAYMENTS,
    };
  },
  components: { PaymentTable, PaymentLoading, MenuFilter },
  props: {
    transactions: {
      type: Array,
    },
    total: {
      type: Number,
    },
    page: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    filteredTransactions() {
      const searchTransactions = this.search.toLowerCase().trim();
      if (!searchTransactions) return this.transactions;
      return this.transactions.filter((transaction) => {
        const paymentType = transaction.paymentType
          ? transaction.paymentType.toLowerCase()
          : "";
        const transactionType = transaction.transactionType
          ? transaction.transactionType.toLowerCase()
          : "";
        return (
          paymentType.indexOf(searchTransactions) > -1 ||
          transactionType.indexOf(searchTransactions) > -1
        );
      });
    },
  },
  methods: {
    setPaymentType(type) {
      if (type == "All") {
        location.reload();
      }
      this.search = type;
      this.paymentType = type;
    },
  },
};
</script>
    
<style scoped>
.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
.transactionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 44px 0px;
}

.transactionCardContainer {
  margin: 0px 90px 44px 0px;
}
.tabContainer {
  padding: 20px 0px 0px 24px;
}
.button {
  padding: 40px 0px;
  border-radius: 10px;
}
</style>
    
    
    