<template>
    <v-main>


      <div class="transactionsContainer">
      <h1>Account Limit</h1>
      <div>
        <v-btn @click="createLimit = true" large color="#0582D2" class="button white--text"><v-icon>mdi-plus</v-icon>Create New</v-btn>
      </div>
      </div>


      <div class="limitCards">
        <v-card v-for="(limit, i) in limits" :key="i" class="limitCard" flat>
        <div class="d-flex justify-space-between">
         <h2 class="mb-3"> {{ limit.title }} </h2>
         <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
        link
        @click="editLimit = true"
        >
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>

        <v-list-item
        link
        @click="deleteLimit = true"
        >
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
        </div>

         <div class="d-flex justify-space-between">
          <p>Daily limit</p><p>{{limit.daily}}</p>
         </div>

         <div class="d-flex justify-space-between">
          <p>Per Transaction</p><p>{{limit.perTransaction}}</p>
         </div>

         <div class="d-flex justify-space-between">
          <p>Maximum Balance</p><p>{{limit.maxBalance}}</p>
         </div>
        </v-card>
    </div>


    <v-dialog max-width="580px" v-model="createLimit">
        <v-card class="createPermissionCard" max-width="580px">
            <h2 class="d-flex justify-space-between">Create Permission <v-btn @click="createLimit = false" class="closeButton" fab depressed><v-icon>mdi-close</v-icon></v-btn></h2>
            <label>Level</label>
            <v-text-field outlined dense style="height:50px"/>

            <label>Daily limit</label>
            <v-text-field outlined dense style="height:50px"/>

            <label>Per Transaction</label>
            <v-text-field outlined dense style="height:50px"/>

            <label>Maximum Balance</label>
            <v-text-field outlined dense style="height:50px"/>

            <v-btn block large color="#0582D2" class="rounded-lg white--text my-5">Create Limit</v-btn>
        </v-card>
    </v-dialog>


    <v-dialog max-width="580px" v-model="editLimit">
        <v-card class="createPermissionCard" max-width="580px">
            <h2 class="d-flex justify-space-between">Edit Permission <v-btn @click="editLimit = false" class="closeButton" fab depressed><v-icon>mdi-close</v-icon></v-btn></h2>
            <label>Level</label>
            <v-text-field outlined dense style="height:50px"/>

            <label>Daily limit</label>
            <v-text-field outlined dense style="height:50px"/>

            <label>Per Transaction</label>
            <v-text-field outlined dense style="height:50px"/>

            <label>Maximum Balance</label>
            <v-text-field outlined dense style="height:50px"/>

            <v-btn block large color="#0582D2" class="rounded-lg white--text my-5">Create Limit</v-btn>
        </v-card>
    </v-dialog>


    <v-dialog max-width="580px" v-model="deleteLimit">
        <v-card class="createPermissionCard text-center" max-width="580px">
          <div class="d-flex justify-center">
          <img width="180px" src="../../assets/delete.gif" />
        </div>
        <h2>
          Delete Limit
        </h2>
        <p>Are you sure you want to delete this limit? This process is not reversible</p>
        <v-btn large color="#EF4444" class="white--text" block>Yes, Delete</v-btn>
        </v-card>
    </v-dialog>

      

      
    </v-main>
  </template>
    
    
    
    <script>
    export default {
      data: () => ({
        limits: [
            { title: "Level 1", id: "1",daily:"NGN 5,000,0000",perTransaction:"NGN 2,000,000",maxBalance:"Unlimited"},
            { title: "Level 2", id: "2",daily:"NGN 5,000,0000",perTransaction:"NGN 2,000,000",maxBalance:"Unlimited"},
            { title: "Level 3", id: "3",daily:"NGN 5,000,0000",perTransaction:"NGN 2,000,000",maxBalance:"Unlimited"},
            { title: "Level 4", id: "4",daily:"NGN 5,000,0000",perTransaction:"NGN 2,000,000",maxBalance:"Unlimited"},
        ],
        items:[
          "Edit",
          "Delete"
        ],
        createLimit:false,
        editLimit:false,
        deleteLimit:false
      }),
      methods:{
        delete(){
          this.deleteLimit = !this.deleteLimit
        }
      }
    };
    </script>
   

   
  <style scoped>
  
  .searchField >>> .v-input__slot::before {
   border-style: none !important;
 }
 .transactionsContainer{
   display:flex;
   justify-content: space-between;
   margin:0px 20px 44px 0px
 }
 
 .transactionCardContainer{
   margin:0px 90px 44px 0px;
   
 }
 .tabContainer{
 padding:20px 0px 0px 24px
 }
 .button{
   padding:40px 0px;
   border-radius:10px
 }


    .whiteBackground{
        background:white
    }

    .createPermissionCard{
        padding:40px
    }

    .closeButton{
        margin:-10px 0px 0px 0px
    }

@media only screen and (max-width: 600px) {

 .limitCards{
    padding:10px
 }
  .limitCard {
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px;
    margin:0px 0px 20px 0px
  }
  .limitCard h2{
    font-size:23px
  }
  
  .actionButtons{
    margin:28px 0px 0px 0px
  }
}

@media only screen and (min-width: 600px) {
  .limitCard {
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px;
    margin: 8px;
  }

 .limitCards{
    display:grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    padding:0px
 }
}

@media only screen and (min-width:768px){
    .limitCards{
    display:grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    padding:0px
 }
}

@media only screen and (min-width:996px){
    .limitCard{
        height:199px;
        border:1px solid #DEDFE3
    }
    .limitCards{
    display:grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    padding:0px;
 }
 .actionButtons{
    display: flex;
    justify-content: space-between;
 }
}







 </style>
    
    
    