import api from "@/services/api";
import { handleError } from "@/utils/handleErrors";

export default {
  namespaced: true,
  state: {
    alltransactions: 0,
    activetransactions: 0,
    recenttransactions: [],
    pendingtransactions: 0,
    completetransactions: 0,
    loading: false,
    page: 0,
    transactions: [],
    total: 0,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_ALL_TRANSACTIONS_COUNT(state, value) {
      state.alltransactions = value;
    },

    SET_RECENT_TRANSACTIONS(state, value) {
      state.recenttransactions = value;
    },

    SET_ACTIVE_TRANSACTIONS(state, value) {
      state.activetransactions = value;
    },

    SET_PENDING_TRANSACTIONS(state, value) {
      state.pendingtransactions = value;
    },

    SET_COMPLETE_TRANSACTIONS(state, value) {
      state.completetransactions = value;
    },

    SET_TRANSACTIONS(state, value) {
      state.transactions = value;
    },

    SET_TOTAL_TRANSACTIONS(state, value) {
      state.total = value;
    },

    SET_PAGE(state, value) {
      state.page = value;
    },
  },
  actions: {
    fetchAllTransactionsCount: async ({ commit }) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-all-escrowTxs"
        );
        commit("SET_ALL_TRANSACTIONS_COUNT", response.data.data.count);
      } catch (error) {
        handleError(error.message);
      }
    },

    fetchRecentTransactions: async ({ commit }) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-10-most-recent-txs"
        );
        commit("SET_RECENT_TRANSACTIONS", response.data.data.recentTxs);
      } catch (error) {
        handleError(error.message);
      }
    },

    fetchPendingTransactionsCount: async ({ commit }) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-all-pending-escrowTxs"
        );
        commit("SET_PENDING_TRANSACTIONS", response.data.data.count);
      } catch (error) {
        handleError(error.message);
      }
    },

    fetchActiveTransactionsCount: async ({ commit }) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-all-active-escrowTxs"
        );
        commit("SET_ACTIVE_TRANSACTIONS", response.data.data.count);
      } catch (error) {
        handleError(error.message);
      }
    },

    fetchCompleteTransactionsCount: async ({ commit }) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-all-completed-escrowTxs"
        );
        commit("SET_COMPLETE_TRANSACTIONS", response.data.data.count);
      } catch (error) {
        handleError(error.message);
      }
    },

    fetchTransactions: async ({ commit, rootState }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest(
          `profiling/usermgt/get-all-escrow-txs?pageNo=${rootState.transaction.page}&pageSize=10`
        );
        commit("SET_TRANSACTIONS", response.data.data.escrowtxs);
        commit("SET_TOTAL_TRANSACTIONS", response.data.data.total_transactions);
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },

    setTransactionPagination: async (context, value) => {
      try {
        context.commit("SET_LOADING", true);
        const response = await api.getRequest(
          "profiling/usermgt/get-all-escrow-txs?pageNo=" +
            value +
            "&pageSize=" +
            10
        );
        context.commit("SET_TRANSACTIONS", response.data.data.escrowtxs);
        context.commit("SET_PAGE", value);
        context.commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        context.commit("SET_LOADING", false);
      }
    },
  },
};
