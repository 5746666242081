<template>
  <DashboardLayout>
    <FinanceBody />
  </DashboardLayout>
</template>




<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import FinanceBody from "@/components/Finance/FinanceBody.vue";
export default {
  components: {
    DashboardLayout,
    FinanceBody,
  },
};
</script>