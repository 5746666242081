<template>
  <v-card class="card">
    <div class="d-flex justify-space-between">
      <h2>
        {{ title }}
      </h2>
      <v-btn @click="closeModal" small fab depressed>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <p class="my-3">
      {{ description }}
    </p>

    <div class="spacebuttons">
      <v-btn @click="closeModal" color="#0284c7" outlined>No, Close</v-btn>
      <v-btn
        :loading="loading"
        @click="handleProceed"
        color="#0284c7"
        class="white--text"
        >Yes, Proceed</v-btn
      >
    </div>
  </v-card>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },

  methods: {
    handleProceed() {
      this.$emit("handleProceed");
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>


<style scoped>
.card {
  padding: 26px 40px;
}

.spacebuttons > * {
  margin: 12px 12px 12px 0px;
}
</style>