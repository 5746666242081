<template>
  <DashboardLayout>
    <PaymentBody
      :transactions="payment.payments"
      :total="payment.total"
      :page="payment.page"
      :loading="payment.loading"
    />
  </DashboardLayout>
</template>
    
    
    
    
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import PaymentBody from "@/components/Payments/PaymentBody.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    PaymentBody,
  },
  // props: {
  //   states: {
  //     type: Array,
  //   },
  // },

  computed: {
    ...mapState(["payment"]),
  },

  created() {
    this.$store.dispatch("payment/fetchPayments");
  },
};
</script>
    
    
    
    
    
    
<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>