<template>
  <v-window v-model="step">
    <v-window-item :value="1">
      <v-card class="pa-4">
        <div class="promotionsHeading">
          <h2>Compose Message</h2>
          <v-btn @click="closeNotification" small fab depressed
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-alert v-model="alert" type="error">{{ error }}</v-alert>

        <v-form class="my-4">
          <label>Message title</label>
          <v-text-field v-model="title" style="height: 50px" single-line outlined dense />

          <label>Recipient</label>
          <v-select v-model="recipient" single-line outlined dense :items="items" />

          <label>Message</label>
          <v-textarea v-model="message" single-line outlined dense :items="items" />
        </v-form>
        <div class="my-4">
          <v-btn @click="sendNotification" :loading="loading" block class="primarybtn">Send Message </v-btn>
        </div>
      </v-card>
    </v-window-item>
    <v-window-item :value="2">
      <successScreen
        :title="'Push Notification Sent!'"
        :buttonText="'Continue'"
        :description="'Notification sent to users'"
        @closeSuccess="closeNotification"
      />
    </v-window-item>
  </v-window>
</template>
  
  <script>
import successScreen from "@/components/Modal/successScreen.vue";
import axios from 'axios'
import { HEADERS, POSTURL } from '@/constants/constants';
import { handleError } from '@/utils/handleErrors';


export default {
  data: () => ({
    step: 1,
    error: "",
    alert: false,
    items: ["Mobile", "Web"],
    loading: false,
    title:"",
    message:"",
    recipient:""
  }),
  components: {
    successScreen,
  },
  methods: {
    closeNotification() {
      this.step = 1
      this.$emit("closeNotification");
    },
    sendNotification() {
      if(this.title == '' || this.message =='' || this.recipient == ''){
        return
      }
      this.loading = true;
      if(this.recipient == 'Web'){
      axios({
        method: "POST",
        url: POSTURL+"profiling/notificationmgt/one-signal/send-notification-segment",
        headers: HEADERS,
        data: {
          segments: ["Subscribed Users"],
          message: this.message,
          heading: this.title,
        },
      }).then(()=>{
        
        this.loading = false
        this.step++
      }).catch(()=>{
        this.loading = false
      })
    }

    else{
      axios({
        method: "POST",
        url: POSTURL+"profiling/notificationmgt/firebase/notification/topic",
        headers: HEADERS,
        data: {
          title:this.title,
          message: this.message,
          topic: "All-Users",
        },
      }).then(()=>{
        
        this.loading = false
        this.step++
      }).catch((error)=>{
        handleError(error.message)
        this.loading = false
      })
    }
    },
  },
};
</script>
  
  
  
  <style scoped>
@media only screen and (max-width: 600px) {
  .promotionsContainer {
    padding: 20px !important;
  }

  .promotionsHeading {
    display: flex;
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 776px) {
}

@media only screen and (min-width: 894px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 996px) {
  .promotionsContainer {
    padding: 0px 20px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

.primarybtn {
  color: white !important;
  background: #0582d2 !important;
  padding: 24px 16px !important;
  border-radius: 8px;
}

.dragAndDropContainer {
  border: 1px dashed #0582d2;
  background: #f5fbff;
  border-radius: 10px;
  padding: 20px 0px;
  position: relative;
}

.dragAndDropContainer .preview-card {
  padding: 0px 10px;
}
.hidden-input {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}
.imageContainer {
  padding: 0px;
}
.outlinedbtn {
  padding: 24px 16px !important;
}
</style>