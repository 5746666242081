import api from "@/services/api";
import { handleError } from "@/utils/handleErrors";
import { ADMINUSER } from "@/constants/constants";

export default {
  namespaced: true,
  state: {
    loading: false,
    allcrowdfunding: [],
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_CROWDFUNDING(state, value) {
      state.allcrowdfunding = value;
    },
  },
  actions: {
    fetchAllCrowdfunding: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.postRequest(
          "settlements/onetimesettlementmgt/crowd-funding/get-all-transactions",
          {
            walletNo: ADMINUSER.phone,
          }
        );
        commit("SET_CROWDFUNDING", response.data.data);
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },
  },
};
