import api from "@/services/api";
import { handleError } from "@/utils/handleErrors";

export default {
  namespaced: true,
  state: {
    page: 0,
    payments: [],
    loading: false,
    total: 10,
  },
  mutations: {
    SET_PAYMENTS(state, value) {
      state.payments = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.total = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_PAGE(state, value) {
      state.page = value;
    },
  },
  actions: {
    fetchPayments: async ({ commit, rootState }) => {
      commit("SET_LOADING", true);
      try {
        const response = await api.getRequest(
          `profiling/usermgt/get-paginated-payments?pageNo=${rootState.payment.page}&pageSize=10`
        );
        commit("SET_PAYMENTS", response.data.data.payments);
        commit("SET_TOTAL_PAGES", response.data.data.total_transactions);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },

    setPaymentPagination: async (context, value) => {
      try {
        context.commit("SET_LOADING", true);
        const response = await api.getRequest(
          "profiling/usermgt/get-paginated-payments?pageNo=" +
            value +
            "&pageSize=" +
            10
        );
        context.commit("SET_PAYMENTS", response.data.data.payments);
        context.commit("SET_PAGE", value);
        context.commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        context.commit("SET_LOADING", false);
      }
    },
  },
};
