// DashboardMixin.js
import DashboardBody from "@/components/Dashboard/DashboardBody.vue";
import { mapState } from "vuex";
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";

export const DashboardMixin = {
  components: {
    DashboardBody,
    DashboardLayout,
  },

  computed: {
    ...mapState(["admin"]),
    ...mapState(["transaction"]),
    ...mapState(["users"]),
    ...mapState(["graph"]),
  },

  created() {
    this.$store.dispatch("users/fetchUsersCount");
    this.$store.dispatch("transaction/fetchAllTransactionsCount");
    this.$store.dispatch("transaction/fetchPendingTransactionsCount");
    this.$store.dispatch("transaction/fetchActiveTransactionsCount");
    this.$store.dispatch("transaction/fetchCompleteTransactionsCount");
    this.$store.dispatch("transaction/fetchRecentTransactions");
    this.$store.dispatch("users/fetchLastUsers");
    this.$store.dispatch("graph/fetchGraphUsers");
    this.$store.dispatch("graph/fetchGraphTransactions");
  },
};
