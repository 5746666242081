<template>
  <DashboardLayout>
    <TransactionsBody
      :transactions="transaction.transactions"
      :page="transaction.page"
      :total="transaction.total"
      :loading="transaction.loading"
    />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import TransactionsBody from "@/components/Transactions/TransactionsBody.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    TransactionsBody,
  },

  computed: {
    ...mapState(["transaction"]),
  },

  created() {
    this.$store.dispatch("transaction/fetchTransactions");
  },
};
</script>
  
<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>