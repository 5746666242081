import api from "@/services/api";
import { handleError } from "@/utils/handleErrors";

export default {
  namespaced: true,
  state: {
    loading: false,
    notifications: [],
    pushnotifications: [],
  },

  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_NOTIFICATIONS(state, value) {
      state.notifications = value;
    },

    SET_PUSH_NOTIFICATIONS(state, value) {
      state.pushnotifications = value;
    },
  },

  actions: {
    fetchPendingKyc: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest(
          "profiling/walletmgt/get-pending-utilbill-approval"
        );
        commit("SET_NOTIFICATIONS", response.data.data["pending-approvals"]);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },

    fetchPushNotifications: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest(
          "profiling/notificationmgt/one-signal/getNotifications"
        );
        commit(
          "SET_PUSH_NOTIFICATIONS",
          response.data.data.notifications.notifications
        );
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
  },
};
