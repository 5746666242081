<template>
  <div class="rolesContainer">
    <v-card flat color="white">
      <div class="rolesFilterFlex">
        <div>
          <v-text-field
            style="max-width: 590px"
            class="searchField mt-5"
            label="Search Members"
            single-line
            prepend-inner-icon="mdi-magnify"
            @keyup="searchUsers"
            v-model="search"
          />
        </div>
        <v-spacer></v-spacer>

        <div class="filterPosition">
          <!-- Date filter -->
          <MenuFilter
            :data="FILTERDATES"
            @filter="filterDate"
            :type="'Last Active'"
            :title="dateCreated"
          />

          <!-- Status filter -->
          <MenuFilter
            :data="roles"
            @filter="filterStatus"
            :type="'Permission Level'"
            :title="role"
          />

          <!-- Role filter -->

          <v-btn
            @click="addMember = true"
            large
            color="#0582D2"
            class="rounded-lg button white--text"
            depressed
            ><v-icon>mdi-plus</v-icon>Invite Member</v-btn
          >
        </div>
      </div>
    </v-card>

    <v-dialog max-width="580px" v-model="addMember">
      <AddMember @closeModal="closeModal" :groups="groups" />
    </v-dialog>
  </div>
</template>


<script>
import MenuFilter from "@/elements/MenuFilter.vue";
import AddMember from "./Dialogs/AddMember.vue";
import { FILTERDATES } from "@/constants/constants";
export default {
  components: { AddMember, MenuFilter },
  props: {
    roles: {
      type: Array,
    },
    groups: {
      type: Array,
    },
  },
  data: () => ({
    role: "Super Admin",
    lastActive: "All",
    addMember: false,
    items: ["Super Admin", "Admin", "Role 1", "Role 2", "Role 3", "Role 4"],
    dateCreated: "All",
    search: "",
    FILTERDATES,
  }),
  methods: {
    closeModal() {
      this.addMember = false;
    },
    filterDate(e) {
      console.log(e);
    },
    filterStatus(e) {
      this.role = e;
      this.$store.dispatch("admin/filterAdminUsers", e);
    },

    searchUsers() {
      this.$store.dispatch("admin/searchAdminUsers", this.search.toLowerCase());
    },
  },
};
</script>



<style scoped>
.rolesContainer {
  padding: 0px 20px 0px 20px;
}

.createPermissionCard {
  padding: 40px;
}

@media only screen and (min-width: 600px) {
  .rolesFilterFlex {
    display: flex;
  }
  .filterPosition {
    margin: 30px 0px 0px 0px;
  }
}
</style>