<template>
  <div>
    <v-list three-line v-for="(notification, i) in userNotifications" :key="i">
      <template>
        <v-list-item>
          <v-btn
            fab
            outlined
            small
            class="d-flex justify-center mr-5"
            color="#0582D2"
            ><v-icon color="#0582D2" size="20px">mdi-bell</v-icon>
          </v-btn>

          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >{{ notification.firstName }} {{ notification.lastName }} ({{
                notification.walletNo
              }}) uploaded a Utility bill for approval</v-list-item-title
            >
            <v-list-item-subtitle
              ><button @click="viewUtilityBill(notification)">
                View Utility Bill
              </button>
              <v-btn
                class="ml-3"
                color="#0066f5"
                outlined
                small
                @click="approveUtilityBill(notification)"
              >
                Approve Utility Bill
              </v-btn>

              <v-btn
                class="ml-3"
                color="#ff2000"
                outlined
                small
                @click="declineUtilityBill(notification)"
              >
                Decline Utility Bill
              </v-btn>

              <v-btn
                class="white--text ml-3"
                color="#0066f5"
                small
                @click="goToProfile(notification)"
              >
                View profile
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
    </v-list>

    <v-dialog max-width="640px" v-model="billmodal">
      <img
        :src="
          'data:image/octet-stream;base64,' +
          notification.utilityBillsBasz64Image
        "
        width="auto"
      />
    </v-dialog>

    <v-dialog persistent v-model="actionutil" width="580px">
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card class="pa-6">
            <div class="my-3 d-flex justify-space-between">
              <h2>{{ actionTitle }} Utility Bill</h2>
              <v-btn fab small depressed @click="actionutil = !actionutil"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>

            <p>
              Are you sure you want to {{ actionTitle.toLowerCase() }} this
              Utility Bill?
            </p>
            <span class="red--text">{{ error }}</span>
            <div class="d-flex justify-end mb-4">
              <v-btn
                @click="actionutil = !actionutil"
                outlined
                large
                color="black"
                class="mr-2 mb-2"
                >Close</v-btn
              >
              <v-btn
                :loading="verifyloading"
                @click="actionBill()"
                large
                color="#0582d2"
                class="white--text"
                >{{ actionTitle }}</v-btn
              >
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <successScreen
            :buttonText="'Continue'"
            @closeSuccess="closeSuccess"
            :title="'Utility bill ' + actionTitle + 'd successfully'"
            :description="
              'You have successfully ' + actionTitle + 'd this utility bill'
            "
          />
        </v-window-item>
      </v-window>
    </v-dialog>
  </div>
</template>
    
    
<script>
import axios from "axios";
import successScreen from "../Modal/successScreen.vue";
import { HEADERS, POSTURL } from "@/constants/constants";

export default {
  data() {
    return {
      billmodal: false,
      image: "",
      verifyloading: false,
      actionutil: false,
      notification: "",
      step: 1,
      actionTitle: "",
      error: "",
    };
  },
  components: {
    successScreen,
  },
  props: {
    userNotifications: {
      type: Array,
    },
  },
  methods: {
    viewUtilityBill(notification) {
      this.billmodal = true;
      this.notification = notification;
    },
    approveUtilityBill(notification) {
      this.actionutil = true;
      this.actionTitle = "Approve";
      this.notification = notification;
    },

    declineUtilityBill(notification) {
      this.actionutil = true;
      this.actionTitle = "Decline";
      this.notification = notification;
    },

    closeSuccess() {
      this.actionutil = !this.actionutil;
      this.location.reload();
    },

    goToProfile(notification) {
      this.$router.push("admin/user?phoneNumber=" + notification.walletNo);
    },

    actionBill() {
      this.verifyloading = true;
      axios({
        method: "POST",
        url: POSTURL + "profiling/walletmgt/validate-util-bill",
        headers: HEADERS,
        data: {
          walletNo: this.notification.walletNo,
          actionCode: this.actionTitle === "Approve" ? 1 : 0,
          actionDesc: this.actionTitle,
        },
      })
        .then(() => {
          this.verifyloading = false;
          this.step++;
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch((error) => {
          console.error(error);
          this.verifyloading = false;
          this.step++;
          setTimeout(() => {
            location.reload();
          }, 1500);
        });
    },
  },
};
</script>
    
    
<style scoped>
.object-fit {
  object-fit: cover;
}
.time {
  font-size: 10px;
}
</style>