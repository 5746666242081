<template>
  <v-main>
    <div class="reconcileContainer">
      <div class="finance__title__flex">
        <h1>Finance</h1>
        <v-btn
          @click="handleReconcile"
          depressed
          :color="BRANDCOLOR"
          class="white--text"
          large
        >
          <v-icon>mdi-plus</v-icon> Make Bulk Transfer</v-btn
        >
      </div>

      <TableComponent :columns="columns">
        <tbody v-for="(project, i) in crowdfunding" :key="i">
          <tr class="text-center">
            <td>
              <v-avatar color="blue" size="35px" class="white--text">{{
                project.customerName.slice(0, 2)
              }}</v-avatar>
            </td>
            <td>{{ project.customerName }}</td>
            <td>{{ project.title }}</td>
            <td>{{ project.goalAmount | formatAmount }}</td>
            <td>{{ project.startDate }}</td>
            <td>{{ project.endDate }}</td>
            <td>
              {{
                tab === "withdrawal"
                  ? project.cashOutRequestApprovalStatus
                  : project.approvalStatus
              }}
            </td>
            <td>{{ project.contributions | formatAmount }}</td>
            <td>
              <!-- <div v-if="project.approval !== '1'">
                <v-btn @click="showCampaign(project)" color="#068B03" text
                  ><v-icon>mdi-check</v-icon>Approve</v-btn
                >
                <v-btn @click="showCampaign(project)" color="#DC2626" text
                  ><v-icon>mdi-close</v-icon>Decline</v-btn
                >
              </div> -->
              <!-- <v-menu v-if="project.approval === '1'" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showCampaign(project)">
                    View Campaign
                  </v-list-item>
                  <v-list-item
                    v-if="
                      tab === 'withdrawal' && project.cashOutRequestApproval !== '2'
                    "
                  >
                    Approve Withdrawal
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </td>
          </tr>
        </tbody>
      </TableComponent>
    </div>
  </v-main>
</template>
        
        
        
  <script>
import { BRANDCOLOR } from "@/constants/constants";
import TableComponent from "../Misc/TableComponent.vue";

export default {
  components: {
    TableComponent,
  },
  data() {
    return {
      BRANDCOLOR,
      columns: [
        "Transaction Title",
        "Total Amount (N)",
        "Recipient Number",
        "Date",
        "Status",
      ],
      loading: false,
      crowdfunding: [],
      reconcile: false,
    };
  },
  methods: {
    handleReconcile() {
      this.reconcile = !this.reconcile;
    },
    closeModal() {
      this.reconcile = !this.reconcile;
    },
  },
};
</script>
        
<style scoped>
.finance__title__flex {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 20px 0px;
}
</style>
        
        
        