<template>
  <div class="py-8 tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th class="font-weight-medium">Transaction Details</th>
          <th class="font-weight-medium">Details</th>
          <th class="font-weight-medium">Currency</th>
          <th class="font-weight-medium">Transaction Type</th>
          <th class="font-weight-medium">Date</th>
          <th class="font-weight-medium">Amount</th>
          <th class="font-weight-medium">Balance After</th>
        </tr>
      </thead>
      <tbody v-for="(t, i) in statementlist" :key="i">
        <tr>
          <td>{{ t.remarks | formatRemark }}</td>
          <td>{{ t.explEng }}</td>
          <td>{{ t.curCode }}</td>
          <td>
            <div :class="t.drCrDesc === 'D' ? 'debit' : 'credit'">
              {{ t.drCrDesc === "D" ? "Debit" : "Credit" }}
            </div>
          </td>
          <td>{{ t.intDate }}</td>
          <td>NGN {{ t.traAmt | formatAmount }}</td>
          <td>{{ t.balAfter | formatAmount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
        
      
      
<script>
import Vue from "vue";
import { formatAmount, formatRemark } from "../../utils/formatter";
Vue.filter("formatRemark", formatRemark);
Vue.filter("formatAmount", formatAmount);

export default {
  data() {
    return {
      showBalance: true,
      page: 1,
    };
  },
  computed: {},
  props: {
    statementlist: {
      type: Array,
    },
  },
};
</script>
      
      <style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px 0px 20px 15px  !important;
  background: #f9fafb;
  text-align: start;
}

td {
  font-size: 14px;
  padding: 10px 0px 10px 15px 
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}
.v-date-picker-title {
  color: pink !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  background: red;
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
  background: red;
}

.checkBoxPosition {
  margin: 0px 0px 0px 11px;
}

.debit {
  color: red;
  background: #feeaf3;
  padding: 8px 5px;
  margin: 10px 0px;
  text-align: center;
}

.credit {
  background: #ecfff6;
  color: #22c55e;
  padding: 9px 5px;
  margin: 10px 0px;
  text-align: center;
  
}
</style>
      