<template>
  <v-card class="createPermissionCard" max-width="500px">
    <ModalHeader
      :title="`Edit ${role}'s Permissions`"
      @closeModal="closeModal"
    />

    <div>
      <v-checkbox
        v-for="(permission, i) in permissions"
        :key="i"
        :value="permission.userRole"
        :label="permission.userRole | unslugify"
        v-model="selectedpermissions"
        @change="handleSelect(permission.userRole)"
      ></v-checkbox>
    </div>

    <OverlayLoader :loading="loading" :description="'Updating..'" />
  </v-card>
</template>



<script>
import ModalHeader from "@/elements/ModalHeader.vue";
import { unslugifyString } from "@/utils/formatter";
import OverlayLoader from "@/components/Misc/OverlayLoader.vue";
import Vue from "vue";
import { handleError, handleResponse } from "@/utils/handleErrors";
import api from "@/services/api";
Vue.filter("unslugify", unslugifyString);

export default {
  props: {
    permissions: {
      type: Array,
    },
    role: {
      type: String,
    },
    rolepermissions: {
      type: Array,
    },
  },
  components: { ModalHeader, OverlayLoader },
  data() {
    return {
      selectedpermissions: [...this.rolepermissions],
      loading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async handleSelect(e) {
      if (this.selectedpermissions.length > this.rolepermissions.length) {
        try {
          this.loading = true;
          const response = await api.postRequest(
            "profiling/usermgt/add-role-group",
            {
              userRole: e,
              userGroupName: this.role,
            }
          );

          handleResponse(response, { reload: false });
          this.loading = false;
        } catch (error) {
          this.loading = false;
          handleError(error.message);
        }
      } else {
        try {
          this.loading = true;
          const response = await api.postRequest(
            "profiling/usermgt/delete-role-group",
            {
              userRole: e,
              userGroupName: this.role,
            }
          );

          handleResponse(response, { reload: false });
          this.loading = false;
        } catch (error) {
          this.loading = false;
          handleError(error.message);
        }
      }
    },
  },
};
</script>