<template>
  <div>
    <HeaderComponent :title="'Reconcile'" @closeBtn="closeModal" />

    <FlexCard :title="'Total wallet balance'" :value="totalWalletBalance" />

    <FlexCard
      :title="'Total Commission Earned'"
      :value="totalCommissionEarned"
    />

    <v-form class="space-y-0">
      <div>
        <label>Unpaid Fund</label>
        <v-text-field
          v-model="unpaidfund"
          outlined
          dense
          style="height: 55px"
          type="number"
          inputmode="number"
        />
      </div>

      <div>
        <label>Investment Fund</label>
        <v-text-field
          v-model="investmentfund"
          outlined
          dense
          style="height: 55px"
          type="number"
          inputmode="number"
        />
      </div>

      <div>
        <label>Other charges</label>
        <v-text-field
          v-model="charges"
          outlined
          dense
          style="height: 55px"
          type="number"
          inputmode="number"
        />
      </div>

      <div>
        <label>Pool Account Balance</label>
        <v-text-field
          v-model="accountbalance"
          outlined
          dense
          style="height: 55px"
          type="number"
          inputmode="number"
        />
      </div>

      <v-btn
        depressed
        x-large
        :color="BRANDCOLOR"
        block
        class="white--text rounded-lg"
        @click="nextStep"
        :loading="loading"
        :disabled="disabled"
      >
        Submit</v-btn
      >
    </v-form>
  </div>
</template>


<script>
import HeaderComponent from "@/components/Misc/HeaderComponent.vue";
import FlexCard from "@/components/Misc/FlexCard.vue";
import { BRANDCOLOR, HEADERS, POSTURL } from "@/constants/constants";
import axios from "axios";
import { handleError } from '@/utils/handleErrors';
export default {
  components: {
    HeaderComponent,
    FlexCard,
  },
  props: {
    totalCommissionEarned: {
      type: [Number, String],
    },
    totalWalletBalance: {
      type: [Number, String],
    },
    transaction: {
      type: Object,
    },
    emailAddress: {
      type: String,
    },
  },
  data() {
    return {
      BRANDCOLOR: BRANDCOLOR,
      unpaidfund: "",
      accountbalance: "",
      charges: "",
      investmentfund: "",
      loading: false,
      disabled: false,
    };
  },
  watch: {
    disabled() {
      if (
        this.unpaidfund &&
        this.accountbalance &&
        this.investmentfund &&
        this.charges
      ) {
        this.disabled = false;
      }
    },
  },
  methods: {
    nextStep() {
      this.loading = true;
      axios({
        method: "POST",
        url:
          POSTURL +
          "local-transfer/localtransgermgt/get-reconciliation-variance",
        headers: HEADERS,
        data: {
          processId: this.transaction.processId,
          poolAccountBalance: this.totalWalletBalance,
          totalWalletBalance: this.totalWalletBalance,
          investmentFund: this.investmentfund,
          commissionEarned: this.totalCommissionEarned,
          paystackFundsNotPaid: this.unpaidfund,
          otherCharges: this.charges,
          emailAddress: this.emailAddress,
        },
      })
        .then(() => {
          this.loading = false;
          this.$emit("nextStep");
        })
        .catch((error) => {
          this.loading = false;
          handleError(error.message)
        });
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>