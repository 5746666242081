import axios from "axios";
import { ADMINHEADERS, GETURL, HEADERS, POSTURL } from "@/constants/constants";

export default {
  getRequest(url) {
    return axios({
      method: "GET",
      url: GETURL + url,
      headers: HEADERS,
    });
  },

  postRequest(url, data) {
    return axios({
      method: "POST",
      url: POSTURL + url,
      headers: HEADERS,
      data,
    });
  },

  adminRequest(url, data) {
    return axios({
      method: "POST",
      url: POSTURL + url,
      headers: ADMINHEADERS,
      data,
    });
  },
};
