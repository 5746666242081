<template>
  <div class="py-8 tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th class="font-weight-medium">Transaction ID</th>
          <th class="font-weight-medium">Buyer</th>
          <th class="font-weight-medium">Seller</th>
          <th class="font-weight-medium">Payment Type</th>
          <th class="font-weight-medium">Amount</th>
          <th class="font-weight-medium">Date Created</th>
          <th class="font-weight-medium">Category</th>
          <th class="font-weight-medium">Action</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(t, i) in 5" :key="i" class="text-center">
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
    
    
    
    
    
<script>
export default {};
</script>
    
<style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px !important;
  background: #f9fafb;
}

td {
  font-size: 14px;
  padding: 10px !important;
  text-align: center;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}
.v-date-picker-title {
  color: pink !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  background: red;
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
  background: red;
}

.checkBoxPosition {
  margin: 14px 0px 0px 11px;
}
</style>
    