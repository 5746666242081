<template>
  <div class="newUser">
    <h2>New Users</h2>

    <div v-if="!users.length">
      <div class="userLoading" v-for="i in 6" :key="i">
        <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
      </div>
    </div>

    <div
      class="d-flex my-4"
      v-for="(user, i) in users && users.slice(0, 6)"
      :key="i"
    >
      <v-avatar color="#0582D2">
        <span class="white--text">{{ user.firstName.slice(0, 2) }}</span>
      </v-avatar>
      <p class="userName">{{ user.firstName }} {{ user.lastName }}</p>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  props: {
    users: {
      type: Array,
    },
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .newUser {
    width: 95%;
    margin: 30px auto auto auto;
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px 16px;
    max-width: 346px;
  }
}

@media only screen and (min-width: 600px) {
  .newUser {
    width: 95%;
    margin: 30px 0px 0px 20px;
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px 16px;
    max-width: 346px;
  }
}

@media only screen and (min-width: 768px) {
  .newUser {
    width: 95%;
    margin: 20px 0px 0px 20px;
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px 16px;
    max-width: 346px;
  }
}

@media only screen and (min-width: 996px) {
  .newUser {
    width: 95%;
    margin: 30px 0px 0px 20px;
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px 16px;
    max-width: 346px;
  }
}

@media only screen and (min-width: 1200px) {
  .newUser {
    width: 95%;
    margin: 0px auto auto auto;
    background: #f8f8f9;
    border-radius: 8px;
    padding: 24px 16px;
    max-width: 346px;
  }
}
.userName {
  margin: 13px 0px 0px 10px;
  font-weight: 600;
}

.userLoading > * {
  margin-bottom: 10px;
}
</style>