<template>
  <DashboardLayout>
    <ReversalBody
      :reversals="externalReversals"
      :grandTotalReversal="'[null]'"
      :totalCurrentMonthReversal="'[null]'"
      :loading="loading"
    />
  </DashboardLayout>
</template>



<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import ReversalBody from "@/components/ExternalReversal/ReversalBody.vue";
import { mapState } from "vuex";
export default {
  components: {
    DashboardLayout,
    ReversalBody,
  },

  computed: {
    ...mapState("reconciliation", {
      externalReversals: "externalReversals",
      grandTotalReversal: "grandTotalReversal",
      totalCurrentMonthReversal: "totalCurrentMonthReversal",
      loading: "loading",
    }),
  },

  created() {
   this.$store.dispatch("reconciliation/fetchExternalReversals");
  },
};
</script>