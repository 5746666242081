import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import AdminLoginPage from "../views/Auth/LoginPage.vue";
import AdminDashboardVue from "../views/DashboardPage.vue";
import AdminUsersPage from "../views/UsersPage";
import AdminPayment from "../views/PaymentPage";
import AdminSingleUser from "../views/SingleUser";
import AdminRoles from "../views/RolesPage";
import AdminTransactions from "../views/TransactionsPage";
import AdminPushNotification from "../views/PushNotificationPage";
import AccountLimit from "../views/AccountLimit";
import PromotionsPage from "../views/PromotionsPage.vue";
import Notifications from "../views/NotificationPage.vue";
import AdminCrowdFundingPage from "../views/CrowdFundingPage.vue";
import AdminStatements from "../views/StatementsPage.vue";
import AdminReconciliation from "../views/ReconciliationPage.vue";
import AdminFinance from "../views/FinancePage.vue";
import AdminReversal from "../views/ReversalPage.vue";
import AdminExternalReversal from "../views/ExternalReversal.vue"
import AdminLoggedIssues from "../views/LoggedIssuesPage.vue"
import AdminChangePassword from "../views/ChangePasswordPage.vue"
import { isAuthenticated } from "@/utils/auth";

Vue.use(VueRouter);
Vue.use(Meta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: "/",
    name: "AdminLoginPage",
    component: AdminLoginPage,
    meta: { isAuth: true },
  },

  {
    path: "/login",
    name: "AdminLoginPage",
    component: AdminLoginPage,
    meta: { isAuth: true },
  },

  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLoginPage,
    meta: { isAuth: true },
  },

  {
    path: "/overview",
    name: "AdminDashboard",
    component: AdminDashboardVue,
    meta: { requiresAuth: true },
  },

  {
    path: "/dashboard",
    name: "AdminDashboard",
    component: AdminDashboardVue,
    meta: { requiresAuth: true },
  },

  {
    path: "/users",
    name: "AdminUsers",
    component: AdminUsersPage,
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/user",
    name: "AdminSingleUser",
    component: AdminSingleUser,
    meta: { requiresAuth: true },
  },

  {
    path: "/promotions",
    name: "PromotionsPage",
    component: PromotionsPage,
    meta: { requiresAuth: true },
  },

  {
    path: "/roles",
    name: "AdminRoles",
    component: AdminRoles,
    meta: { requiresAuth: true },
  },

  {
    path: "/account-limit",
    name: "AccountLimit",
    component: AccountLimit,
    meta: { requiresAuth: true },
  },

  {
    path: "/transactions",
    name: "AdminTransactions",
    component: AdminTransactions,
    meta: { requiresAuth: true },
  },

  {
    path: "/payments",
    name: "AdminPayment",
    component: AdminPayment,
    meta: { requiresAuth: true },
  },

  {
    path: "/push-notifications",
    name: "AdminPushNotification",
    component: AdminPushNotification,
    meta: { requiresAuth: true },
  },

  {
    path: "/notifications",
    name: "AdminNotifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },

  {
    path: "/campaigns",
    name: "AdminCrowdfunding",
    component: AdminCrowdFundingPage,
    meta: { requiresAuth: true },
  },

  {
    path: "/statements",
    name: "AdminStatements",
    component: AdminStatements,
    meta: { requiresAuth: true },
  },

  {
    path: "/reconciliation",
    name: "AdminReconciliation",
    component: AdminReconciliation,
    meta: { requiresAuth: true },
  },

  {
    path: "/finance",
    name: "AdminFinance",
    component: AdminFinance,
    meta: { requiresAuth: true },
  },

  {
    path: "/reversal",
    name: "AdminReversal",
    component: AdminReversal,
    meta: { requiresAuth: true },
  },
 
  {
    path: "/external-reversal",
    name: "AdminExternalReversal",
    component: AdminExternalReversal,
    meta: { requiresAuth: true },
  },

  {
    path: "/logged-issues",
    name: "AdminLoggedIssues",
    component: AdminLoggedIssues,
    meta: { requiresAuth: true },
  },

  {
    path: "/change-password",
    name: "AdminChangePassword",
    component: AdminChangePassword,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//prevent not logged-in users from accessing dashboard pages
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next("/login");
  } else next();
});

//prevent logged in users from accessing auth pages
router.beforeEach((to, from, next) => {
  if (to.meta.isAuth && isAuthenticated()) {
    next("/overview");
  } else next();
});

export default router;
