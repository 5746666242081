<template>
  <v-main>
    <div class="transactionsContainer">
      <h1>Users</h1>
    </div>

    <v-card flat class="mr-4" color="white">
      <div class="menufilter-grid">
        <div class="d-flex">
          <div>
            <v-text-field
              @keyup="searchUsers"
              style="max-width: 590px"
              class="searchField mt-5"
              label="Search for users"
              single-line
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            />
          </div>
          <div v-if="search" class="mt-7">
            <v-btn
              @click="handleSearch"
              depressed
              :color="BRANDCOLOR"
              class="white--text"
              rounded
              >Search
            </v-btn>
          </div>
        </div>

        <div class="menufilter">
          <!-- Date filter -->
          <MenuFilter
            @filter="filterDate"
            :type="'Date Created'"
            :title="dateCreated"
            :data="FILTERDATES"
          />

          <!-- Role filter -->
          <MenuFilter
            @filter="filterTier"
            :type="'Account Level'"
            :title="tierLevel"
            :data="TIERLEVELS"
          />
        </div>
      </div>

      <UsersLoadingTable v-if="users.length == 0 || loading" />
      <UsersTable
        v-if="users.length != 0 && !loading"
        :users="users"
        :page="page"
        :search="search"
        :userCount="userCount"
        :filter="filter"
      />
    </v-card>
  </v-main>
</template>
  
  
  
<script>
import UsersTable from "./UsersTable.vue";
import UsersLoadingTable from "./UsersLoadingTable.vue";
import { BRANDCOLOR, FILTERDATES, TIERLEVELS } from "@/constants/constants";
import MenuFilter from "@/elements/MenuFilter.vue";
import { filterDateFormat } from "@/utils/formatter";

export default {
  data() {
    return {
      dateCreated: "",
      status: "",
      tierLevel: "",
      search: "",
      role: "",
      BRANDCOLOR,
      FILTERDATES,
      TIERLEVELS,
    };
  },
  props: {
    users: {
      type: Array,
    },
    page: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
    userCount: {
      type: Number,
    },
    filter: {
      type: String,
    },
  },
  components: { UsersTable, UsersLoadingTable, MenuFilter },
  methods: {
    searchUsers(e) {
      this.search = e.target.value;
      if (e.key === "Enter") {
        this.$store.dispatch("users/searchUsers", e.target.value);
      }
    },
    handleSearch() {
      this.$store.dispatch("users/searchUsers", this.search);
    },
    filterTier(e) {
      if (e === "All") {
        location.reload();
      }
      this.tierLevel = e;
      this.$store.dispatch("users/filterTier", e.split(" ")[1]); //split Tier X and get the number
    },
    filterDate(e) {
      if (e === "Last 7 days") {
        this.dateCreated = e;
        let date = Date.now() - 604800000;
        let startDate = filterDateFormat(date);
        let endDate = filterDateFormat(Date.now());
        this.$store.dispatch("users/filterDate", { startDate, endDate });
      } else if (e === "Last 14 days") {
        this.dateCreated = e;
        let date = Date.now() - 604800000 * 2;
        let startDate = filterDateFormat(date);
        let endDate = filterDateFormat(Date.now());
        this.$store.dispatch("users/filterDate", { startDate, endDate });
      } else if (e === "Last 30 days") {
        this.dateCreated = e;
        let date = Date.now() - 86400000 * 30;
        let startDate = filterDateFormat(date);
        let endDate = filterDateFormat(Date.now());
        this.$store.dispatch("users/filterDate", { startDate, endDate });
      } else if (e === "All") {
        location.reload();
      }
    },
  },
};
</script>
  
  <style scoped>
.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
.transactionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 44px 0px;
}

.transactionCardContainer {
  margin: 0px 90px 44px 0px;
}
.tabContainer {
  padding: 20px 0px 0px 24px;
}
.button {
  padding: 40px 0px;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .menufilter-grid {
    display: grid;
  }
}

@media only screen and (min-width: 768px) {
  .menufilter-grid {
    display: flex;
    justify-content: space-between;
  }
}

.menufilter {
  margin: 30px 0px 0px 0px;
  display: flex;
  overflow-x: auto;
}
</style>
  
  
  