<template>
  <v-app>
    <v-main class="bgMobile">
      <v-card class="loginCard" elevation="0" width="92%">
        <v-container>
          <div class="d-flex justify-space-between">
            <a href="/">
              <img src="../../assets/kuleanBrand.svg" />
            </a>
            <!-- <router-link to="/signup"> CREATE ACCOUNT</router-link> -->
          </div>
          <h2 style="margin: 16px 0px 0px 0px">Admin Login</h2>
          <v-alert class="mt-1" :value="alert" type="error">
            {{ message }}
          </v-alert>

          <v-form ref="form" v-model="valid" lazy-validation class="mt-2 pa-1">
            <label>Email address</label>
            <v-text-field
              v-model="email"
              outlined
              dense
              color="#0582D2"
              style="margin-bottom: 0px"
              :rules="emailRules"
            />

            <label>Password</label>
            <v-text-field
              v-model="password"
              outlined
              dense
              color="#0582D2"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :rules="passwordRules"
              class="input-group--focused"
              @click:append="show = !show"
              style="margin-bottom: -15px"
            />

            <!-- <div class="text-right">
            <router-link to="/forgot-password"> Forgot Password</router-link>
              </div> -->

            <v-checkbox
              color="#0582D2"
              v-model="checkbox"
              label="Remember me"
            ></v-checkbox>

            <v-btn
              depressed
              class="mt-3 white--text createAccount animate__animated animate__fadeInUp"
              style="
                height: 48px;
                margin-top: -13px;
                width: 100%;
                border-radius: 5px;
                margin-bottom: 8px;
              "
              large
              @click="login()"
              :loading="loading"
            >
              Log In</v-btn
            >
          </v-form>
        </v-container>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { LOGINURL } from "@/constants/constants";
import { handleError } from "@/utils/handleErrors";

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      snackbar: false,
      alert: false,
      dialog: false,
      message: "",
      errors: "",
      checkbox: false,
      emailRules: [
        (v) => !!v || "Email address is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      show: false,
      valid: true,
    };
  },

  methods: {
    async login() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      if (this.password == "") {
        this.alert = true;
        this.message = "Please enter your password";
        setTimeout(() => {
          this.alert = false;
        }, 1000);
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      axios({
        method: "POST",
        url: LOGINURL + "session-manager/session/authenticate/admin-user",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          emailAddress: this.email,
          password: this.password,
        },
      })
        .then(async (response) => {
          if (response.data.statusCode == 400) {
            this.message = response.data.description;
            this.alert = true;
            setTimeout(() => {
              this.alert = false;
            }, 2000);
            this.loading = false;
            return;
          } else {
            sessionStorage.setItem(
              "userData",
              JSON.stringify({
                firstName: response.data.data.firstName,
                lastName: response.data.data.lasName,
                email: response.data.data.emailAddress,
                phone: response.data.data.phoneNumber,
              })
            );
            sessionStorage.setItem("adminToken", response.data.data.idToken);
            await this.$store.dispatch(
              "admin/fetchAdminUserRole",
              response.data.data.idToken
            );
            window.location.href = "/overview";
          }
        })
        .catch((error) => {
          handleError(error.message);
          this.errors = error.message;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 400px) {
  .buttonFlex {
    display: flex;
    justify-content: center;
  }
}

a,
router-link {
  color: #0582d2 !important;
  text-decoration: none !important;
}
.v-btn {
  text-transform: none !important;
}
.createAccount {
  background: linear-gradient(180deg, #0582d2 0%, #0582d2 100%);
}
.bgMobile {
  background-image: url("../../assets/authBackground.svg");
  background-position: center;
  background-size: cover;
}
.login {
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .loginCard {
    margin: 20% auto 63% auto;
    border: solid 1px #eef1f1;
    padding: 10px;
    max-width: 430px;
  }
}

@media only screen and (min-width: 600px) {
  .loginCard {
    margin: 50px auto 0px auto;
    border: solid 1px #eef1f1;
    padding: 0px 20px 10px 20px;
    max-width: 430px;
    border-radius: 5px;
  }
}
</style>