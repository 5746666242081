<template>
  <v-sheet width="500px" class="rounded-lg float-right pa-5">
    <div class="campaign-modal">
      <HeaderComponent :title="'Campaign Title'" @closeBtn="closeBtn" />
      <v-img
        height="150px"
        :src="'data:image/octet-stream;base64,' + project.campeignImage"
        alt="campaign"
        class="rounded-lg"
      />
      <div>
        <span>Creator Name</span>
        <h4>{{ project.customerName }}</h4>
      </div>

      <div class="campaign-modal-status">
        <span>Status</span>
        <StatusSelect :project="project" :items="items" :tab="tab" />
      </div>

      <div>
        <span>Description</span>
        <p>
          {{ project.description }}
        </p>
      </div>

      <table cellspacing="0">
        <tr>
          <td>
            <div>
              <span>Start Date</span>
              <h4>{{ project.startDate }}</h4>
            </div>
          </td>
          <td>
            <div>
              <span>End Date</span>
              <h4>{{ project.endDate }}</h4>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div>
              <span>Goal Amount</span>
              <h4>{{ project.goalAmount }}</h4>
            </div>
          </td>
          <td>
            <div>
              <span>Amount Raised</span>
              <h4>NGN {{ project.contributions }}</h4>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div>
              <span>Last Donation Date</span>
              <h4>{{ project.transactionDate }}</h4>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </v-sheet>
</template>



<script>
import StatusSelect from "@/components/Misc/StatusSelect.vue";
import HeaderComponent from "../Misc/HeaderComponent.vue";

export default {
  components: { HeaderComponent, StatusSelect },
  data() {
    return {
      items: ["In-Review", "Approve", "Reject"],
      status: "In-Review",
    };
  },
  props: {
    project: {
      type: Object,
    },
    tab: {
      type: String,
    },
  },
  methods: {
    closeBtn() {
      this.$emit("showCampaign");
    },
  },
};
</script>


<style scoped>
.campaign-modal > * {
  margin: 10px 0px 20px 0px;
}

.campaign-modal-status {
  max-width: 150px;
}

.Pending {
  background: #fef3c7;
  color: #f59e0b;
}

table {
  width: 100%;
}

td {
  padding: 10px 0px !important;
}
</style>