<template>
  <div class="py-8 tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th></th>
          <th class="font-weight-medium">Creator Name</th>
          <th class="font-weight-medium">Title</th>
          <th class="font-weight-medium">Amount (N)</th>
          <th class="font-weight-medium">Start Date</th>
          <th class="font-weight-medium">End Date</th>
          <th class="font-weight-medium">Status</th>
          <th class="font-weight-medium">Donations (N)</th>
          <th class="font-weight-medium">Action</th>
        </tr>
      </thead>

      <tbody v-for="(project, i) in crowdfunding" :key="i">
        <tr class="text-center">
          <td>
            <v-avatar color="blue" size="35px" class="white--text">{{
              project.customerName.slice(0, 2)
            }}</v-avatar>
          </td>
          <td>{{ project.customerName }}</td>
          <td>{{ project.title }}</td>
          <td>{{ project.goalAmount | formatAmount }}</td>
          <td>{{ project.startDate }}</td>
          <td>{{ project.endDate }}</td>
          <td>
            {{
              tab === "withdrawal"
                ? project.cashOutRequestApprovalStatus
                : project.approvalStatus
            }}
          </td>
          <td>{{ project.contributions | formatAmount }}</td>
          <td>
            <div v-if="project.approval !== '1'">
              <v-btn @click="showCampaign(project)" color="#068B03" text
                ><v-icon>mdi-check</v-icon>Approve</v-btn
              >
              <v-btn @click="showCampaign(project)" color="#DC2626" text
                ><v-icon>mdi-close</v-icon>Decline</v-btn
              >
            </div>
            <v-menu v-if="project.approval === '1'" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showCampaign(project)">
                  View Campaign
                </v-list-item>
                <v-list-item
                  v-if="
                    tab === 'withdrawal' && project.cashOutRequestApproval !== '2'
                  "
                  @click="approveWithdrawal(project)"
                >
                  Approve Withdrawal
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>

      <TableLoader v-if="loading" :columns="8" />
    </table>

    <v-dialog persistent v-model="showcampaign">
      <CampaignModal
        :tab="tab"
        :project="project"
        @showCampaign="showCampaign"
      />
    </v-dialog>
  </div>
</template>
    
    
    
    
    
<script>
import CampaignModal from "./CampaignModal.vue";
import TableLoader from "@/components/Misc/TableLoader.vue";
import { formatAmount } from "@/utils/formatter";
import Vue from "vue";
Vue.filter("formatAmount", formatAmount);

export default {
  data() {
    return {
      showcampaign: false,
      page: 1,
      project: {},
      checkbox: false,
    };
  },
  props: {
    crowdfunding: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    tab: {
      type: String,
    },
  },
  components: { CampaignModal, TableLoader },
  computed: {},
  methods: {
    showCampaign(t) {
      this.project = t;
      this.showcampaign = !this.showcampaign;
    },

    approveWithdrawal(project) {
      this.$emit("approveWithdrawal", project);
    },
  },
};
</script>
    
    <style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px !important;
  background: #f9fafb;
}

td {
  font-size: 14px;
  padding: 10px !important;
  text-align: center;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
}

.checkBoxPosition {
  margin: 0px 0px 0px 11px;
}
</style>
    