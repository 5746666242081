<template>
  <DashboardLayout>
    <UsersBody
      :users="users.paginatedusers"
      :page="users.userpage"
      :loading="users.loading"
      :userCount="users.userCount"
      :filter="users.filter"
    />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import UsersBody from "@/components/Users/UsersBody.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    UsersBody,
  },
  computed: {
    ...mapState(["users"]),
  },
  created() {
    this.$store.dispatch("users/fetchUsersCount");
    this.$store.dispatch("users/fetchPaginatedUsers");
  },
};
</script>
  
  
  
  
  
  
  <style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>