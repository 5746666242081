<template>
  <v-main>
    <div class="statCardsFlex">
      <StatCard title="Total Users" :value="userCount" />

      <StatCard title="All Escrow transactions" :value="alltransactions" />

      <StatCard title="Active" :value="activetransactions" />

      <StatCard title="Completed" :value="completetransactions" />

      <StatCard title="Pending" :value="pendingtransactions" />
    </div>

    <div class="dashboard-grid">
      <TransactionsAdmin :transactions="transactions" />
      <NewUsers :users="users" />
    </div>

    <div class="dashboard-grid-graph">
      <div class="graphContainer">
        <LineChart :datacollection="transactionsData" />
      </div>
      <div class="graphContainer">
        <LineChart :datacollection="usersData" />
      </div>
    </div>
  </v-main>
</template>



<script>
import StatCard from "./Cards/StatCard.vue";
import TransactionsAdmin from "./TransactionsAdmin.vue";
import NewUsers from "./NewUsers.vue";
import LineChart from "./Charts/LineChart.vue";

export default {
  components: {
    StatCard,
    TransactionsAdmin,
    NewUsers,
    LineChart,
  },
  props: {
    userCount: {
      type: Number,
    },
    alltransactions: {
      type: Number,
    },
    pendingtransactions: {
      type: Number,
    },
    activetransactions: {
      type: Number,
    },
    completetransactions: {
      type: Number,
    },
    users: {
      type: Array,
    },
    transactions: {
      type: Array,
    },
    transactionGraph: {
      type: Array,
    },
    userGraph: {
      type: Array,
    },
    isUserGraph: {
      type: Boolean,
    },
    isTransactionGraph: {
      type: Boolean,
    },
  },
  data() {
    return {
      showBalance: true,
      picker: null,
      transactionsData: {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        datasets: [
          {
            label: "Transactions",
            data: [0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "#0582D2",
            tension: 0,
          },
        ],
      },
      usersData: {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        datasets: [
          {
            label: "Users",
            data: [0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "#0582D2",
            tension: 0,
          },
        ],
      },
    };
  },

  watch: {
    isUserGraph(_, next) {
      if(next){
      this.usersData = {
        labels: [
          this.userGraph[0]?.createdDate,
          this.userGraph[1]?.createdDate,
          this.userGraph[2]?.createdDate,
          this.userGraph[3]?.createdDate,
          this.userGraph[4]?.createdDate,
          this.userGraph[5]?.createdDate,
          this.userGraph[6]?.createdDate,
          this.userGraph[7]?.createdDate,
        ],
        datasets: [
          {
            label: "Users",
            data: [
              this.userGraph[0]?.totalCount,
              this.userGraph[1]?.totalCount,
              this.userGraph[2]?.totalCount,
              this.userGraph[3]?.totalCount,
              this.userGraph[4]?.totalCount,
              this.userGraph[5]?.totalCount,
              this.userGraph[6]?.totalCount,
              this.userGraph[7]?.totalCount,
            ],
            fill: true,
            borderColor: "#0582D2",
            tension: 0.4,
          },
        ],
      };
    }
    },
    isTransactionGraph(_,next) {
      if(next)
      this.transactionsData = {
        labels: [
          this.transactionGraph[0]?.transactionDate,
          this.transactionGraph[1]?.transactionDate,
          this.transactionGraph[2]?.transactionDate,
          this.transactionGraph[3]?.transactionDate,
          this.transactionGraph[4]?.transactionDate,
          this.transactionGraph[5]?.transactionDate,
          this.transactionGraph[6]?.transactionDate,
          this.transactionGraph[7]?.transactionDate,
        ],
        datasets: [
          {
            label: "Transactions",
            data: [
              this.transactionGraph[0]?.totalCount,
              this.transactionGraph[1]?.totalCount,
              this.transactionGraph[2]?.totalCount,
              this.transactionGraph[3]?.totalCount,
              this.transactionGraph[4]?.totalCount,
              this.transactionGraph[5]?.totalCount,
              this.transactionGraph[6]?.totalCount,
              this.transactionGraph[7]?.totalCount,
            ],
            fill: true,
            borderColor: "#0582D2",
            tension: 0.4,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.statCardsFlex {
  display: grid;
  flex-wrap: nowrap;
  overflow-x: auto;
  grid-template-columns: repeat(5, 1fr);
  padding: 30px 0px 30px 10px;
}
.statCard {
  padding: 32px;
  border-radius: 8px;
  display: flex;
}

.stat {
  width: 160px;
}

.stat h2 {
  font-size: 28px;
  color: #0582d2;
}

.stat p {
  color: #959ba7;
}
.lineSeparatedStat {
  border-left: 1px solid #dedfe3;
  padding: 0px 0px 0px 50px;
  width: 160px;
}

.lineSeparatedStat h2 {
  font-size: 28px;
  color: #0582d2;
}

.lineSeparatedStat p {
  color: #959ba7;
}

.walletCard {
  padding: 46px 20px 40px 20px;

  height: 160px;
  background: linear-gradient(108.45deg, #013580 -4.61%, #0582d2 104.96%);
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}

.walletCard p {
  color: white;
  margin: 0;
  padding: 0;
  font-size: 11px;
}

.walletCard h2 {
  color: white;
  font-weight: 400;
  font-size: 24px;
}

.tableHeading {
  background: #f9fafb;
  border-radius: 20px;
}

table th:first-child {
  border-radius: 10px 0 0 0px;
}

table th:last-child {
  border-radius: 0 10px 0px 0;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 1px solid #f9fafb;
  font-size: 12px;
  padding: 10px !important;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .dashboard-grid {
    margin: 20px 20px 0px 0px;
  }

  .dashboard-grid-graph {
    margin: 20px 20px 0px 0px;
  }
}

@media only screen and (min-width: 768px) {
  .dashboard-grid {
    display: flex;
    margin: 10px 20px 0px 0px;
  }

  .dashboard-grid-graph {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0px 0px 0px 0px;
  }
}

</style>
