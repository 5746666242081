<template>
  <v-window v-model="step">
    <v-window-item :value="1">
      <v-card class="pa-4">
        <div class="promotionsHeading">
          <h2>Create Promotion</h2>
          <v-btn @click="createPromotion" small fab depressed
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-alert v-model="alert" type="error">{{ error }}</v-alert>

        <v-form>
          <label>Header</label>
          <v-text-field single-line outlined dense />

          <label>Sub-title</label>
          <v-text-field single-line outlined dense />

          <div
            id="drop_zone"
            class="dragAndDropContainer"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <div v-if="files.length == 0" class="text-center">
              <div v-if="isDragging">Release to drop files here.</div>
              <v-icon>mdi-plus</v-icon>
              <p>Drag and drop image</p>
              <input
                type="file"
                name="file"
                ref="file"
                id="fileInput"
                class="hidden-input"
                @change="onChange"
              />
            </div>

            <div v-for="file in files" :key="file.name" class="preview-card">
              <div class="d-flex justify-space-between">
                <p>
                  <v-icon small color="#0582D2"> mdi-check </v-icon>
                  {{ file.name }}
                </p>
                <v-btn text color="#0582D2" @click="changeImage">Change</v-btn>
                <input
                  type="file"
                  name="file"
                  ref="changefile"
                  id="fileInput"
                  hidden
                  @change="changeImages"
                />
              </div>
            </div>
          </div>
        </v-form>
        <div class="my-4">
          <v-btn @click="goToPreview" block class="primarybtn">Preview </v-btn>
        </div>
      </v-card>
    </v-window-item>
    <v-window-item :value="2">
      <v-card class="pa-4">
        <div class="promotionsHeading">
          <h2>Preview</h2>
          <v-btn @click="createPromotion" small fab depressed
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="my-3 imageContainer">
          <v-img :src="image" />
        </div>

        <div class="d-flex justify-space-between my-4">
          <v-btn
            @click="createPromotion"
            outlined
            class="outlinedbtn"
            color="#0582D2"
            >Cancel</v-btn
          >
          <v-btn :loading="loading" @click="uploadPromotion" class="primarybtn"
            >Create Promotion</v-btn
          >
        </div>
      </v-card>
    </v-window-item>
    <v-window-item :value="3">
      <successScreen
        :title="'Image Uploaded!'"
        :buttonText="'Continue'"
        :description="'Image has successfully been uploaded'"
        @closeSuccess="createPromotion()"
      />
    </v-window-item>
  </v-window>
</template>

<script>
import axios from "axios";
import successScreen from "@/components/Modal/successScreen.vue";
import { TOKEN, UPLOADURL } from "@/constants/constants";
import { handleError } from '@/utils/handleErrors';

export default {
  data: () => ({
    newpromotion: false,
    isDragging: false,
    files: [],
    step: 1,
    image: "",
    error: "",
    alert: false,
    loading: false,
  }),
  components: {
    successScreen,
  },
  methods: {
    createPromotion() {
      this.step = 1;
      this.$emit("createPromotion");
      window.location.reload();
    },

    onChange() {
      this.files = [...this.$refs.file.files];
      //set image reader
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
    },
    changeImages() {
      this.files = [...this.$refs.changefile[0].files];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    changeImage() {
      this.$refs.changefile[0].click();
    },
    goToPreview() {
      if (this.files.length > 0) {
        this.step++;
      } else {
        this.alert = true;
        this.error = "You need to upload an image";
      }
    },

    uploadPromotion() {
      this.loading = true;
      axios({
        method: "POST",
        url: UPLOADURL + "profiling/usermgt/uploadSlide",
        headers: {
          channel: "Web",
          Accept: "*/*",
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": this.files[0].type,
        },
        data: JSON.stringify(this.files[0]),
      })
        .then(() => {
          this.loading = false;
          this.step++;
        })
        .catch((error) => {
          handleError(error.message)
          this.loading = false;
        });
    },
  },
};
</script>



<style scoped>
@media only screen and (max-width: 600px) {
  .promotionsContainer {
    padding: 20px !important;
  }

  .promotionsHeading {
    display: flex;
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 776px) {
}

@media only screen and (min-width: 894px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 996px) {
  .promotionsContainer {
    padding: 0px 20px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

.primarybtn {
  color: white !important;
  background: #0582d2 !important;
  padding: 24px 16px !important;
  border-radius: 8px;
}

.dragAndDropContainer {
  border: 1px dashed #0582d2;
  background: #f5fbff;
  border-radius: 10px;
  padding: 20px 0px;
  position: relative;
}

.dragAndDropContainer .preview-card {
  padding: 0px 10px;
}
.hidden-input {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}
.imageContainer {
  padding: 0px;
}
.outlinedbtn {
  padding: 24px 16px !important;
}
</style>