import { ADMINROLES } from "@/constants/constants";

export const isAuthenticated = () => {
  if (sessionStorage.getItem("adminToken") == null) {
    return false;
  }
  const token = sessionStorage.getItem("adminToken");
  const tokenParts = token.split(".");
  if (tokenParts.length !== 3) {
    return false;
  }
  if (!token.startsWith("eyJ")) {
    return false;
  }
  return true;
};

export const isAllowed = (permission) => {
  return ADMINROLES && ADMINROLES.userRolesNames.includes(permission);
};
