<template>
  <div class="py-8 tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th class="font-weight-medium">Transaction ID</th>
          <th class="font-weight-medium">Buyer</th>
          <th class="font-weight-medium">Seller</th>
          <th class="font-weight-medium">Payment Type</th>
          <th class="font-weight-medium">Amount</th>
          <th class="font-weight-medium">Date Created</th>
          <th class="font-weight-medium">Category</th>
        </tr>
      </thead>
      <tbody v-for="(t, i) in escrow" :key="i">
        <tr>
          <td>{{ t.transactionId }}</td>
          <td>{{ t.walletNo }}</td>
          <td>{{ t.receiverPhoneNumber }}</td>
          <td>{{ t.paymentType }}</td>
          <td>NGN {{ t.transactionAmount.toLocaleString("en") }}</td>
          <td>{{ t.createdDate | dateFormatter}}</td>
          <td>{{ t.category }}</td>
        </tr>
      </tbody>
    </table>

    <div class="text-center">
      <div>
        <Pagination
          :page="page"
          :pagination="total"
          :number="1"
          @prevPagination="prevPagination"
          @nextPagination="nextPagination"
          @setPagination="setPagination"
        />
      </div>
    </div>
  </div>
</template>
    
    
    
    
    
<script>
import { dateFormatter } from '@/utils/formatter';
import Pagination from "../Misc/Pagination.vue";
import Vue from "vue"
Vue.filter("dateFormatter",dateFormatter)

export default {
  components: { Pagination },
  props: {
    escrow: {
      type: Array,
    },
    transactions: {
      type: Array,
    },
    total: {
      type: Number,
    },
    page: {
      type: Number,
    },
  },
  computed: {},

  methods: {
    prevPagination(e) {
      window.scrollTo(0, 0);
      if (e > 1) {
        this.$store.dispatch("transaction/setTransactionPagination", e - 1);
      }
    },

    setPagination(e) {
      window.scrollTo(0, 0);
      this.$store.dispatch("transaction/setTransactionPagination", e);
    },

    nextPagination(e) {
      window.scrollTo(0, 0);
      if (e < Math.floor(this.total / 10)) {
        this.$store.dispatch("transaction/setTransactionPagination", e + 1);
      }
    },
  },
};
</script>
    
    <style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px 10px !important;
  background: #f9fafb;
  text-align: start;
}

td {
  font-size: 14px;
  padding: 10px 10px !important;
  text-align: start;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}
.v-date-picker-title {
  color: pink !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  background: red;
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
  background: red;
}

.checkBoxPosition {
  margin: 0px 0px 0px 11px;
}
</style>
    