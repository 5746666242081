import { render, staticRenderFns } from "./TransactionsTable.vue?vue&type=template&id=61955c7b&scoped=true&"
import script from "./TransactionsTable.vue?vue&type=script&lang=js&"
export * from "./TransactionsTable.vue?vue&type=script&lang=js&"
import style0 from "./TransactionsTable.vue?vue&type=style&index=0&id=61955c7b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61955c7b",
  null
  
)

export default component.exports