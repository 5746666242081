import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import {
  HEADERS,
  GETURL,
  ALERTCONFIG,
  TOASTCONFIG,
} from "./constants/constants";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(VueTelInput, {
  defaultCountry: 234,
  dropdownOptions: {
    showSearchBox: true,
  },
  inputOptions: {
    placeholder: "",
    type: "tel",
  },
});

Vue.use(Toast, TOASTCONFIG);

const timeoutInMS = 600000; // 3 minutes -> 3 * 60 * 1000
let timeoutId;

function handleInactive() {
  // Here you want to logout a user and/or ping your token

  axios({
    method: "GET",
    url: GETURL + "session-manager/session/logout",
    headers: HEADERS,
  }).then(() => {
    sessionStorage.removeItem("adminToken");
    window.location.href = "/login";
  });
  alert("session expired. Kindly logout and log back in to continue");
}

function startTimer() {
  // setTimeout returns an ID (can be used to start or clear a timer)
  timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() {
  clearTimeout(timeoutId);
  startTimer();
}

function setupTimers() {
  document.addEventListener("keypress", resetTimer, false);
  document.addEventListener("mousemove", resetTimer, false);
  document.addEventListener("mousedown", resetTimer, false);
  document.addEventListener("touchmove", resetTimer, false);

  startTimer();
}

if (sessionStorage.getItem("adminToken") != null) {
  setupTimers();
}

Vue.config.productionTip = false;
Vue.use(VueSweetalert2, ALERTCONFIG);

new Vue({
  vuetify,
  router,
  store,
  VueSweetalert2,
  render: (h) => h(App),
}).$mount("#app");
