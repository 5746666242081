<template>
  <DashboardLayout>
    <ReconciliationBody
      :totalCommissionEarned="totalCommissionEarned"
      :totalWalletBalance="totalWalletBalance"
      :processId="processId"
      :transactions="transactions"
      :loading="loading"
  /></DashboardLayout>
</template>


<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import ReconciliationBody from "@/components/Reconciliation/ReconciliationBody.vue";
import { mapState } from "vuex";
export default {
  components: {
    DashboardLayout,
    ReconciliationBody,
  },
  computed: {
    ...mapState("reconciliation", {
      totalCommissionEarned: "totalCommissionEarned",
      totalWalletBalance: "totalWalletBalance",
      processId: "processId",
      transactions: "transactions",
      loading: "loading",
    }),
  },
  created() {
    this.$store.dispatch("reconciliation/fetchReconciliations");
    this.$store.dispatch("reconciliation/getReconciliationVariance");
  },
};
</script>


<style scoped>
</style>