<template>
  <div id="app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>


<script>
export default {
  metaInfo() {
    return {
      title: `Admin Portal`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
#app,
p,
v-list-item-title {
  font-family: "Work Sans";
}

.brandcolor {
  color: #0582d2;
}

html {
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: "PP Radio";
  src: url("./fonts/PPRadioGrotesk-Regular.otf");
}

h1,
h2,
h3,
h4,
caption,
p,
.caption,
.subtitle-1 {
  font-family: "PP Radio";
}

.v-btn {
  text-transform: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label {
  font-weight: 500;
  font-size: 14px;
}

a:hover,
li:hover,
router-link:hover {
  text-transform: none !important;
  text-decoration: none;
}

.v-text-field {
  height: 56px !important;
}

.pointer {
  cursor: pointer;
}
</style>
