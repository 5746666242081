<template>
  <v-main>
    <div class="transactionsContainer">
      <v-breadcrumbs divider=">"></v-breadcrumbs>
    </div>
    <div class="transactionsContainer">
      <h1>Admin</h1>
    </div>

    <v-card flat class="transactionCardContainer">
      <div>
        <v-tabs v-model="tab" background-color="transparent">
          <v-tabs-slider color="#0582D2"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item" class="text-capitalize">
            {{ item }}
          </v-tab>
        </v-tabs>
        <hr class="line" />

        <div class="d-flex">
          <div class="mr-4 mt-7"></div>
        </div>
      </div>

      <v-tabs-items v-model="tab">
        <!-- Profile tab -->
        <v-tab-item>
          <v-card flat class="mr-6">
            <RolesFilter :roles="roles" :groups="groups"/>
            <RolesTableLoading v-if="loading" />
            <RolesTable
              :loading="loading"
              v-if="!loading"
              :admin="admin"
              :groups="groups"
              :roles="roles"
            />
          </v-card>
        </v-tab-item>

        <!-- Verification tab -->
        <v-tab-item>
          <v-card color="#F8F8F9" flat>
            <div>
              <PermissionsUser
                :roles="roles"
                :groups="groups"
                :rolespermission="rolespermission"
                :grouptypes="grouptypes"
                :permissions="permissions"
              />
            </div>
          </v-card>
        </v-tab-item>

        <!-- Payout tab -->
      </v-tabs-items>
    </v-card>
  </v-main>
</template>
    
    
    
    <script>
import PermissionsUser from "./PermissionsUser.vue";
import RolesTable from "./RolesTable.vue";
import RolesFilter from "./RolesFilter.vue";
import RolesTableLoading from "./RolesTableLoading.vue";
export default {
  data() {
    return {
      tab: null,
      items: ["Members", "Roles & Permissions"],
    };
  },

  computed: {},

  props: {
    admin: {
      type: Array,
    },
    roles: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    groups: {
      type: Array,
    },
    rolespermission: {
      type: [Object, Array],
    },
    grouptypes: {
      type: Array,
    },
    permissions:{
      type: Array
    }
  },

  created() {},

  components: { PermissionsUser, RolesTable, RolesFilter, RolesTableLoading },

  watch: {},

  methods: {},
};
</script>
    
    <style scoped>
.v-breadcrumbs {
  font-weight: 500 !important;
  color: black !important;
  padding: 0px;
}

.v-breadcrumbs >>> a {
  color: #383b43 !important;
  font-size: 14px !important;
}

.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
.transactionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}

.transactionCardContainer {
  margin: 0px 0px 44px 0px;
}
.tabContainer {
  padding: 20px 0px 0px 0px;
  border-bottom: 2px solid;
}
.button {
  padding: 40px 0px;
  border-radius: 10px;
}

.line {
  margin-top: -1.3px;
  height: 1px;
  /* border: 0.001em solid #acb0b9; */
  border: none;
  background: #efefef;
}
</style>
    
    
    