<template>
  <v-card class="createPermissionCard" max-width="580px">
    <ModalHeader :title="'Create Group'" @closeModal="closeModal" />
    <label>Group Name</label>
    <v-text-field outlined dense v-model="groupname" />

    <label>Group Permissions</label>
    <v-chip-group v-if="selectedpermissions.length != 0">
      <v-chip v-for="(permission, i) in selectedpermissions" :key="i">{{
        permission | getPermission(permissions)
      }}</v-chip>
    </v-chip-group>
    <div
      @click="showAllPermissions"
      class="text-center brandcolor font-weight-medium pointer"
    >
      <v-icon :color="BRANDCOLOR">mdi-plus</v-icon> Add Permission
    </div>

    <label>Group Type</label>
    <v-select v-model="grouptype" dense :items="grouptypes" outlined />

    <v-btn
      @click="createGroup"
      block
      large
      :color="BRANDCOLOR"
      class="rounded-lg white--text my-5"
      :loading="loading"
      >Create Group</v-btn
    >

    <v-dialog persistent max-width="580px" v-model="showpermission">
      <PermissionsList
        :permissions="permissions"
        @handleSelect="handleSelect"
        @closeModal="showpermission = false"
      />
    </v-dialog>
  </v-card>
</template>


<script>
import Vue from "vue";
import { BRANDCOLOR } from "@/constants/constants";
import PermissionsList from "./PermissionsList.vue";
import ModalHeader from "@/elements/ModalHeader.vue";
import { getPermission } from "@/utils/formatter";
import { handleError, handleResponse } from "@/utils/handleErrors";
import api from "@/services/api";

Vue.filter("getPermission", getPermission);

export default {
  data() {
    return {
      BRANDCOLOR,
      selectedpermissions: [],
      showpermission: false,
      grouptype: "",
      groupname: "",
      loading: false,
    };
  },
  components: {
    PermissionsList,
    ModalHeader,
  },
  props: {
    grouptypes: {
      type: Array,
    },
    permissions: {
      type: Array,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    showAllPermissions() {
      this.showpermission = !this.showpermission;
    },

    handleSelect(e) {
      this.selectedpermissions = e;
    },

    async createGroup() {
      try {
        this.loading = true;
        const response = await api.postRequest(
          "profiling/usermgt/create-new-group",
          {
            userGroupName: this.groupname,
            userGroupRoles: this.selectedpermissions.join(),
            userGroupType: this.grouptype,
          }
        );
        handleResponse(response, { reload: true });
        this.loading = false;
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },
  },
};
</script>