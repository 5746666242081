import { MONTH } from "@/constants/constants";
import moment from "moment";
import { unslugify } from "unslugify";

export function formatTitle(title) {
  if (title.length > 15) {
    return title.slice(0, 15) + "...";
  }
  return title;
}

export function dateFormatter(date) {
  return moment(date).format("lll");
}

export function filterDateFormat(date) {
  return moment(date).format().split("T")[0];
}

export function formatDate(date) {
  let dateArr = date.split("-");
  return dateArr[2] + "-" + MONTH[dateArr[1]] + "-" + dateArr[0];
}

export function formatAmount(amount) {
  return "NGN " + parseFloat(amount).toLocaleString("en");
}

export function formatNumber(amount) {
  return amount.slice(0, 11);
}
export function formatRemark(remark) {
  if (remark.split("/")[0].length > 30) {
    return remark.split("/")[0].slice(0, 30) + "...";
  } else {
    return remark.split("/")[0];
  }
}

export const getRole = (roleId, groups) => {
  if (groups) {
    return groups.filter((role) => role.userGroupId === roleId)[0]
      .userGroupName;
  } else {
    return roleId;
  }
};

export const getPermission = (roleId, permissions) => {
  if (permissions) {
    return unslugifyString(
      permissions.filter((role) => role.roleId === roleId)[0].userRole
    );
  } else {
    return roleId;
  }
};

export const unslugifyString = (string) => {
  return unslugify(string);
};
