<template>
  <tbody>
    <tr v-for="(t, i) in 5" :key="i" class="text-center">
      <td v-for="(t, i) in columns" :key="i">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </td>
      <td>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark v-bind="attrs" v-on="on" text>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link> View </v-list-item>
            <v-list-item link> Delete </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
    props:{
        columns:{
            type:Number
        }
    }
};
</script>