<template>
  <v-list-item v-if="isAllowed" link :to="to">
    <v-list-item-icon style="margin-right: 20px">
      <v-img
        :src="require(`../../assets/Icons/${active ? iconActive : icon}.svg`)"
      />
    </v-list-item-icon>

    <v-list-item-content :to="to">
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
    },
    to: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconActive: {
      type: String,
    },
    active: {
      type: Boolean,
    },
    isAllowed: {
      type: Boolean,
    },
  },
};
</script>