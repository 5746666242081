<template>
  <v-card flat class="text-center cardSize pb-6">
    <div v-if="closebtn" class="px-7 py-3">
      <v-icon @click="closeSuccess" class="float-right">mdi-close</v-icon>
    </div>
    <div class="pa-5">
      <v-img
        width="200px"
        class="mx-auto"
        src="../../assets/success.gif"
        alt="success"
      />
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>

      <div class="my-5 text-center">
        <v-btn
          @click="closeSuccess"
          large
          class="white--text"
          color="#0582D2"
          width="80%"
          >{{ buttonText }}</v-btn
        >
      </div>
    </div>
  </v-card>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    closebtn: {
      type: Boolean,
    },
  },
  methods: {
    closeSuccess() {
      this.$emit("closeSuccess");
    },
  },
};
</script>>