<template>
  <div class="flex-card">
    <div>{{ title }}</div>

    <div class="font-weight-medium">{{ value }}</div>
  </div>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style scoped>
.flex-card {
  background: #f3f4f6;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}
</style>