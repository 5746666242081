<template>
    <div class="d-flex justify-space-between header">
      <v-icon v-if="back" @click="closeBtn">mdi-arrow-left</v-icon>
      <h2 class="title">{{ title }}</h2>
      <v-btn @click="closeBtn" fab small depressed color="#F8F8F9"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
  </template>
  
  
  <script>
  export default {
      name:"HeaderComponent",
      props:{
          title:{
              type:String
          },
          back:{
            type:Boolean
          }
      },
      methods:{
          closeBtn(){
              this.$emit("closeBtn")
          }
      }
  }
  </script>
  
  