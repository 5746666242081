<template>
  <DashboardLayout>
    <CrowdFundingBody
      :crowdfunding="crowdfunding.allcrowdfunding"
      :loading="crowdfunding.loading"
      :user="admin.user"
    />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import CrowdFundingBody from "@/components/CrowdFunding/CrowdFundingBody.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    CrowdFundingBody,
  },
  computed: {
    ...mapState(["crowdfunding"]),
    ...mapState(["admin"]),
  },

  created() {
    this.$store.dispatch("crowdfunding/fetchAllCrowdfunding");
    this.$store.dispatch("admin/fetchAdminUser");
  },
};
</script>
  
  
  
  
  
  
  <style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>