import api from "@/services/api";
import { handleError } from "@/utils/handleErrors";

export default {
  namespaced: true,
  state: {
    loading: false,
    isUserGraph: false,
    isTransactionGraph: false,
    graphUsers: [],
    graphTransactions: [],
  },

  mutations: {
    SET_IS_USER_GRAPH(state, value) {
      state.isUserGraph = value;
    },

    SET_IS_TRANSACTION_GRAPH(state, value) {
      state.isTransactionGraph = value;
    },

    SET_GRAPH_USERS(state, value) {
      state.graphUsers = value;
    },
    SET_GRAPH_TRANSACTIONS(state, value) {
      state.graphTransactions = value;
    },
  },

  actions: {
    fetchGraphUsers: async ({ commit }) => {
      try {
        commit("SET_IS_USER_GRAPH", true);
        const response = await api.getRequest("profiling/usermgt/userCount");
        commit("SET_GRAPH_USERS", response.data.data.userStat);
        commit("SET_IS_USER_GRAPH", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_IS_USER_GRAPH", false);
      }
    },

    fetchGraphTransactions: async ({ commit }) => {
      try {
        commit("SET_IS_TRANSACTION_GRAPH", true);
        const response = await api.getRequest(
          "profiling/usermgt/transactionsCount"
        );
        commit("SET_GRAPH_TRANSACTIONS", response.data.data.txStat);
        commit("SET_IS_TRANSACTION_GRAPH", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_IS_TRANSACTION_GRAPH", false);
      }
    },
  },
};
