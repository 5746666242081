<template>
  <v-main>
    <div class="reconcileContainer">
      <div class="reconcile__title__flex">
        <h1>{{ title }}</h1>

        <div class="reversal-buttons">
          <v-btn
            @click="createlog = !createlog"
            depressed
            color="#F0F5FD"
            class="blue--text"
            ><v-icon>mdi-plus</v-icon> Create Log
          </v-btn>
          <v-btn @click="viewLog" depressed color="#F0F5FD" class="blue--text"
            ><v-icon small class="mr-2">mdi-eye</v-icon> View Log
          </v-btn>
          <v-divider vertical inset style="width: 10px; background: #0582d2" />
          <v-btn
            @click="handleReversal"
            depressed
            outlined
            class="mr-3 blue--text"
            to="/external-reversal"
          >
            External Reverse</v-btn
          >

          <v-btn
            @click="handleReversal"
            depressed
            :color="BRANDCOLOR"
            class="white--text"
          >
            Reverse</v-btn
          >
        </div>
      </div>
      <div class="stat-grid">
        <StatCard
          title="Total Reversal"
          :loading="loading"
          :value="grandTotalReversal === '[null]' ? '0' : grandTotalReversal"
          :type="'currency'"
        />
        <StatCard
          title="Total Monthly Reversal"
          :loading="loading"
          :value="
            totalCurrentMonthReversal === '[null]' ? '0' : grandTotalReversal
          "
          :type="'currency'"
        />
      </div>
      <TableComponent :checkbox="true" :columns="columns">
        <tbody v-for="(transaction, i) in reversals" :key="i">
          <tr class="text-center">
            <td><v-checkbox /></td>
            <td>{{ transaction.transactionId }}</td>
            <td>{{ transaction.paymentType }}</td>
            <td>{{ transaction.senderName }}</td>
            <td>{{ transaction.ammount }}</td>
            <td>{{ transaction.createdDate | dateFormatter }}</td>
            <!-- <td>
              <div v-if="project.approval !== '1'">
                <v-btn @click="showCampaign(project)" color="#068B03" text
                  ><v-icon>mdi-check</v-icon>Approve</v-btn
                >
                <v-btn @click="showCampaign(project)" color="#DC2626" text
                  ><v-icon>mdi-close</v-icon>Decline</v-btn
                >
              </div>
              <v-menu v-if="project.approval === '1'" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showCampaign(project)">
                    View Campaign
                  </v-list-item>
                  <v-list-item
                    v-if="
                      tab === 'withdrawal' && project.cashOutRequestApproval !== '2'
                    "
                  >
                    Approve Withdrawal
                  </v-list-item>
                </v-list>
              </v-menu>
            </td> -->
          </tr>
        </tbody>
      </TableComponent>
    </div>

    <v-dialog persistent max-width="500px" v-model="reversal">
      <ReversalModal @closeModal="closeModal" />
    </v-dialog>

    <v-dialog max-width="500px" v-model="createlog">
      <CreateLogForm @closeModal="createlog = !createlog" />
    </v-dialog>
  </v-main>
</template>
        
        
        
<script>
import { BRANDCOLOR } from "@/constants/constants";
import StatCard from "../Dashboard/Cards/StatCard.vue";
import TableComponent from "../Misc/TableComponent.vue";
import ReversalModal from "./Modals/ReversalModal.vue";
import CreateLogForm from "./Modals/createLogForm.vue";
import { dateFormatter } from "@/utils/formatter";
import Vue from "vue";

Vue.filter("dateFormatter", dateFormatter);

export default {
  props: {
    reversals: {
      type: Array,
    },
    loggedReversals: {
      type: Array,
    },
    grandTotalReversal: {
      type: [Number, String],
    },
    totalCurrentMonthReversal: {
      type: [Number, String],
    },
  },
  components: {
    StatCard,
    TableComponent,
    ReversalModal,
    CreateLogForm,
  },
  data() {
    return {
      BRANDCOLOR,
      columns: [
        "Transaction Id",
        "Payment Type",
        "Customer Name",
        "Amount",
        "Date",
      ],
      loading: false,
      reversal: false,
      createlog: false,
      step: 1,
      title: "Reversal",
    };
  },
  methods: {
    handleReversal() {
      this.reversal = !this.reversal;
    },
    closeModal() {
      this.reversal = !this.reversal;
    },

    viewLog() {
      this.$router.push("/logged-issues");
    },
  },
};
</script>
        
<style scoped>
.reversal-buttons > * {
  margin: 10px 10px 0px 0px;
}
.reconcile__title__flex {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 20px 0px;
}
.stat-grid {
  display: grid;
  grid-template-columns: auto auto;
}

@media only screen and (min-width: 600px) {
  .reversal-buttons {
    display: flex;
  }
}
</style>
        
        
        