<template>
  <DashboardLayout>
    <PushNotificationBody
      :notifications="notifications.pushnotifications"
      :loading="notifications.loading"
    />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import PushNotificationBody from "@/components/PushNotification/PushNotificationBody.vue";
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    PushNotificationBody,
  },
  computed: {
    ...mapState(["notifications"]),
  },
  created() {
    this.$store.dispatch("notifications/fetchPushNotifications");
  },
};
</script>
  
  
  
  
  
  
  <style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>