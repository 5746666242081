<template>
  <div style="background: white; padding: 0px 0px 0px 90px">
    <v-app-bar color="white" class="appBar" flat>
      <v-text-field
        style="max-width: 590px"
        class="searchField mt-5"
        label="Search for anything"
        single-line
        prepend-inner-icon="mdi-magnify"
      />

      <v-spacer></v-spacer>

      <div class="mr-4">
        <v-badge
          right
          color="red"
          :content="
            notifications.notifications && notifications.notifications.length
          "
          overlap
          :value="notifications.notifications ? true : false"
        >
          <v-icon @click="viewNotifications()"> mdi-bell-outline </v-icon>
        </v-badge>
      </div>

      <div>
        <v-list-item class="px-2">
          <v-list-item-avatar color="#F8F8F9" class="d-flex justify-center">
            <span class="font-weight-medium">{{ name.slice(0, 2) }}</span>
          </v-list-item-avatar>
          <v-list-item-title class="font-weight-medium"> Hi, {{
            name
          }} 👋🏾</v-list-item-title>
        </v-list-item>
      </div>
    </v-app-bar>
  </div>
</template>




<script>
import { mapState } from "vuex";
import { ADMINUSER } from "@/constants/constants";
export default {
  computed: {
    ...mapState(["notifications"]),
  },

  data: () => ({
    name: ADMINUSER.firstName,
  }),
  created() {
    this.$store.dispatch("notifications/fetchPendingKyc");
  },
  methods: {
    viewNotifications() {
      this.$router.push("/notifications");
    },
  },
};
</script>


<style lang="css" scoped>
.appBar {
  padding: 30px 70px 80px 70px !important;
}
v-text-field {
  outline: none !important;
}

.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
</style>