<template>
  <div>
    <div class="transactionsContainer">
      <h1>Statement</h1>
      <div class="timepicker-grid">
        <div>
          <label>Start Date</label>
          <v-text-field v-model="startDate" outlined dense type="date" />
        </div>

        <div>
          <label>End Date</label>
          <v-text-field v-model="endDate" outlined dense type="date" />
        </div>
      </div>

      <v-btn
        :disabled="startDate === '' || endDate === ''"
        @click="generateStatement"
        class="white--text rounded-lg"
        x-large
        :color="BRANDCOLOR"
        :loading="loading"
        depressed
        >Generate Statement</v-btn
      >

      <div v-if="admin.statementlist.length">
        <div class="d-flex justify-end mr-2">
          <v-btn
            depressed
            @click="exportData"
            :color="BRANDCOLOR"
            class="white--text"
            ><v-icon>mdi-cloud-download</v-icon> Export as CSV</v-btn
          >
        </div>
        <StatementTable :statementlist="admin.statementlist" />
      </div>
    </div>
  </div>
</template>


<script>
import { BRANDCOLOR } from "@/constants/constants";
import { formatDate } from "@/utils/formatter";
import { mapState } from "vuex";
import StatementTable from "./StatementTable.vue";
import { excelParser } from "../../utils/excelparser";
import { handleError } from "@/utils/handleErrors";
export default {
  name: "StatementBody",
  data() {
    return {
      BRANDCOLOR,
      loading: false,
      startDate: "",
      endDate: "",
    };
  },
  components: {
    StatementTable,
  },
  computed: {
    ...mapState(["admin"]),
  },
  methods: {
    async generateStatement() {
      if (new Date(this.endDate).getTime() > Date.now()) {
        this.$swal({
          icon: "warning",
          title: "Invalid end date",
          text: "Please enter an end date that is not in the future",
        });
        return;
      }

      if (
        new Date(this.startDate).getTime() > new Date(this.endDate).getTime()
      ) {
        this.$swal({
          icon: "warning",
          title: "Invalid start date",
          text: "Please enter a start date that is not later than the end date",
        });
        return;
      }

      this.loading = true;
      try {
        await this.$store.dispatch("admin/fetchBankStatement", {
          startDate: formatDate(this.startDate),
          endDate: formatDate(this.endDate),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        handleError(error.message);
      }
    },

    exportData() {
      excelParser().exportDataFromJSON(
        this.admin.statementlist,
        "Kuleanpay - PROVIDUS Bank Statement",
        "csv"
      );
    },
  },
};
</script>


<style scoped>
.timepicker-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin: 0px 20px 0px 0px;
}
</style>