<template>
  <v-card class="createPermissionCard" max-width="500px">
    <ModalHeader @closeModal="closeModal" :title="`${role}'s Permissions`" />
    <div>
      <v-checkbox
        v-for="(permission, i) in permissions"
        :key="i"
        style="height: 20px"
        readonly
        v-model="checked"
        :label="permission | unslugify"
      ></v-checkbox>
    </div>
  </v-card>
</template>



<script>
import Vue from "vue";
import { unslugifyString } from "@/utils/formatter";
import ModalHeader from "@/elements/ModalHeader.vue";
Vue.filter("unslugify", unslugifyString);
export default {
  data: () => ({
    checked: true,
  }),
  props: {
    permissions: {
      type: Array,
    },
    role: {
      type: String,
    },
  },
  components: {
    ModalHeader,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>