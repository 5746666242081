<template>
  <v-main>
    <div>
      <h1>Change Password</h1>

      <v-card class="change-password-card">
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-form>
              <v-text-field outlined v-model="email" />

              <v-btn
                x-large
                depressed
                block
                :color="BRANDCOLOR"
                class="white--text rounded-md my-5"
                @click="handleResetPassword"
                :loading="loading"
              >
                Reset Password
              </v-btn>
            </v-form>
          </v-window-item>

          <v-window-item :value="2">
            <h3>Enter the OTP sent to your email</h3>
            <v-otp-input length="6" v-model="otp" />

            <v-btn
              :color="BRANDCOLOR"
              @click="step++"
              class="white--text my-5 rounded-md"
              x-large
              block
            >
              Continue</v-btn
            >
          </v-window-item>

          <v-window-item :value="3">
            <div>
              <label> Enter your old password </label>
              <v-text-field outlined dense v-model="oldPassword" />
            </div>

            <div class="my-3">
              <label> Enter your new password </label>
              <v-text-field
                outlined
                dense
                v-model="newPassword"
                @keyup="passwordValidate()"
              />
              <CheckPassword :password="newPassword" ref="checkpassword" />
            </div>

            <v-btn
              :color="BRANDCOLOR"
              @click="handleChangePassword"
              class="white--text my-5 rounded-md"
              x-large
              block
              :loading="loading"
            >
              Change Password</v-btn
            >
          </v-window-item>
        </v-window>
      </v-card>
    </div>
  </v-main>
</template>


<script>
import { ADMINUSER, BRANDCOLOR } from "@/constants/constants";
import { handleError, handleResponse } from "@/utils/handleErrors";
import CheckPassword from "./CheckPassword.vue";
import api from "@/services/api";

export default {
  data() {
    return {
      email: ADMINUSER.email,
      loading: false,
      BRANDCOLOR,
      step: 1,
      requestId: "",
      otp: "",
      newPassword: "",
      oldPassword: "",
    };
  },

  components: {
    CheckPassword,
  },

  methods: {
    async handleResetPassword() {
      this.loading = true;
      try {
        const response = await api.adminRequest(
          "profiling/usermgt/initiate-admin-pwd-change",
          {
            emailAddress: this.email,
          }
        );
        this.loading = false;
        this.requestId = response.data.data.requestId;
        handleResponse(response, { reload: false });
        this.step = 2;
      } catch (error) {
        handleError(error);
      }
    },

    passwordValidate() {
      this.$refs.checkpassword.passwordValidate();
    },

    async handleChangePassword() {
      this.loading = true;
      try {
        const response = await api.adminRequest(
          "profiling/usermgt/handle-admin-pwd-change",
          {
            newPassword: this.newPassword,
            oldPassword: this.oldPassword,
            requestId: this.requestId,
            otp: this.otp,
          }
        );
        this.loading = false;
        handleResponse(response, { reload: true });
      } catch (error) {
        handleError(error);
      }
    },
  },
};
</script>


<style scoped>
.change-password-card {
  max-width: 700px;
  padding: 30px;
  margin: 30px 0px;
  border-radius: 10px;
}
</style>