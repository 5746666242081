<template>
  <div>
    <v-navigation-drawer
      fixed
      absolute
      permanent
      left
      style="padding: 30px 30px 0px 30px"
      class="hidden-md-and-down"
    >
      <v-list-item>
        <router-link to="/overview"
          ><img src="../../assets/blueLogo.svg" width="100px" height="100px" />
        </router-link>
      </v-list-item>

      <v-list>
        <v-list-item-group color="#0582D2">
          <SidebarItem
            v-for="(item, i) in menu"
            :key="i"
            :to="item.to"
            :icon="item.icon"
            :iconActive="item.iconActive"
            :title="item.title"
            :active="pathname === item.to"
            :isAllowed="item.isAllowed"
          />

          <v-list-item link @click="logOut()">
            <v-list-item-icon style="margin-right: 20px">
              <v-img src="../../assets/Icons/logout.svg" />
            </v-list-item-icon>

            <v-list-item-content to="/overview">
              <v-list-item-title style="color: #ef4444"
                >Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <div class="hidden-lg-and-up">
      <v-app-bar flat color="white">
        <v-app-bar-nav-icon
          color="#0582D2"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>

        <!-- notifications icon -->

        <div class="mr-2">
          <!-- <v-badge right color="red" content="10" overlap>
            <v-icon> mdi-bell-outline </v-icon>
          </v-badge> -->
        </div>

        <!-- notifications icon -->

        <!-- Avatar -->
        <v-list-item-avatar color="#F8F8F9" class="d-flex justify-center">
          <span class="font-weight-medium">{{
            user.firstName.slice(0, 2)
          }}</span>
        </v-list-item-avatar>

        <!-- Avatar -->
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        height="100%"
        absolute
        temporary
        left
        style="padding: 30px"
      >
        <v-list-item three-line>
          <router-link to="/overview"
            ><img
              src="../../assets/blueLogo.svg"
              width="100px"
              height="100px"
            />
          </router-link>
        </v-list-item>

        <v-list>
          <v-list-item-group color="#0582D2">
            <SidebarItem
              v-for="(item, i) in menu"
              :key="i"
              :to="item.to"
              :icon="item.icon"
              :iconActive="item.iconActive"
              :title="item.title"
              :active="pathname === item.to"
              :isAllowed="item.isAllowed"
            />

            <v-list-item link @click="logOut()">
              <v-list-item-icon style="margin-right: 20px">
                <v-img src="../../assets/Icons/logout.svg" />
              </v-list-item-icon>

              <v-list-item-content to="/overview">
                <v-list-item-title style="color: #ef4444"
                  >Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { GETURL, HEADERS, ADMINUSER } from "@/constants/constants";
import SidebarItem from "../Misc/SidebarItem.vue";
import { isAllowed } from "@/utils/auth";
export default {
  name: "NavMenu",
  components: { SidebarItem },
  data: () => ({
    user: ADMINUSER,
    drawer: false,
    group: null,
    menu: [
      {
        to: "/overview",
        title: "Overview",
        icon: "overview",
        iconActive: "overview-active",
        isAllowed: true,
      },
      {
        to: "/users",
        title: "Users",
        icon: "users",
        iconActive: "users-active",
        isAllowed: true
      },
      {
        to: "/transactions",
        title: "Transactions",
        icon: "transactions",
        iconActive: "transactions-active",
        isAllowed: isAllowed("wallet-wallet-transfer"),
      },
      {
        to: "/account-limit",
        title: "Account Limit",
        icon: "user",
        iconActive: "user-active",
        isAllowed: isAllowed("wallet-wallet-transfer"),
      },
      {
        to: "/payments",
        title: "Payments",
        icon: "wallet",
        iconActive: "wallet-active",
        isAllowed: isAllowed("fund-balance")
      },
      {
        to: "/finance",
        title: "Finance",
        icon: "finance",
        iconActive: "finance-active",
        isAllowed: isAllowed("final-payout-approval")
      },
      {
        to: "/reconciliation",
        title: "Reconciliation",
        icon: "reconciliation",
        iconActive: "reconciliation-active",
        isAllowed: isAllowed("access-reconciliation"),
      },
      {
        to: "/reversal",
        title: "Reversal",
        icon: "reverse",
        iconActive: "reverse-active",
        isAllowed: isAllowed("access-reversals"),
      },
      {
        to: "/roles",
        title: "Admin",
        icon: "verifiedIcon",
        iconActive: "verifiedIcon-active",
        isAllowed: isAllowed("create-new-group"),
      },
      {
        to: "/push-notifications",
        title: "Push Notification",
        icon: "push-notification",
        iconActive: "push-notification-active",
        isAllowed: isAllowed("change-user-status")
      },
      {
        to: "/promotions",
        title: "Promotions",
        icon: "promotions",
        iconActive: "promotion-active",
        isAllowed: isAllowed("change-user-status")
      },

      {
        to: "/campaigns",
        title: "Campaigns",
        icon: "report",
        iconActive: "report-active",
        isAllowed: isAllowed("pull-all-crowd-funding-transactions"),
      },
      {
        to: "/statements",
        title: "Statements",
        icon: "report",
        iconActive: "report-active",
        isAllowed: isAllowed("get-providus-account-statements")
      },

      {
        to: "/change-password",
        title: "Change Password",
        icon: "report",
        iconActive: "report-active",
        isAllowed: true
      },
    ],
  }),

  computed: {
    pathname() {
      return location.pathname;
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
  props: {},
  methods: {
    logOut() {
      axios({
        method: "GET",
        url: GETURL + "/api/get?url=session-manager/session/logout",
        headers: HEADERS,
      }).then(() => {
        sessionStorage.removeItem("adminToken");
        sessionStorage.removeItem("userData");
        this.$router.push("/login");
      });
    },
  },
};
</script>


<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>