<template>
  <DashboardLayout>
    <ReversalBody
      :reversals="reversals"
      :loggedReversals="loggedReversals"
      :grandTotalReversal="grandTotalReversal"
      :totalCurrentMonthReversal="totalCurrentMonthReversal"
      :loading="loading"
    />
  </DashboardLayout>
</template>



<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import ReversalBody from "@/components/Reversal/ReversalBody.vue";
import { mapState } from "vuex";
export default {
  components: {
    DashboardLayout,
    ReversalBody,
  },

  computed: {
    ...mapState("reconciliation", {
      reversals: "reversals",
      loggedReversals: "loggedReversals",
      grandTotalReversal: "grandTotalReversal",
      totalCurrentMonthReversal: "totalCurrentMonthReversal",
      loading: "loading",
    }),
  },

  created() {
    this.$store.dispatch("reconciliation/fetchReversals");
    this.$store.dispatch("reconciliation/fetchLoggedReversals");
  },
};
</script>