<template>
  <DashboardLayout>
    <AccountLimitBody />
  </DashboardLayout>
</template>
  
  
  
  
<script>
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";
import AccountLimitBody from "@/components/AccountLimit/AccountLimitBody.vue";

export default {
  components: {
    DashboardLayout,
    AccountLimitBody,
  },
};
</script>
  
  
  

  
<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>