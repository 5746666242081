<template>
  <div class="py-8 tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th class="font-weight-medium">Full Name</th>
          <th class="font-weight-medium">Email Address</th>
          <th class="font-weight-medium">Phone Number</th>
          <th class="font-weight-medium">Date Joined</th>
          <th class="font-weight-medium">Account Level</th>
          <th class="font-weight-medium">Action</th>
        </tr>
      </thead>

      <tbody v-for="(t, i) in users" :key="i">
        <tr class="cursor-pointer text-center">
          <td>{{ t.firstName }} {{ t.lastName }}</td>
          <td>{{ t.emailAddress }}</td>
          <td>{{ t.uniqueIdentification }}</td>
          <td>{{ t.created.slice(0, 10) }}</td>
          <td>{{ t.tierCategory }}</td>
          <td>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link @click="viewUser(t)"> View </v-list-item>
                <v-list-item
                  v-if="t.enabled"
                  class="red--text"
                  @click="restrictUser(t)"
                >
                  Restrict Account
                </v-list-item>
                <v-list-item
                  v-if="!t.enabled"
                  class="blue--text"
                  @click="unRestrictUser(t)"
                >
                  Unrestrict Account
                </v-list-item>
                <!-- <v-list-item link> Delete </v-list-item> -->
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="text-center">
      <div>
        <Pagination
          :page="page"
          :pagination="userCount"
          :number="1"
          @prevPagination="prevPagination"
          @nextPagination="nextPagination"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <!-- modal for suspension -->
    <v-dialog width="580px" v-model="suspenduser">
      <SuspendUser :action="action" :user="user" @closeModal="closeModal" />
    </v-dialog>
  </div>
</template>
    
    
    
    
    
<script>
import SuspendUser from "./Dialogs/SuspendUser.vue";
import Pagination from "../Misc/Pagination.vue";
export default {
  components: {
    Pagination,
    SuspendUser,
  },
  data() {
    return {
      suspenduser: false,
      user: "",
      action: "",
    };
  },
  props: {
    users: {
      type: Array,
    },
    userCount: {
      type: Number,
    },
    page: {
      type: Number,
    },
    search: {
      type: String,
    },
    filter: {
      type: String,
    },
  },
  computed: {},
  methods: {
    viewUser(link) {
      sessionStorage.setItem("selectedUser", JSON.stringify(link));
      window.location.href =
        "/admin/user?phoneNumber=" + link.uniqueIdentification;
    },

    restrictUser(t) {
      //show the suspension modal
      this.user = t;
      this.suspenduser = true;
      this.action = "suspend";
    },

    unRestrictUser(t) {
      this.user = t;
      this.suspenduser = true;
      this.action = "unsuspend";
    },

    closeModal() {
      this.suspenduser = false;
    },

    prevPagination(e) {
      window.scrollTo(0, 0);
      if (e >= 1) {
        this.filter === "date"
          ? this.$store.dispatch("users/setUserDatePagination", e - 1)
          : this.$store.dispatch("users/setUserPagination", e - 1);
      }
    },

    setPagination(e) {
      window.scrollTo(0, 0);
      this.filter === "date"
        ? this.$store.dispatch("users/setUserDatePagination", e)
        : this.$store.dispatch("users/setUserPagination", e);
    },

    nextPagination(e) {
      window.scrollTo(0, 0);
      this.filter === "date"
        ? this.$store.dispatch("users/setUserDatePagination", e + 1)
        : this.$store.dispatch("users/setUserPagination", e + 1);
    },
  },
};
</script>
    
<style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px 10px !important;
  background: #f9fafb;
  text-align: start;
}

td {
  font-size: 14px;
  padding: 10px !important;
  text-align: start;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}
.v-date-picker-title {
  color: pink !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  background: red;
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
  background: red;
}

.checkBoxPosition {
  margin: 14px 0px 0px 11px;
}

.brandcolor {
  color: #0582d2;
}
</style>
    