<template>
  <v-main class="walletContainer">
    <div>
      <div>
        <h1>Notifications</h1>

        <v-card flat class="transactionCard">
          <div
            v-if="
              !isNotifications &&
              (userNotifications == undefined ||
                (userNotifications && userNotifications.length == 0))
            "
          >
            <EmptyNotification />
          </div>

          <NotificationItem :userNotifications="userNotifications" />

          <div v-if="isNotifications">
            <notifications-loading />
          </div>
        </v-card>
      </div>
    </div>
  </v-main>
</template>
      
       
      
<script>
import NotificationItem from "./NotificationItem.vue";
import NotificationsLoading from "./NotificationsLoading.vue";
import EmptyNotification from "./EmptyNotification.vue";

export default {
  components: {
    NotificationItem,
    NotificationsLoading,
    EmptyNotification,
  },
  props: {
    isNotifications: {
      type: Boolean,
    },
    userNotifications: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
      
<style scoped>
.walletBalance {
  padding: 0px;
  margin: 0px 0px 12px 0px;
  text-transform: uppercase;
}

.amount {
  font-size: 32px;
  font-weight: 400;
}

.walletContainer {
  display: flex;
  justify-content: center;
}

.cardWidth {
  margin: 40px 0px 0px 0px;
  width: 744px;
  text-align: center;
  color: white;
  padding: 95px 95px 50px 95px;
  border-radius: 8px;
}

.buttonFlex {
  margin: 40px 0px 0px 0px;
}
.roundedButton {
  color: white;
}

.transactionCard {
  background: white;
  border-radius: 8px;
  margin: 0px 0px 0px 0px;
  padding: 24px 40px;
}

.transactionTitle {
  padding: 0px;
  font-weight: 500;
}

span {
  padding: 0px;
  margin: 0;
}

.transactionDate {
  font-size: 13px;
  color: #acb0b9;
}

.withdrawal {
  color: #ef4444;
  font-weight: 500;
  font-size: 14px;
  background: #feeaf3 !important;
  padding: 8px;
}

.deposit {
  background: #dcfce7 !important;
  color: #2ec76b !important;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
}

.singleTransaction {
  border-bottom: 1px solid #f8f8f9;
}

.withdrawalcard {
  padding: 26px 40px;
}

.withdrawaltitle {
  padding: 0px 0px 10px 0px;
}

.line {
  height: 1px;
  border: none;
  background: #efefef;
}

.accountcard {
  margin: 0px 0px 32px 0px;
}

.bankName {
  padding: 0;
  margin: 0px 0px -10px 0px;
}
.bankDetails {
  font-size: 13px;
  color: #6b7280;
  padding: 0;
  margin: 0;
}

.v-btn {
  letter-spacing: normal;
}

@media only screen and (max-width: 800px) {
  .walletBalance {
    padding: 0px;
    margin: 0px 0px 12px 0px;
    text-transform: uppercase;
  }

  .amount {
    font-size: 32px;
    font-weight: 400;
  }

  .walletContainer {
    padding: 40px 20px !important;
    max-width: 900px;
    margin: auto;
  }

  .cardWidth {
    margin: 40px 0px 0px 0px;
    text-align: center;
    color: white;
    padding: 50px 15px 50px 15px !important;
    border-radius: 8px;
  }

  .buttonFlex {
    margin: 40px 0px 0px 0px;
  }
  .roundedButton {
    color: white;
  }

  .transactionCard {
    background: white;
    border-radius: 8px;
    margin: 51px 0px 0px 0px;
    padding: 24px 12px;
  }

  .transactionTitle {
    padding: 0px;
    font-weight: 500;
  }

  .transactionHistory {
    font-size: 19px;
  }

  span {
    padding: 0px;
    margin: 0;
  }

  .transactionDate {
    font-size: 13px;
    color: #acb0b9;
  }

  .withdrawal {
    color: #ef4444;
    font-weight: 500;
    font-size: 14px;
    background: #feeaf3 !important;
    padding: 8px;
    width: 110px;
    text-align: center;
  }

  .deposit {
    background: #dcfce7 !important;
    color: #2ec76b !important;
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  .singleTransaction {
    border-bottom: 1px solid #f8f8f9;
  }

  .transactionFlex {
    display: flex;
    justify-content: space-between;
  }

  .roundedButton {
    margin-right: 6px;
  }

  @media only screen and (max-width: 400px) {
    .v-btn {
      padding: auto;
      width: auto !important;
    }
    .roundedButton {
      margin-bottom: 20px;
    }
    .transactionFlex {
      display: block;
    }

    .transactionHistory {
      font-size: 19px;
    }

    .deposit {
      background: #feeaf3;
      color: #ef4444;
      padding: 8px 8px;
      font-weight: 500;
      font-size: 14px;
      width: 110px;
      text-align: center;
    }
  }
}
</style>
      