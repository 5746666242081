<template>
  <v-main>
    <div class="reconcileContainer">
      <div>
        <v-breadcrumbs :items="items" divider=">" />
        <h1 class="ml-5">Logged Issues</h1>
      </div>

      <div v-if="!isuser" class="d-flex">
        <div>
          <v-text-field
            style="max-width: 590px"
            class="searchField mt-5"
            label="Search for users"
            single-line
            prepend-inner-icon="mdi-magnify"
          />
        </div>
        <v-spacer></v-spacer>

        <div class="mr-4 mt-7">
          <!-- Payment type filter -->
          <MenuFilter
            @filter="setPaymentType"
            :type="'Payment Type'"
            :title="paymentType"
            :data="FILTERPAYMENTS"
          />
        </div>
      </div>

      <v-window v-model="step">
        <v-window-item :value="1">
          <TableComponent :checkbox="true" :columns="columns">
            <tbody v-for="(transaction, i) in loggedReversals" :key="i">
              <tr class="text-center">
                <td><v-checkbox /></td>
                <td>{{ transaction.transactionId }}</td>
                <td>{{ transaction.customerWalletNo }}</td>
                <td>{{ transaction.customerName }}</td>
                <td>{{ transaction.amount }}</td>
                <td :class="transaction.status">{{ transaction.status }}</td>
                <td>{{ transaction.createdDate | dateFormatter }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="viewUser(transaction)">
                        View User
                      </v-list-item>

                      <v-list-item @click="initiateReversal(transaction)">
                        Initiate Reversal
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </TableComponent>
        </v-window-item>
        <v-window-item :value="2">
          <v-btn text @click="step = 1"
            ><v-icon small>mdi-arrow-left</v-icon> Back</v-btn
          >
          <SingleProfileCard
            v-if="isuser"
            :adminKyc="users.singleUserKyc"
            :loading="users.loading"
          />
        </v-window-item>
      </v-window>
    </div>

    <v-dialog persistent max-width="500px" v-model="reversal">
      <ReversalModal
        @closeModal="closeModal"
        :transactiondetails="transactiondetails"
      />
    </v-dialog>

    <OverlayLoader :loading="loading" :description="'Sending OTP...'" />
  </v-main>
</template>
        
        
        
<script>
import { BRANDCOLOR, FILTERPAYMENTS } from "@/constants/constants";
import TableComponent from "../Misc/TableComponent.vue";
import { dateFormatter } from "@/utils/formatter";
import Vue from "vue";
import MenuFilter from "@/elements/MenuFilter.vue";
import SingleProfileCard from "../SingleUser/SingleProfileCard.vue";
import { mapState } from "vuex";
import ReversalModal from "./Modals/ReversalModal.vue";
import OverlayLoader from "../Misc/OverlayLoader.vue";
import { handleError } from "@/utils/handleErrors";
import api from "@/services/api";

Vue.filter("dateFormatter", dateFormatter);

export default {
  props: {
    reversals: {
      type: Array,
    },
    loggedReversals: {
      type: Array,
    },
    grandTotalReversal: {
      type: [Number, String],
    },
    totalCurrentMonthReversal: {
      type: [Number, String],
    },
  },
  components: {
    TableComponent,
    ReversalModal,
    MenuFilter,
    SingleProfileCard,
    OverlayLoader,
  },
  data() {
    return {
      BRANDCOLOR,
      columns: [
        "Transaction Id",
        "Wallet Number",
        "Name",
        "Amount",
        "Status",
        "Date",
        "Action",
      ],
      loading: false,
      reversal: false,
      createlog: false,
      step: 1,
      title: "Reversal",
      items: [
        { text: "Reversal", href: "reversal" },
        { text: "Logged Issues", href: "logged-issues" },
      ],
      FILTERPAYMENTS,
      search: "",
      paymentType: "",
      isuser: false,
      transactiondetails: {},
    };
  },

  computed: {
    ...mapState(["users"]),
  },

  methods: {
    handleReversal() {
      this.reversal = !this.reversal;
    },
    closeModal() {
      this.reversal = !this.reversal;
    },
    viewUser(transaction) {
      this.title = "Logged Issues";
      this.isuser = true;
      this.step = 2;
      this.$store.dispatch(
        "users/fetchSingleUserKyc",
        transaction.customerWalletNo
      );
    },
    setPaymentType(type) {
      if (type == "All") {
        location.reload();
      }
      this.search = type;
      this.paymentType = type;
    },

    async initiateReversal(transaction) {
      this.loading = true;
      try {
        const response = await api.postRequest(
          "local-transfer/localtransgermgt/external-reversal-initiate-process-external-reversal",
          {
            walletNo: transaction.customerWalletNo,
            transactionId: transaction.transactionId,
            emailAddress: transaction.emailAddress,
          }
        );

        this.transactiondetails = response.data.data;
        this.loading = false;
        this.reversal = !this.reversal;
      } catch (error) {
        handleError(error.message);
      }
    },
  },
};
</script>
        
<style scoped>
.searchField >>> .v-input__slot::before {
  border-style: none !important;
}

.reversal-buttons > * {
  margin: 10px 10px 0px 0px;
}
.reconcile__title__flex {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 20px 0px;
}
.stat-grid {
  display: grid;
  grid-template-columns: auto auto;
}

@media only screen and (min-width: 600px) {
  .reversal-buttons {
    display: flex;
  }
}
</style>
        
        
        