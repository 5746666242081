import api from "@/services/api";
import { handleError } from "@/utils/handleErrors";

export default {
  namespaced: true,
  state: {
    loading: false,
    promotions: []
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_PROMOTIONS(state,value){
        state.promotions = value
    }
  },
  actions: {
    fetchPromotions: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest("profiling/walletmgt/getSlides");
        commit("SET_PROMOTIONS", response.data.data.slides.slice(0,10));
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
  },
};
