<template>
  <v-main>
    <div class="transactionsContainer">
      <v-breadcrumbs divider=">"></v-breadcrumbs>
    </div>
    <div class="transactionsContainer">
      <h1>Campaigns</h1>
    </div>

    <v-card flat class="transactionCardContainer">
      <div>
        <v-tabs v-model="tab" background-color="transparent">
          <v-tabs-slider color="#0582D2"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item" class="text-capitalize">
            {{ item }}
          </v-tab>
        </v-tabs>
        <hr class="line" />

        <div class="d-flex">
          <div class="mr-4 mt-7"></div>
        </div>
      </div>

      <v-tabs-items v-model="tab">
        <!-- All campaigns tab -->
        <v-tab-item>
          <v-card flat class="mr-6">
            <CampaignTable
              v-if="crowdfunding"
              :loading="loading"
              :crowdfunding="crowdfunding"
              :tab="'all'"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item class="mr-6">
          <!-- Withdrawal tab -->
          <CampaignTable
            v-if="crowdfunding"
            :loading="loading"
            :crowdfunding="
              crowdfunding.filter(
                (project) =>
                  project.cashOutRequestApproval === '1' ||
                  project.cashOutRequestApproval === '2'
              )
            "
            :tab="'withdrawal'"
            @approveWithdrawal="approveWithdrawal"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <OverlayLoader :loading="overlayloading" :description="'Loading...'" />
  </v-main>
</template>
    
    
    
<script>
import CampaignTable from "./CampaignTable.vue";
import OverlayLoader from "@/components/Misc/OverlayLoader.vue";
import { HEADERS, POSTURL } from "@/constants/constants";
import axios from "axios";
import { handleError } from '@/utils/handleErrors';
export default {
  name: "CrowdFundingBody",
  components: { CampaignTable, OverlayLoader },
  data() {
    return {
      tab: null,
      items: ["Requests", "Withdrawal"],
      overlayloading: false,
    };
  },

  computed: {},

  props: {
    crowdfunding: {
      type: Array,
    },
    user: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },

  methods: {
    approveWithdrawal(project) {
      this.overlayloading = true;
      axios({
        method: "POST",
        url: POSTURL + "settlements/onetimesettlementmgt/crowd-funding/payout",
        headers: HEADERS,
        data: {
          walletNo: project.walletNo,
          adminWalletNo: this.user.phone,
          transactionId: project.transactionId,
        },
      })
        .then((response) => {
          this.overlayloading = false;
          if (response.data.statusCode == 400) {
            this.$swal({
              icon: "error",
              title: response.data.description,
            });
            return;
          }
          location.reload();
        })
        .catch((error) => {
          console.log(error)
          handleError(error.message)
          this.overlayloading = false;
        });
    },
  }
};
</script>
    
<style scoped>
.v-breadcrumbs {
  font-weight: 500 !important;
  color: black !important;
  padding: 0px;
}

.v-breadcrumbs >>> a {
  color: #383b43 !important;
  font-size: 14px !important;
}

.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
.transactionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}

.transactionCardContainer {
  margin: 0px 0px 44px 0px;
}
.tabContainer {
  padding: 20px 0px 0px 0px;
  border-bottom: 2px solid;
}
.button {
  padding: 40px 0px;
  border-radius: 10px;
}

.line {
  margin-top: -1.3px;
  height: 1px;
  /* border: 0.001em solid #acb0b9; */
  border: none;
  background: #efefef;
}
</style>
    
    
    