<template>
  <div v-if="password != ''">
    <v-chip
      :color="lowerColor"
      class="mr-2"
      label
      :text-color="lowerText"
      style="font-size: 10px; border-radius: 8px"
    >
      <v-icon :color="lowerColorIcon" left> mdi-check-circle </v-icon>
      Lowercase letter
    </v-chip>
    <v-chip
      :color="upperColor"
      label
      class="mr-2"
      :text-color="upperText"
      style="font-size: 10px; border-radius: 8px"
    >
      <v-icon :color="upperColorIcon" left> mdi-check-circle </v-icon>
      Uppercase letter
    </v-chip>
    <v-chip
      :color="numberColor"
      label
      :text-color="numberText"
      style="font-size: 10px; border-radius: 8px"
    >
      <v-icon :color="numberColorIcon" left> mdi-check-circle </v-icon>
      Number
    </v-chip>
    <v-chip
      :color="specialColor"
      label
      :text-color="specialText"
      style="font-size: 10px; border-radius: 8px"
      class="specialChip"
    >
      <v-icon :color="specialColorIcon" left> mdi-check-circle </v-icon>
      Special character
    </v-chip>

    <v-chip
      :color="lengthColor"
      label
      :text-color="lengthText"
      style="font-size: 10px; border-radius: 8px"
      class="ml-2 mt-2"
    >
      <v-icon :color="lengthColorIcon" left> mdi-check-circle </v-icon>
      Password must be more than 8 character
    </v-chip>
  </div>
</template>



<script>
export default {
  data() {
    return {
      lowerColor: "#F9F9F9",
      lowerText: "#B7C1C0",
      lowerColorIcon: "#B7C1C0",
      upperColor: "#F9F9F9",
      upperText: "#B7C1C0",
      upperColorIcon: "#B7C1C0",
      specialColor: "#F9F9F9",
      specialText: "#B7C1C0",
      specialColorIcon: "#B7C1C0",
      lengthColor: "#F9F9F9",
      lengthText: "#B7C1C0",
      lengthColorIcon: "#B7C1C0",
      numberColor: "#F9F9F9",
      numberText: "#B7C1C0",
      numberColorIcon: "#B7C1C0",
    };
  },

  props: {
    password: {
      type: String,
    },
  },
  methods: {
    passwordValidate() {
      let lower = /[a-z]/.test(this.password);
      let upper = /[A-Z]/.test(this.password);
      let number = /[0-9]/.test(this.password);
      let length = this.password.length >= 8;
      let special = /[!@#$%^&*)(+=._-]/.test(this.password);
      if (lower) {
        this.lowerColor = "#cdf2fc";
        this.lowerColorIcon = "#0066f5";
        this.lowerText = "#0066f5";
      } else {
        this.lowerColor = "#F9F9F9";
        this.lowerColorIcon = "#B7C1C0";
        this.lowerText = "#B7C1C0";
      }
      if (upper) {
        this.upperColor = "#cdf2fc";
        this.upperColorIcon = "#0066f5";
        this.upperText = "#0066f5";
      } else {
        this.upperColor = "#F9F9F9";
        this.upperColorIcon = "#B7C1C0";
        this.upperText = "#B7C1C0";
      }
      if (number) {
        this.numberColor = "#cdf2fc";
        this.numberColorIcon = "#0066f5";
        this.numberText = "#0066f5";
      } else {
        this.numberColor = "#F9F9F9";
        this.numberColorIcon = "#B7C1C0";
        this.numberText = "#B7C1C0";
      }
      if (special) {
        this.specialColor = "#cdf2fc";
        this.specialColorIcon = "#0066f5";
        this.specialText = "#0066f5";
      } else {
        this.specialColor = "#F9F9F9";
        this.specialColorIcon = "#B7C1C0";
        this.specialText = "#B7C1C0";
      }

      if (length) {
        this.lengthColor = "#cdf2fc";
        this.lengthColorIcon = "#0066f5";
        this.lengthText = "#0066f5";
      } else {
        this.lengthColor = "#F9F9F9";
        this.lengthColorIcon = "#B7C1C0";
        this.lengthText = "#B7C1C0";
      }
    },
  },
};
</script>