<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <span v-if="type">{{ type }}:</span> {{ title }}
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-for="(option, i) in data" :key="i">
        <v-list-item @click="filter(option)">
          <v-list-item-title>{{ option }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>


<script>
export default {
  props: {
    type: {
      type: String,
    },
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
    user: {
      type: Object,
    },
  },
  methods: {
    filter(e) {
      this.$emit("filter", e, this.user);
    },
  },
};
</script>