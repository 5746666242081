export const statusColor = (status) => {
  switch (status) {
    case "In-Review":
      return "#fef3c7";
    case "Approved":
      return "#CDE8CD";
    case "Rejected":
      return "#FEE2E2";
  }
};
