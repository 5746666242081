var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"whiteBackground"},[_c('v-card',{staticClass:"adminVerificationCard",attrs:{"flat":""}},[_c('div',{staticClass:"adminVerificationCardHeader"},[_c('h2',[_vm._v(_vm._s(_vm.adminKyc.firstName))]),_c('p',[_vm._v(_vm._s(_vm.userMeta.tierCategory))])]),_c('div',{staticClass:"kyclist"},_vm._l((_vm.kycs),function(kyc,i){return _c('div',{key:i},[_c('div',{staticClass:"kycListItem"},[_c('div',[_c('h4',[_vm._v(" "+_vm._s(kyc.title)+" "),_c('v-chip',{class:kyc.status.toLowerCase(),attrs:{"small":""}},[_vm._v(_vm._s(kyc.status.toUpperCase()))])],1),(kyc.title == 'Utility Bill')?_c('div',[(kyc.data != null)?_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","color":"#0582d2"},on:{"click":_vm.showUtilityBill}},[_vm._v(_vm._s(_vm.buttonText))]):_vm._e(),(_vm.showBill)?_c('img',{attrs:{"width":"400px","src":'data:image/jpg;base64,' + kyc.data}}):_vm._e()],1):_vm._e(),(kyc.title == 'Business Information')?_c('div',{staticClass:"mb-4"},[(kyc.data != null)?_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","color":"#0582d2"},on:{"click":_vm.showBusinessPhoto}},[_vm._v(_vm._s(_vm.businessText))]):_vm._e(),(_vm.showBusiness)?_c('img',{attrs:{"width":"400px","src":'data:image/png;base64,' + kyc.data}}):_vm._e(),_c('div',{staticClass:"businessDetails"},[_c('p',{staticClass:"font-weight-medium"},[_vm._v("Business Name")]),_c('p',[_vm._v(_vm._s(kyc.businessName))])]),_c('div',{staticClass:"businessDetails"},[_c('p',{staticClass:"font-weight-medium"},[_vm._v("Address")]),_c('p',[_vm._v(_vm._s(kyc.address))])]),_c('div',{staticClass:"businessDetails"},[_c('p',{staticClass:"font-weight-medium"},[_vm._v("State, City, LGA")]),_c('p',[_vm._v(_vm._s(kyc.state)+" "+_vm._s(kyc.city)+" "+_vm._s(kyc.lga))])])],1):_vm._e()]),(
              kyc.title == 'Utility Bill' ||
              kyc.title == 'Business Information'
            )?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","dark":"","text":"","loading":_vm.verifyloading}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.verifyBill(kyc.title)}}},[_vm._v(" Verify ")]),_c('v-list-item',{on:{"click":function($event){return _vm.declineBill(kyc.title)}}},[_vm._v(" Decline ")])],1)],1)],1):_vm._e()])])}),0)]),_c('v-dialog',{attrs:{"persistent":"","width":"580px"},model:{value:(_vm.actionutil),callback:function ($$v) {_vm.actionutil=$$v},expression:"actionutil"}},[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card',{staticClass:"pa-6"},[_c('div',{staticClass:"my-3 d-flex justify-space-between"},[_c('h2',[_vm._v(_vm._s(_vm.actionTitle)+" Reason")]),_c('v-btn',{attrs:{"fab":"","small":"","depressed":""},on:{"click":function($event){_vm.actionutil = !_vm.actionutil}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-text-field',{staticStyle:{"height":"55px"},attrs:{"dense":"","outlined":"","placeholder":_vm.actionTitle == 'Approve'
                ? 'e.g the utility bill matches user details'
                : 'e.g the utility bill does not match user details'},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"outlined":"","large":"","color":"black"},on:{"click":function($event){_vm.actionutil = !_vm.actionutil}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.verifyloading,"large":"","color":"#0582d2"},on:{"click":function($event){return _vm.actionBill()}}},[_vm._v(_vm._s(_vm.actionTitle))])],1)],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('successScreen',{attrs:{"buttonText":'Continue',"title":'Utility bill ' + _vm.actionTitle + 'd successfully',"description":'You have successfully ' + _vm.actionTitle + 'd this utility bill'},on:{"closeSuccess":_vm.closeSuccess}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"580px"},model:{value:(_vm.businessutil),callback:function ($$v) {_vm.businessutil=$$v},expression:"businessutil"}},[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card',{staticClass:"pa-6"},[_c('div',{staticClass:"my-3 d-flex justify-space-between"},[_c('h2',[_vm._v(_vm._s(_vm.actionTitle)+" Reason")]),_c('v-btn',{attrs:{"fab":"","small":"","depressed":""},on:{"click":function($event){_vm.businessutil = !_vm.businessutil}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-text-field',{staticStyle:{"height":"55px"},attrs:{"dense":"","outlined":"","placeholder":_vm.actionTitle == 'Approve'
                ? 'e.g the Business matches user details'
                : 'e.g the Business does not match user details'},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"outlined":"","large":"","color":"black"},on:{"click":function($event){_vm.businessutil = !_vm.businessutil}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.verifyloading,"large":"","color":"#0582d2"},on:{"click":function($event){return _vm.actionBusiness()}}},[_vm._v(_vm._s(_vm.actionTitle))])],1)],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('successScreen',{attrs:{"buttonText":'Continue',"title":'Business registration ' + _vm.actionTitle + 'd successfully',"description":'You have successfully ' + _vm.actionTitle + 'd this business'},on:{"closeSuccess":function($event){_vm.businessutil = false}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }