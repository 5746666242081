<template>
  <div>
    <HeaderComponent :title="'Initiate reversal'" @closeBtn="closeModal" />

    <HeaderCard
      :title="'Transaction Amount'"
      :value="parseFloat(amount)"
      :color="'#F3F4F6'"
      :textColor="'black'"
      :borderRadius="'10px'"
    />

    <v-form>
      <div class="my-4">
        <label>Upload Evidence (Kuleanpay)</label>
        <DragAndDrop @setImage="setKuleanpayEvidence" />
      </div>

      <div>
        <label>Upload Evidence (Providus)</label>
        <DragAndDrop @setImage="setProvidusEvidence" />
      </div>

      <div class="mt-4">
        <label>Wallet Account</label>
        <v-text-field v-model="walletAccount" dense outlined />
      </div>

      <div>
        <label>Reversal Reason</label>
        <v-text-field v-model="reason" outlined style="height: 40px" />
      </div>
    </v-form>

    <div class="grid-button">
      <v-btn
        depressed
        large
        :color="'#F0F5FD'"
        class="black--text rounded-lg"
        @click="prevStep"
      >
        Back</v-btn
      >
      <v-btn
        depressed
        large
        :color="BRANDCOLOR"
        class="white--text rounded-lg"
        @click="submitForm"
        :loading="loading"
      >
        Submit</v-btn
      >
    </div>
  </div>
</template>
    
    
<script>
import HeaderComponent from "@/components/Misc/HeaderComponent.vue";
import { ADMINUSER, BRANDCOLOR } from "@/constants/constants";
import DragAndDrop from "@/components/Misc/DragAndDrop.vue";
import HeaderCard from "@/components/Misc/HeaderCard.vue";
import api from "@/services/api";
import { handleError, handleResponse } from "@/utils/handleErrors";
export default {
  components: {
    HeaderCard,
    HeaderComponent,
    DragAndDrop,
  },
  props: {
    amount: {
      type: String,
    },
    processId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      BRANDCOLOR: BRANDCOLOR,
      reason: "",
      walletAccount: "",
      kuleanPayEvidence: "",
      providusEvidence: "",
      loading: false,
    };
  },
  methods: {
    prevStep() {
      this.$emit("prevStep");
    },

    setKuleanpayEvidence(image) {
      this.kuleanPayEvidence = image;
    },

    setProvidusEvidence(image) {
      this.providusEvidence = image;
    },
    async submitForm() {
      try {
        this.loading = true;
        const response = await api.postRequest(
          "local-transfer/localtransgermgt/reversals-request",
          {
            reversalAmount: this.amount,
            kuleanPayEvidence: this.kuleanPayEvidence,
            providusEvidence: this.providusEvidence,
            walletAccount: this.walletAccount,
            reversalReasons: this.reason,
            processId: this.processId,
            emailAddress: ADMINUSER.email,
          }
        );
        handleResponse(response, { reload: true });
        this.loading = false;
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>


<style scoped>
.grid-button {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  margin: 20px 0px;
}
</style>