<template>
  <DashboardLayout>
    <NotificationsBody
      :userNotifications="notifications.notifications"
      :isNotifications="notifications.loading"
    />
  </DashboardLayout>
</template>
    
    
    
    
<script>
import NotificationsBody from "../components/Notifications/NotificationsBody.vue";
import { mapState } from "vuex";
import DashboardLayout from "@/components/Layout/DashboardLayout.vue";

export default {
  components: {
    NotificationsBody,
    DashboardLayout,
  },

  metaInfo() {
    return {
      title: `Notifications`,
      titleTemplate: "Notifications | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: "Notifications | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://app.kuleanpay.com/notifications",
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },

  computed: {
    ...mapState(["notifications"]),
  },

  created() {
    this.$store.dispatch("notifications/fetchPendingKyc");
  },
};
</script>
 
