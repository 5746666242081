<template>
  <div>
    <HeaderComponent :title="'Initiate reversal'" @closeBtn="closeModal" />

    <v-form @submit.prevent="nextStep">
      <div class="mb-6">
        <label>Enter Reference Number</label>
        <v-text-field
          v-model="referencenumber"
          outlined
          dense
          style="height: 55px"
        />
      </div>

      <v-btn
        depressed
        x-large
        :color="BRANDCOLOR"
        block
        class="white--text rounded-lg"
        @click="nextStep"
        :disabled="!referencenumber"
        :loading="loading"
      >
        Next</v-btn
      >
    </v-form>
  </div>
</template>
  
  
<script>
import HeaderComponent from "@/components/Misc/HeaderComponent.vue";
import { ADMINUSER, BRANDCOLOR } from "@/constants/constants";
import api from "@/services/api";
import { handleError, handleResponse } from "@/utils/handleErrors";
export default {
  components: {
    HeaderComponent,
  },
  data() {
    return {
      BRANDCOLOR: BRANDCOLOR,
      disabled: true,
      loading: false,
      referencenumber: "",
    };
  },
  methods: {
    async nextStep() {
      try {
        this.loading = true;
        const response = await api.postRequest(
          "local-transfer/localtransgermgt/initiate-process-reversal",
          {
            transactionId: this.referencenumber,
            emailAddress: ADMINUSER.email,
          }
        );
        this.loading = false;
        handleResponse(response, { reload: false });
        const data = response.data.data;
        if (response.data.statusCode === 200) {
          this.$emit("nextStep", data);
        }
      } catch (error) {
        this.loading = false;
        handleError(error.message);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>