<template>
  <v-main>
    <div class="transactionsContainer">
      <h1>Transactions</h1>
    </div>

    <v-card flat class="mr-4" color="white">
      <div class="d-flex">
        <div>
          <v-text-field
            style="max-width: 590px"
            class="searchField mt-5"
            label="Search transactions"
            single-line
            prepend-inner-icon="mdi-magnify"
          />
        </div>
        <v-spacer></v-spacer>

        <div class="mr-4 mt-7">
          <!-- Date filter -->

          <!-- Status filter -->

          <!-- Role filter -->
        </div>
      </div>
      <TransactionsLoading v-if="loading" />
      <TransactionsTable
        v-if="!loading"
        :escrow="filteredEscrow"
        :total="total"
        :page="page"
      />
    </v-card>
  </v-main>
</template>
  
  
  
  <script>
import TransactionsTable from "./TransactionsTable.vue";
import TransactionsLoading from "./TransactionsLoading.vue";

export default {
  data() {
    return {
      dateCreated: "All",
      status: "All",
      role: "Level 1",
      search: "",
    };
  },
  components: { TransactionsTable, TransactionsLoading },
  props: {
    transactions: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    page: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },
  methods: {},
  computed: {
    filteredEscrow() {
      const searchEscrow = this.search.toLowerCase().trim();
      if (!searchEscrow) return this.transactions;
      return this.transactions.filter(
        (transaction) =>
          transaction.transactionId.toLowerCase().indexOf(searchEscrow) > -1
      );
    },
  },
};
</script>
  
  <style scoped>
.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
.transactionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 44px 0px;
}

.transactionCardContainer {
  margin: 0px 90px 44px 0px;
}
.tabContainer {
  padding: 20px 0px 0px 24px;
}
.button {
  padding: 40px 0px;
  border-radius: 10px;
}
</style>
  
  
  