import Vue from "vue";
import Vuex from "vuex";
import admin from "./admin";
import reconciliation from "./reconciliation";
import transaction from "./transaction";
import users from "./users";
import crowdfunding from "./crowdfunding";
import payment from "./payment";
import notifications from "./notifications";
import graph from "./graph";
import promotion from "./promotion";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    reconciliation,
    transaction,
    payment,
    crowdfunding,
    users,
    notifications,
    graph,
    promotion
  },
});
