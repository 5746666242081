<template>
  <v-main>
    <div class="reconcileContainer">
      <div class="reconcile__title__flex">
        <div>
          <v-breadcrumbs :items="items" :divider="'>'" />
          <h1>External Reversal</h1>
        </div>
        <div>
          <v-btn
            @click="handleReversal"
            depressed
            outlined
            class="mr-3 blue--text"
          >
            <v-icon class="mr-2">mdi-sort-variant</v-icon> Filter</v-btn
          >
        </div>
      </div>
      <div class="stat-grid">
        <StatCard
          title="Wallet Balance"
          :loading="loading"
          :value="grandTotalReversal === '[null]' ? '0' : grandTotalReversal"
          :type="'currency'"
        />
        <StatCard
          title="Total Reversals"
          :loading="loading"
          :value="
            totalCurrentMonthReversal === '[null]' ? '0' : grandTotalReversal
          "
          :type="'currency'"
        />
      </div>

      <v-text-field
        class="searchField mt-5"
        prepend-inner-icon="mdi-magnify"
        style="max-width: 70%"
        placeholder="Search for users"
      />
      <TableComponent :checkbox="true" :columns="columns">
        <tbody v-for="(transaction, i) in reversals" :key="i">
          <tr class="text-center">
            <td><v-checkbox /></td>
            <td>{{ transaction.transactionId }}</td>
            <td>{{ transaction.paymentType }}</td>
            <td>{{ transaction.senderName }}</td>
            <td>{{ transaction.ammount }}</td>
            <td>{{ transaction.createdDate | dateFormatter }}</td>
            <!-- <td>
              <div v-if="project.approval !== '1'">
                <v-btn @click="showCampaign(project)" color="#068B03" text
                  ><v-icon>mdi-check</v-icon>Approve</v-btn
                >
                <v-btn @click="showCampaign(project)" color="#DC2626" text
                  ><v-icon>mdi-close</v-icon>Decline</v-btn
                >
              </div>
              <v-menu v-if="project.approval === '1'" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showCampaign(project)">
                    View Campaign
                  </v-list-item>
                  <v-list-item
                    v-if="
                      tab === 'withdrawal' && project.cashOutRequestApproval !== '2'
                    "
                  >
                    Approve Withdrawal
                  </v-list-item>
                </v-list>
              </v-menu>
            </td> -->
          </tr>
        </tbody>
      </TableComponent>
    </div>

    <v-dialog persistent max-width="500px" v-model="reversal">
      <ReversalModal @closeModal="closeModal" />
    </v-dialog>
  </v-main>
</template>
        
        
        
  <script>
import { BRANDCOLOR } from "@/constants/constants";
import StatCard from "../Dashboard/Cards/StatCard.vue";
import TableComponent from "../Misc/TableComponent.vue";
import ReversalModal from "./Modals/ReversalModal.vue";
import { dateFormatter } from "@/utils/formatter";
import Vue from "vue";

Vue.filter("dateFormatter", dateFormatter);

export default {
  props: {
    reversals: {
      type: Array,
    },
    grandTotalReversal: {
      type: [Number, String],
    },
    totalCurrentMonthReversal: {
      type: [Number, String],
    },
  },
  components: {
    StatCard,
    TableComponent,
    ReversalModal,
  },
  data() {
    return {
      BRANDCOLOR,
      columns: [
        "Transaction Id",
        "Payment Type",
        "Customer Name",
        "Amount",
        "Date",
      ],
      loading: false,
      crowdfunding: [],
      reversal: false,
      items: [
        { text: "Reversal", href: "reversal" },
        { text: "External Reversal", href: "external-reversal" },
      ],
    };
  },
  methods: {
    handleReversal() {
      this.reversal = !this.reversal;
    },
    closeModal() {
      this.reversal = !this.reversal;
    },
  },
};
</script>
        
<style scoped>
.reconcile__title__flex {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 0px;
  padding: 0px 0px 20px 0px;
}
.stat-grid {
  display: grid;
  grid-template-columns: auto auto;
}

.v-breadcrumbs {
  font-weight: 500 !important;
  color: black !important;
  padding: 0px;
}

.v-breadcrumbs >>> a {
  color: #383b43 !important;
  font-size: 14px !important;
}
</style>
        
        
        