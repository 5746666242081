<template>
  <v-main>
    <div class="transactionsContainer">
      <h1>Push Notification</h1>
      <div>
        <v-btn
          @click="addNewNotification"
          large
          color="#0582D2"
          class="button white--text"
          ><v-icon>mdi-plus</v-icon>New Message</v-btn
        >
      </div>
    </div>

    <v-card flat class="mr-4" color="white">
      <div class="d-flex">
        <div>
          <v-text-field
            v-model="search"
            style="max-width: 590px"
            class="searchField mt-5"
            label="Search message"
            single-line
            prepend-inner-icon="mdi-magnify"
          />
        </div>
        <v-spacer></v-spacer>

        <div class="mr-4 mt-7">
          <!-- Date filter -->
          <MenuFilter
            :data="FILTERDATES"
            @filter="filterDate"
            :type="'Date'"
            :title="dateCreated"
          />
        </div>
      </div>

      <PushNotificationLoading v-if="loading" />
      <PushNotificationTable
        v-if="!loading"
        :notifications="filteredNotifications"
      />
    </v-card>

    <v-dialog persistent v-model="newmessage" max-width="580px">
      <AddNotification @closeNotification="addNewNotification" />
    </v-dialog>
  </v-main>
</template>
  
  
  
  <script>
import PushNotificationTable from "./PushNotificationsTable.vue";
import PushNotificationLoading from "./PushNotificationsLoading.vue";
import AddNotification from "./AddNotification";
import MenuFilter from "@/elements/MenuFilter.vue";
import { FILTERDATES } from "@/constants/constants";

export default {
  data() {
    return {
      dateCreated: "All",
      status: "All",
      role: "Level 1",
      search: "",
      newmessage: false,
      FILTERDATES,
    };
  },
  props: {
    notifications: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    PushNotificationTable,
    PushNotificationLoading,
    AddNotification,
    MenuFilter,
  },
  computed: {
    filteredNotifications() {
      const searchNotification = this.search.toLowerCase().trim();
      if (!searchNotification) return this.notifications;
      return this.notifications.filter(
        (notification) =>
          notification.headings.en.toLowerCase().indexOf(searchNotification) >
          -1
      );
    },
  },
  methods: {
    addNewNotification() {
      this.newmessage = !this.newmessage;
    },

    filterDate(e) {
      console.log(e);
    },
  },
};
</script>
  
  <style scoped>
.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
.transactionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 44px 0px;
}

.transactionCardContainer {
  margin: 0px 90px 44px 0px;
}
.tabContainer {
  padding: 20px 0px 0px 24px;
}
.button {
  padding: 40px 0px;
  border-radius: 10px;
}
</style>
  
  
  