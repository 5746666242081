<template>
  <v-main>
    <div style="height: 100%" class="hidden-md-and-down">
      <v-row style="height: 100%">
        <v-col cols="12" md="1" sm="1" lg="1">
          <SideBar />
        </v-col>

        <v-col cols="12" md="11" sm="11" lg="11">
          <AppBar />
          <hr class="line" />
          <div style="padding: 0px 0px 0px 90px">
            <div style="margin: 30px 0px 30px 90px">
              <slot></slot>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="hidden-lg-and-up">
      <SideBar />
      <div style="padding: 30px 10px 30px 20px">
        <slot></slot>
      </div>
    </div>
  </v-main>
</template>
  
  
  
  
<script>
import SideBar from "../Dashboard/SideBar.vue";
import AppBar from "../Dashboard/AppBar.vue";

export default {
  components: {
    SideBar,
    AppBar,
  },
};
</script>
  
  
  

  
<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>