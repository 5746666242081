import api from "@/services/api";
import { handleError } from "@/utils/handleErrors";

export default {
  namespaced: true,
  state: {
    lastUsers: [],
    userpage: 0,
    paginatedusers: [],
    users: [],
    loading: false,
    savedUsers: [],
    userCount: 0,
    singleUserKyc: {},
    startDate: "",
    endDate: "",
    filter: "",
  },
  getters: {},
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_USER_COUNT(state, value) {
      state.userCount = value;
    },
    SET_PAGINATED_USERS(state, value) {
      state.paginatedusers = value;
    },
    SET_SAVED_USERS(state, value) {
      state.savedUsers = value;
    },
    SET_LAST_USERS(state, value) {
      state.lastUsers = value;
    },
    SET_ALL_USERS(state, value) {
      state.users = value;
    },
    SET_USER_PAGINATION(state, value) {
      state.userpage = value;
    },

    SET_USER_KYC(state, value) {
      state.singleUserKyc = value;
    },

    SET_FILTER_DATES(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    },

    SET_FILTER(state, value) {
      state.filter = value;
    },
  },
  actions: {
    fetchUsersCount: async (context) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-user-count"
        );
        context.commit("SET_USER_COUNT", response.data.data.count);
      } catch (error) {
        handleError(error.message);
      }
    },

    fetchPaginatedUsers: async ({ rootState, commit }) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-all-users?pageNo=" +
            rootState.users.userpage +
            "&pageSize=10"
        );
        commit("SET_PAGINATED_USERS", response.data.data.users);
        commit("SET_SAVED_USERS", response.data.data.users);
      } catch (error) {
        handleError(error.message);
      }
    },

    fetchLastUsers: async ({ commit }) => {
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-last10-users"
        );
        commit("SET_LAST_USERS", response.data.data.last10Users);
      } catch (error) {
        handleError(error.message);
      }
    },

    setUserPagination: async (context, value) => {
      try {
        context.commit("SET_LOADING", true);
        const response = await api.getRequest(
          "profiling/usermgt/get-all-users?pageNo=" +
            value +
            "%26pageSize=" +
            10
        );
        context.commit(
          "SET_PAGINATED_USERS",
          response.data.data.users.reverse()
        );
        context.commit("SET_USER_PAGINATION", value);
        context.commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        context.commit("SET_LOADING", false);
      }
    },
    searchUsers: async ({ commit, rootState }, value) => {
      commit("SET_LOADING", true);
      if (!value) {
        commit("SET_PAGINATED_USERS", rootState.users.savedUsers);
        commit("SET_LOADING", false);
        return;
      }
      try {
        const response = await api.getRequest(
          "profiling/usermgt/search-users-like?search=" + value
        );
        commit("SET_PAGINATED_USERS", response.data.data.users);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },

    filterTier: async ({ commit, rootState }, value) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest(
          `profiling/usermgt/get-all-users-by-tier?tier=${value}&pageNo=${rootState.users.userpage}&pageSize=10`
        );
        commit("SET_PAGINATED_USERS", response.data.data.users);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },

    filterDate: async ({ commit, rootState }, { startDate, endDate }) => {
      commit("SET_LOADING", true);
      try {
        const response = await api.getRequest(
          `profiling/usermgt/find-users-between-dates?fromDate=${startDate}%26toDate=${endDate}%26pageNo=${rootState.users.userpage}&pageSize=10`
        );
        commit("SET_FILTER_DATES", { startDate, endDate });
        commit("SET_PAGINATED_USERS", response.data.data.users);
        commit("SET_USER_COUNT", response.data.data.total_transactions);
        commit("SET_LOADING", false);
        commit("SET_FILTER", "date");
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },

    setUserDatePagination: async ({ commit, rootState }, value) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest(
          `profiling/usermgt/find-users-between-dates?fromDate=${rootState.users.startDate}%26toDate=${rootState.users.endDate}%26pageNo=${value}&pageSize=10`
        );
        commit("SET_PAGINATED_USERS", response.data.data.users);
        commit("SET_USER_PAGINATION", value);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },

    fetchSingleUserKyc: async ({ commit }, value) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest(
          "profiling/walletmgt/get-verification-statuses?walletNo=" + value
        );

        commit("SET_USER_KYC", response.data.data.status);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
  },
};
