<template>
  <h2 class="d-flex justify-space-between">
    {{ title }}
    <v-btn small @click="closeModal" class="closeButton" fab depressed
      ><v-icon>mdi-close</v-icon></v-btn
    >
  </h2>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>