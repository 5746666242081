<template>
  <div class="py-8 tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th class="font-weight-medium">Name</th>
          <th class="font-weight-medium">Email Address</th>
          <th class="font-weight-medium">Permission Level</th>
          <th class="font-weight-medium">Last Active</th>
          <th class="font-weight-medium">Action</th>
        </tr>
      </thead>

      <tbody v-for="(t, i) in admin" :key="i">
        <tr class="text-center">
          <td>{{ t.firstName }} {{ t.lastName }}</td>
          <td>{{ t.emailAddress }}</td>
          <td>
            <MenuFilter
              @filter="changeRole"
              :title="title[i] ? title[i] : t.userGroup | getRole(groups)"
              :data="roles"
              :user="t"
            />
          </td>
          <td>{{ t.modified | dateFormatter }}</td>
          <td>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="black" dark v-bind="attrs" v-on="on" text>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="t.enabled"
                  class="red--text"
                  @click="restrictUser(t)"
                >
                  Restrict Account
                </v-list-item>
                <v-list-item
                  v-if="!t.enabled"
                  class="blue--text"
                  @click="unRestrictUser(t)"
                >
                  Unrestrict Account
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </table>

    <OverlayLoader :loading="loading" :description="'Updating...'" />

    <v-dialog width="580px" v-model="suspenduser">
      <AreYouSure
        :loading="restrictloading"
        :title="`${action} user: ${user.firstName}`"
        :description="`Are you sure you want to ${action} this user?`"
        @handleProceed="handleRestrict"
        @closeModal="suspenduser = false"
      />
    </v-dialog>
  </div>
</template>
    
    
    
    
    
<script>
import Vue from "vue";
import MenuFilter from "@/elements/MenuFilter.vue";
import { dateFormatter, getRole } from "@/utils/formatter";
import { handleError, handleResponse } from "@/utils/handleErrors";
import OverlayLoader from "../Misc/OverlayLoader.vue";
import AreYouSure from "../Misc/AreYouSure.vue";
import api from "@/services/api";
Vue.filter("dateFormatter", dateFormatter);
Vue.filter("getRole", getRole);

export default {
  props: {
    admin: {
      type: Array,
    },
    roles: {
      type: Array,
    },
    groups: {
      type: Array,
    },
  },
  components: { MenuFilter, OverlayLoader, AreYouSure },
  data() {
    return {
      title: [],
      loading: false,
      action: "",
      user: {},
      restrictloading: false,
      suspenduser: false
    };
  },
  methods: {
    async changeRole(e, user) {
      try {
        this.loading = true;
        const response = await api.postRequest(
          "profiling/usermgt/modify-user-group",
          {
            emailAddess: user.emailAddress,
            userGroupName: e,
          }
        );
        handleResponse(response, { reload: true });
        this.loading = false;
      } catch (error) {
        handleError(error.message);
      }
    },

    restrictUser(t) {
      this.user = t;
      this.suspenduser = true;
      this.action = "restrict";
    },

    unRestrictUser(t) {
      this.user = t;
      this.suspenduser = true;
      this.action = "unrestrict";
    },

    handleRestrict() {
      this.restrictloading = true;
      if (this.action === "restrict") {
        this.handleSuspend();
        return;
      }
      if (this.action === "unrestrict") {
        this.handleUnsuspend();
        return;
      }
    },

    async handleSuspend() {
      try {
        this.restrictloading = true;
        const response = await api.getRequest(
          "profiling/usermgt/suspend-user?phoneNumber=" +
            this.user.uniqueIdentification
        );
        handleResponse(response, {reload: true});
        this.restrictloading = false;
      } catch (error) {
        handleError(error.message);
        this.restrictloading = false;
      }
    },

    async handleUnsuspend() {
      this.restrictloading = true;
      try {
        const response = await api.getRequest(
          "profiling/usermgt/unsuspend-user?phoneNumber=" +
            this.user.uniqueIdentification
        );
        handleResponse(response, {reload: true});
        this.restrictloading = false;
      } catch (error) {
        handleError(error.message);
        this.restrictloading = false;
      }
    },
  },
};
</script>
    
<style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px !important;
  background: #f9fafb;
}

td {
  font-size: 14px;
  padding: 10px !important;
  text-align: center;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
}

.checkBoxPosition {
  margin: 0px 0px 0px 11px;
}
</style>
    