<template>
  <div class="tableWidth">
    <table cellspacing="0">
      <thead class="tableHeading">
        <tr>
          <th v-if="checkbox"><v-checkbox /></th>
          <th
            class="font-weight-medium"
            v-for="(column, i) in columns"
            :key="i"
          >
            {{ column }}
          </th>
        </tr>
      </thead>
      <slot></slot>
      <TableLoader v-if="loading" :columns="8" />
    </table>
  </div>
</template>

  <script>
export default {
  props: {
    columns: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    checkbox: {
      type: Boolean,
      default: false
    },
  },
};
</script>


   
<style scoped>
.tableWidth {
  overflow-x: auto;
}

.tableHeading {
  background: #f9fafb;
  width: 100%;
}
table {
  width: 100%;
  margin: 30px 0px;
}

.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 2px solid #f9fafb;
}
.tableHeading th {
  font-size: 14px;
  padding: 20px 10px !important;
  background: #f9fafb;
  text-align: start;
}

td {
  font-size: 14px;
  padding: 10px !important;
  text-align: start;
}

.active {
  color: #1c6bff;
}

.pending {
  color: #f59e0b;
}

.completed {
  color: #22c55e;
}

.v-date-picker-title,
.v-picker__title,
.v-date-picker-header {
  display: none !important;
}
.v-date-picker-title {
  color: pink !important;
}

@media screen and (min-width: 1300px) {
  .pickerStyle {
    width: 360px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-pagination {
  background: red;
  box-shadow: none !important;
}
.border {
  outline: 30px !important;
  background: red;
}

.checkBoxPosition {
  margin: 14px 0px 0px 11px;
}

.brandcolor {
  color: #0582d2;
}
</style>
    