<template>
  <v-card>
    <div class="d-flex justify-space-between">
      <v-btn @click="prevStep" small fab depressed
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <v-btn @click="closeBtn" small fab depressed
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <div>
      <HeaderCard
        :title="'Variance'"
        :value="5865346"
        :color="'#B91C1C'"
        :textColor="'white'"
        :borderRadius="'10px 10px 0px 0px'"
      />
      <div class="confirm__reconcile__details">
        Variance = Pool - (Total wallet balance + investment fund + commission
        earned + paystack fund not paid - other charges)
      </div>
    </div>

    <v-btn class="rounded-lg" large block depressed color="#F0F5FD">
      Continue
    </v-btn>
  </v-card>
</template>


<script>
import HeaderCard from "@/components/Misc/HeaderCard.vue";

export default {
  components: {
    HeaderCard,
  },
  methods: {
    prevStep() {
      this.$emit("prevStep");
    },
    closeBtn() {
      this.$emit("closeBtn");
    },
  },
};
</script>


<style scoped>
.confirm__reconcile__heading {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  color: white;
  margin: 15px 0px 0px 0px;
}

.confirm__reconcile__details {
  background: #f9fafb;
  text-align: center;
  padding: 15px;
  margin-bottom: 40px;
}
</style>