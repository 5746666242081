<template>
  <v-app>
    <v-main class="promotionsContainer">
      <div class="promotionsHeading">
        <h2>Promotions</h2>
        <v-btn @click="createPromotion" class="primarybtn"
          ><v-icon>mdi-plus</v-icon>New Promotion</v-btn
        >
      </div>

      <v-progress-circular
        size="50"
        color="#0582d2"
        indeterminate
        v-if="loading"
      ></v-progress-circular>

      <div class="promoSlider">
        <div v-for="(slide, i) in promotions" :key="i">
          <div class="relative">
            <v-menu transition="slide-x-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="absolute" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon></v-btn
                ></template
              >
              <v-list>
                <v-list-item @click="deleteButton(slide)"> Delete </v-list-item>
              </v-list>
            </v-menu>
            <v-img class="slideImage" :src="slide.url" />
          </div>
        </div>
      </div>

      <v-dialog persistent max-width="580px" v-model="newpromotion">
        <CreatePromotion @createPromotion="createPromotion" />
      </v-dialog>
    </v-main>

    <v-overlay opacity="0.8" v-model="deleteloading">
      <v-progress-circular
        size="50"
        color="white"
        indeterminate
      ></v-progress-circular>
      <p>Deleting...</p>
    </v-overlay>
  </v-app>
</template>



<script>
import CreatePromotion from "./CreatePromotion.vue";
import axios from "axios";
import { DELETEURL, HEADERS } from "@/constants/constants";
import { handleError } from "@/utils/handleErrors";
export default {
  components: {
    CreatePromotion,
  },
  props: {
    promotions: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data: () => ({
    newpromotion: false,
    deleteloading: false,
  }),
  methods: {
    createPromotion() {
      this.newpromotion = !this.newpromotion;
    },
    deleteButton(slide) {
      this.deleteloading = true;
      axios({
        method: "DELETE",
        url: DELETEURL + "profiling/usermgt/deleteSlide",
        headers: HEADERS,
        data: {
          slideName: slide.slideName,
        },
      })
        .then((response) => {
          this.deleteloading = false;
          if (response.data.statusCode == 400) {
            this.$swal({
              icon: "error",
              title: "Error",
              text: response.data.description,
              confirmButtonText: `<p class=brandcolor>Ok</p>`,
            });
          }
          this.$swal({
            icon: "success",
            title: "Slide Deleted Successfully",
            text: response.data.description,
            confirmButtonText: `<p class=brandcolor>Ok</p>`,
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
        })
        .catch((error) => {
          handleError(error);
          this.deleteloading = false;
        });
    },
  },
};
</script>



<style scoped>
@media only screen and (max-width: 600px) {
  .promotionsContainer {
    padding: 20px !important;
  }

  .promotionsHeading {
    display: flex;
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 776px) {
}

@media only screen and (min-width: 894px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 996px) {
  .promotionsContainer {
    padding: 0px 20px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

.primarybtn {
  color: white !important;
  background: #0582d2 !important;
  padding: 10px 16px !important;
  border-radius: 8px;
}

.dragAndDropContainer {
  border: 1px dashed #0582d2;
  background: #f5fbff;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  padding: 24px 0px;
  position: relative;
}
.hidden-input {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}

.promoSlider {
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 20px;
}

.slideImage {
  width: 354.67px;
  height: 152px;
  margin: 0px 20px 0px 0px;
  border-radius: 8px;
}

.deleteButton {
  margin: -9px 0px 0px 0px;
  text-align: center;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 24px;
  z-index: 1;
}
</style>