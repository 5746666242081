<template>
  <v-card class="createPermissionCard" max-width="580px">
    <h2 class="d-flex justify-space-between">
      Add Member
      <v-btn @click="closeModal" class="closeButton" fab depressed
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </h2>
    <v-row>
      <v-col>
        <label>First Name</label>
        <v-text-field v-model="firstName" outlined dense />
      </v-col>

      <v-col>
        <label>Last Name</label>
        <v-text-field v-model="lastName" outlined dense />
      </v-col>
    </v-row>

    <label>Email</label>
    <v-text-field v-model="emailAddress" outlined dense />

    <label>Phone</label>
    <v-text-field v-model="phoneNumber" outlined dense />

    <label>Password</label> <v-text-field v-model="password" outlined dense />
    <label>Confirm Password</label
    ><v-text-field v-model="confPassword" outlined dense />

    <label>Group Name</label>
    <v-select
      outlined
      dense
      :items="groups"
      item-text="userGroupName"
      item-value="userGroupId"
      v-model="userGroup"
    ></v-select>

    <v-btn
      :loading="loading"
      @click="createAdminUser"
      block
      large
      color="#0582D2"
      class="rounded-lg white--text"
      depressed
      >Invite</v-btn
    >
  </v-card>
</template>

<script>
import api from "@/services/api";
import { handleError, handleResponse } from "@/utils/handleErrors";

export default {
  props: {
    groups: {
      type: Array,
    },
  },
  data: () => ({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    confPassword: "",
    userGroup: "",
    loading: false,
  }),
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async createAdminUser() {
      this.loading = true;
      try {
        const response = await api.postRequest(
          "profiling/usermgt/create-user",
          {
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            emailAddress: this.emailAddress,
            password: this.password,
            confPassword: this.confPassword,
            userGroup: this.userGroup,
            userName: this.firstName,
          }
        );
        handleResponse(response, { reload: true });
      } catch (error) {
        handleError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>