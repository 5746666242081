<template>
  <DashboardLayout>
    <DashboardBody
      :userCount="users.userCount"
      :users="users.lastUsers"
      :transactions="transaction.recenttransactions"
      :alltransactions="transaction.alltransactions"
      :pendingtransactions="transaction.pendingtransactions"
      :activetransactions="transaction.activetransactions"
      :completetransactions="transaction.completetransactions"
      :transactionGraph="graph.graphTransactions"
      :userGraph="graph.graphUsers"
      :isUserGraph="graph.isUserGraph"
      :isTransactionGraph="graph.isTransactionGraph"
    />
  </DashboardLayout>
</template>




<script>
import { DashboardMixin } from "@/mixins/Dashboard/DashboardMixins";
export default {
  mixins: [DashboardMixin],
};
</script>






<style scoped>
</style>