<template>
  <div
    class="header__card"
    :style="`background:${color};color:${textColor};border-radius:${borderRadius}`"
  >
    <div>
      <div>{{ title }}</div>
      <h2>NGN {{ value.toLocaleString("en") }}</h2>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
    },
    value: {
      type: Number,
    },
    color: {
      type: String,
    },
    textColor: {
      type: String,
    },
    borderRadius: {
      type: String,
    },
  },
};
</script>


<style scoped>
.header__card {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 15px;
  margin: 15px 0px 0px 0px;
}
</style>