<template>
  <v-main>
    <div class="transactionsContainer">
      <v-breadcrumbs :items="links" divider=">"></v-breadcrumbs>
    </div>
    <div class="transactionsContainer">
      <h1>{{ adminKyc.firstName }}</h1>
    </div>

    <v-card flat class="transactionCardContainer">
      <div>
        <v-tabs v-model="tab" background-color="transparent">
          <v-tabs-slider color="#0582D2"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item" class="text-capitalize">
            {{ item }}
          </v-tab>
        </v-tabs>
        <hr class="line" />

        <div class="d-flex">
          <div class="mr-4 mt-7"></div>
        </div>
      </div>

      <v-tabs-items v-model="tab">
        <!-- Profile tab -->
        <v-tab-item>
          <v-card flat>
            <SingleProfileCard :adminKyc="adminKyc" :loading="loading" />
          </v-card>
        </v-tab-item>

        <!-- Verification tab -->
        <v-tab-item>
          <v-card color="#F8F8F9" flat>
            <div>
              <VerificationCard :adminKyc="adminKyc" :loading="loading" />
            </div>
          </v-card>
        </v-tab-item>

        <!-- Payout tab -->
      </v-tabs-items>
    </v-card>
  </v-main>
</template>
    
    
    
<script>
import SingleProfileCard from "./SingleProfileCard.vue";
import VerificationCard from "./VerificationCard.vue";

export default {
  data() {
    return {
      tab: null,
      items: ["Profile", "Verification"],
      links: [
        {
          text: "users",
          disabled: false,
          href: "/users",
        },
        {
          text: this.$route.query.phoneNumber,
          disabled: false,
          href: this.$route.query.phoneNumber,
        },
      ],
    };
  },

  created() {},

  components: { SingleProfileCard, VerificationCard },

  watch: {},

  methods: {},
  props: {
    adminKyc: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>
    
    <style scoped>
.v-breadcrumbs {
  font-weight: 500 !important;
  color: black !important;
  padding: 0px;
}

.v-breadcrumbs >>> a {
  color: #383b43 !important;
  font-size: 14px !important;
}

.searchField >>> .v-input__slot::before {
  border-style: none !important;
}
.transactionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}

.transactionCardContainer {
  margin: 0px 0px 44px 0px;
}
.tabContainer {
  padding: 20px 0px 0px 0px;
  border-bottom: 2px solid;
}
.button {
  padding: 40px 0px;
  border-radius: 10px;
}

.line {
  margin-top: -1.3px;
  height: 1px;
  /* border: 0.001em solid #acb0b9; */
  border: none;
  background: #efefef;
}
</style>
    
    
    