<template>
  <v-window v-model="step">
    <v-window-item :value="1">
      <v-card class="card">
        <div class="d-flex justify-space-between">
          <h2>
            {{ action === "suspend" ? "Suspend" : "Unsuspend" }}
            {{ user.firstName }} {{ user.lasName }}
          </h2>
          <v-btn @click="closeModal" small fab depressed>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <p class="my-3">
          Are you sure you want to
          {{ action === "suspend" ? "Suspend" : "Unsuspend" }}
          {{ user.firstName }} {{ user.lasName }}? This action will prevent the
          user from fully using their Kuleanpay account
        </p>

        <div class="spacebuttons">
          <v-btn @click="closeModal" color="#0284c7" outlined
            >No, don't
            {{ action === "suspend" ? "Suspend" : "Unsuspend" }}</v-btn
          >
          <v-btn
            :loading="loading"
            @click="suspendUser"
            color="#0284c7"
            class="white--text"
            >{{ action === "suspend" ? "Suspend" : "Unsuspend" }}
            {{ user.firstName }}</v-btn
          >
        </div>
      </v-card>
    </v-window-item>
    <v-window-item :value="2">
      <success-screen
        :title="
          user.firstName +
          ' has been ' +
          (action === 'suspend' ? 'suspended' : 'unsuspended')
        "
        :description="
          user.firstName +
          ' ' +
          '' +
          ' has successfully been ' +
          (action === 'suspend' ? 'suspended' : 'unsuspended')
        "
        @closeSuccess="closeSuccess"
        :buttonText="'Continue'"
      />
    </v-window-item>
  </v-window>
</template>


<script>
import successScreen from "@/components/Modal/successScreen.vue";
import axios from "axios";
import { GETURL, HEADERS } from "@/constants/constants";
import { handleError } from '@/utils/handleErrors';
export default {
  data() {
    return {
      loading: false,
      step: 1,
    };
  },
  components: { successScreen },
  props: {
    user: {
      type: Object,
    },
    action: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    closeSuccess() {
      location.reload();
    },
    suspendUser() {
      this.loading = true;
      if (this.action === "suspend") {
        this.handleSuspend();
        return;
      }
      if (this.action === "unsuspend") {
        this.handleUnsuspend();
        return;
      }
    },

    handleSuspend() {
      axios({
        method: "GET",
        url:
          GETURL +
          "profiling/usermgt/suspend-user?phoneNumber=" +
          this.user.uniqueIdentification,
        headers: HEADERS,
      })
        .then((response) => {
          this.loading = false;
          if (response.data.statusCode === 200) {
            this.step++;
          } else {
            this.$swal({
              icon: "error",
              title: "Error",
              text: response.data.description,
              confirmButtonText: `<p class=brandcolor>Ok</p>`,
            });
          }
        })
        .catch((error) => {
          handleError(error.message)
          this.loading = false;
        });
    },

    handleUnsuspend() {
      axios({
        method: "GET",
        url:
          GETURL +
          "profiling/usermgt/unsuspend-user?phoneNumber=" +
          this.user.uniqueIdentification,
        headers: HEADERS,
      })
        .then((response) => {
          this.loading = false;
          if (response.data.statusCode === 200) {
            this.step++;
          } else {
            this.$swal({
              icon: "error",
              title: "Error",
              text: response.data.description,
              confirmButtonText: `<p class=brandcolor>Ok</p>`,
            });
          }
        })
        .catch((error) => {
          handleError(error.message)
          this.loading = false;
        });
    },
  },
};
</script>



<style scoped>
.card {
  padding: 26px 40px;
}

.spacebuttons > * {
  margin: 12px 12px 12px 0px;
}
</style>