<template>
  <v-card class="pa-6">
    <v-window v-model="step">
      <v-window-item :value="1">
        <ReconcileForm
          @nextStep="nextStep"
          @closeModal="closeModal"
          :totalWalletBalance="totalWalletBalance"
          :totalCommissionEarned="totalCommissionEarned"
          :transaction="transaction"
        />
      </v-window-item>
      <v-window-item :value="2">
        <ConfirmReconcile @closeBtn="closeModal" @prevStep="prevStep" />
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import ReconcileForm from "./ReconcileForm.vue";
import ConfirmReconcile from "./ConfirmReconcile.vue";

export default {
  props: {
    totalCommissionEarned: {
      type: [Number, String],
    },
    totalWalletBalance: {
      type: [Number, String],
    },
    transaction: {
      type: Object,
    },
  },
  components: {
    ReconcileForm,
    ConfirmReconcile,
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    prevStep() {
      this.step--;
    },
    nextStep() {
      this.step++;
    },
  },
};
</script>


<style scoped>
</style>