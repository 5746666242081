<template>
  <v-card flat class="cardStyle">
    <p class="titleStyle">{{ title }}</p>
    <h2 v-if="value == ''" class="numberStyle">
      <v-progress-circular indeterminate />
    </h2>
    <h2 v-if="value != ''" class="numberStyle">
      {{ type === "currency" ? "NGN" : "" }} {{ parseFloat(value).toLocaleString("en") }}
    </h2>
  </v-card>
</template>




<script>
export default {
  props: {
    title: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    type: {
      type: String,
    },
  },
};
</script>


<style scoped>
@media only screen and (max-width: 768px) {
  .cardStyle {
    min-width: 190px;
    text-align: center;
  }
}
.cardStyle {
  height: 124px;
  padding: 24px 16px;
  background: #f8f8f9;
  border-radius: 8px;
  margin: 0px 24px 0px 0px;
  flex: 0 0 auto;
  text-align: center;
}

.titleStyle {
  color: #6b7280;
  font-size: 14px;
  font-weight: 500;
}

.numberStyle {
  color: #383b43;
  font-weight: 600;
}
</style>