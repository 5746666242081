<template>
  <div>
    <v-overlay opacity="0.8" v-if="loading">
      <v-progress-circular
        size="50"
        color="white"
        indeterminate
      ></v-progress-circular>
      <p>{{ description }}</p>
    </v-overlay>
  </div>
</template>


<script>
export default {
  props: {
    loading: Boolean,
    description: String,
  },
};
</script>