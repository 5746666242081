var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card',{staticClass:"card"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',[_vm._v(" "+_vm._s(_vm.action === "suspend" ? "Suspend" : "Unsuspend")+" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lasName)+" ")]),_c('v-btn',{attrs:{"small":"","fab":"","depressed":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('p',{staticClass:"my-3"},[_vm._v(" Are you sure you want to "+_vm._s(_vm.action === "suspend" ? "Suspend" : "Unsuspend")+" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lasName)+"? This action will prevent the user from fully using their Kuleanpay account ")]),_c('div',{staticClass:"spacebuttons"},[_c('v-btn',{attrs:{"color":"#0284c7","outlined":""},on:{"click":_vm.closeModal}},[_vm._v("No, don't "+_vm._s(_vm.action === "suspend" ? "Suspend" : "Unsuspend"))]),_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loading,"color":"#0284c7"},on:{"click":_vm.suspendUser}},[_vm._v(_vm._s(_vm.action === "suspend" ? "Suspend" : "Unsuspend")+" "+_vm._s(_vm.user.firstName))])],1)])],1),_c('v-window-item',{attrs:{"value":2}},[_c('success-screen',{attrs:{"title":_vm.user.firstName +
        ' has been ' +
        (_vm.action === 'suspend' ? 'suspended' : 'unsuspended'),"description":_vm.user.firstName +
        ' ' +
        '' +
        ' has successfully been ' +
        (_vm.action === 'suspend' ? 'suspended' : 'unsuspended'),"buttonText":'Continue'},on:{"closeSuccess":_vm.closeSuccess}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }