<template>
  <div
    id="drop_zone"
    class="dragAndDropContainer"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div v-if="files.length == 0" class="text-center d-flex justify-center">
      <div v-if="isDragging">Release to drop files here.</div>
      <div><v-icon>mdi-plus</v-icon></div>
      <div>
        Drag and drop file or
        <v-btn
          text
          class="button"
          @click="changeImage"
          :style="`color:${BRANDCOLOR}`"
        >
          upload
        </v-btn>
      </div>
      <input
        type="file"
        name="file"
        ref="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
      />
    </div>

    <div v-for="file in files" :key="file.name" class="preview-card">
      <div class="d-flex justify-space-between">
        <p>
          <v-icon small color="#0582D2"> mdi-check </v-icon>
          {{ file.name }}
        </p>
        <v-btn text color="#0582D2" @click="changeImage">Change</v-btn>
        <input
          type="file"
          name="file"
          ref="changefile"
          id="fileInput"
          hidden
          @change="changeImages"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { BRANDCOLOR } from "@/constants/constants";

export default {
  name: "DragAndDrop",
  data: () => ({
    newpromotion: false,
    isDragging: false,
    files: [],
    step: 1,
    image: "",
    error: "",
    alert: false,
    loading: false,
    BRANDCOLOR,
  }),
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
      //set image reader
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.$emit("setImage", this.image);
      };
      reader.readAsDataURL(this.files[0]);
    },
    changeImages() {
      this.files = [...this.$refs.changefile[0].files];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.$emit("setImage", this.image);
      };
      reader.readAsDataURL(this.files[0]);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    changeImage() {
      this.$refs.changefile[0].click();
    },
    goToPreview() {
      if (this.files.length > 0) {
        this.step++;
      } else {
        this.alert = true;
        this.error = "You need to upload an image";
      }
    },
  },
};
</script>



<style scoped>
@media only screen and (max-width: 600px) {
  .promotionsContainer {
    padding: 20px !important;
  }

  .promotionsHeading {
    display: flex;
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 894px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 996px) {
  .promotionsContainer {
    padding: 0px 20px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) {
  .promotionsContainer {
    padding: 0px 40px 0px 0px !important;
  }
  .promotionsHeading {
    display: flex;
    justify-content: space-between;
  }
}

.primarybtn {
  color: white !important;
  background: #0582d2 !important;
  padding: 24px 16px !important;
  border-radius: 8px;
}

.dragAndDropContainer {
  border: 1px dashed #d7d7d7;
  background: white;
  border-radius: 10px;
  padding: 15px 0px 0px 0px;
  position: relative;
}

.dragAndDropContainer .preview-card {
  padding: 0px 10px;
}
.hidden-input {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}
.imageContainer {
  padding: 0px;
}
.outlinedbtn {
  padding: 24px 16px !important;
}
</style>