import { ADMINUSER, GETURL } from "@/constants/constants";
import api from "@/services/api";
import { getRole } from "@/utils/formatter";
import { handleError, handleResponse } from "@/utils/handleErrors";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    alladminusers: [],
    adminUsers: [],
    roles: [],
    groups: [],
    loading: false,
    statementlist: [],
    rolespermission: [],
    grouptypes: [],
    allroles: [],
    permissions: [],
    user: {},
  },
  getters: {},
  mutations: {
    SET_USER(state, value) {
      state.user = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_ADMIN_USERS(state, value) {
      state.adminUsers = value;
    },

    SET_ALL_ADMIN_USERS(state, value) {
      state.alladminusers = value;
    },

    SET_ALL_GROUPS(state, value) {
      state.groups = value;
      state.grouptypes = value.map((item) => item.userGroupType);
    },

    SET_ALL_ROLES(state, value) {
      state.roles = value;
    },

    SET_ROLES_PERMISSION(state, value) {
      state.rolespermission = value;
    },

    SET_PERMISSIONS(state, value) {
      state.permissions = value;
    },

    SET_BANK_STATEMENT(state, value) {
      state.statementlist = value;
    },
  },
  actions: {
    fetchAdminUser: ({ commit }) => {
      commit("SET_USER", JSON.parse(sessionStorage.getItem("userData")));
    },
    fetchAdminUsers: async ({ commit }) => {
      commit("SET_LOADING", true);
      try {
        const response = await api.getRequest(
          "profiling/usermgt/get-admin-users"
        );
        commit("SET_LOADING", false);
        commit("SET_ADMIN_USERS", response.data.data["admin-users"]);
        commit("SET_ALL_ADMIN_USERS", response.data.data["admin-users"]);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },

    fetchAdminUserRole: async ({ commit }, value) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios({
          method: "GET",
          url: GETURL + "profiling/usermgt/get-current-user-group-roles",
          headers: {
            channel: "Web",
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${value}`,
          },
        });

        sessionStorage.setItem(
          "adminroles",
          JSON.stringify(response.data.data)
        );
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },

    filterAdminUsers: ({ rootState, commit }, value) => {
      commit(
        "SET_ADMIN_USERS",
        rootState.admin.alladminusers.filter(
          (user) => value === getRole(user.userGroup, rootState.admin.groups)
        )
      );
    },

    searchAdminUsers: ({ rootState, commit }, value) => {
      if (value)
        commit(
          "SET_ADMIN_USERS",
          rootState.admin.alladminusers.filter(
            (user) => user.firstName.toLowerCase().indexOf(value) > -1
          )
        );
      else {
        commit("SET_ADMIN_USERS", rootState.admin.alladminusers);
      }
    },

    fetchPermissions: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest("profiling/usermgt/get-roles");
        commit("SET_PERMISSIONS", response.data.data.roles);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
    fetchAllGroups: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest("profiling/usermgt/get-groups");
        commit("SET_ALL_GROUPS", response.data.data.groups);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
    fetchAllRoles: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await api.getRequest(
          "profiling/usermgt/get-roles-groups"
        );
        commit(
          "SET_ALL_ROLES",
          Object.keys(response.data.data["group-roles"][0])
        );
        // set the permissions for this roles
        commit("SET_ROLES_PERMISSION", response.data.data["group-roles"]);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
    fetchBankStatement: async ({ commit }, value) => {
      try {
        const response = await api.postRequest(
          "profiling/walletmgt/get-account-statement-api",
          {
            startDate: value.startDate,
            endDate: value.endDate,
            userId: ADMINUSER.phone,
          }
        );
        handleResponse(response, { reload: false });
        commit("SET_BANK_STATEMENT", response.data.data);
      } catch (error) {
        handleError(error.message);
      }
    },
  },
};
