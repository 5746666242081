import Vue from "vue";
export const handleError = (message) => {
  Vue.prototype.$toast.clear();
  Vue.prototype.$toast.error(message, {
    icon: true,
  });
};

export const handleSuccess = (message) => {
  Vue.prototype.$toast.clear();
  Vue.prototype.$toast.success(message, {
    icon: true,
  });
};

export const handleResponse = (response, reload) => {
  const isReload = reload.reload;
  if (response.data.statusCode === 400) {
    handleError(response.data.description);
  } else {
    handleSuccess(response.data.description);
    if (isReload) {
      setTimeout(() => {
        location.reload();
      }, 1500);
    }
  }
};
