<template>
  <v-card class="chartStyle" flat>
    <div class="d-flex justify-space-between">
      <!-- <h2>Transactions</h2> -->
      <!-- <v-select :items="days"></v-select> -->
    </div>
    <v-container>
      <line-chart :chart-data="datacollection" :options="options"></line-chart>
    </v-container>
  </v-card>
</template>

<script>
import LineChart from "./LineChart.js";
export default {
  name: "ChartComponent",
  components: {
    LineChart,
  },
  data() {
    return {
      chartOptions: {
        backgroundColor: 'linear-gradient(180deg, #112EC8 -0.37%, rgba(17, 46, 200, 0) 100%)',
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: 20,
          bodyFontSize: 25,
        },
        scales: {
          xAxes: [],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 1, // Adjust this value to control the curvature of the lines
          },
        },
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
      },
    };
  },
  props: {
    datacollection: {
      type: [Object, Array],
    },
  },
};
</script>



<style scoped>
.chartStyle {
  border-radius: 8px;
  margin: 60px 40px 0px 0px;
}
</style>