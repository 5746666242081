import { ADMINUSER, HEADERS, POSTURL } from "@/constants/constants";
import { handleError } from "@/utils/handleErrors";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    reconciliations: [],
    processId: "",
    totalCommissionEarned: 0,
    totalWalletBalance: 0,
    transactions: [],
    loading: false,
    reversals: [],
    grandTotalReversal: 0,
    totalCurrentMonthReversal: 0,
    loggedReversals: [],
    externalReversals: [],
  },
  getters: {},
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_RECONCILIATION(state, value) {
      const { mapAll, processId, totalCommissionEarned, totalWalletBalance } =
        value;
      state.processId = processId;
      state.totalCommissionEarned = totalCommissionEarned;
      state.transactions = mapAll;
      state.totalWalletBalance = totalWalletBalance;
    },

    SET_REVERSAL(state, value) {
      const { mapAll, grandTotalReversal, totalCurrentMonthReversal } = value;
      state.reversals = mapAll;
      state.grandTotalReversal = grandTotalReversal;
      state.totalCurrentMonthReversal = totalCurrentMonthReversal;
    },

    SET_EXTERNAL_REVERSAL(state, value) {
      state.externalReversals = value;
    },

    SET_LOGGED_REVERSAL(state, value) {
      state.loggedReversals = value;
    },
  },
  actions: {
    fetchReconciliations: ({ commit }) => {
      commit("SET_LOADING", true);
      axios({
        method: "POST",
        url:
          POSTURL +
          "local-transfer/localtransgermgt/get-all-transactions-for-reconciliations",
        headers: HEADERS,
        data: {
          emailAddress: ADMINUSER.email,
        },
      })
        .then((response) => {
          commit("SET_RECONCILIATION", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          handleError(error.message);
        });
    },

    fetchReversals: ({ commit }) => {
      commit("SET_LOADING", true);
      axios({
        method: "POST",
        url:
          POSTURL +
          "local-transfer/localtransgermgt/get-all-reversal-transactions",
        headers: HEADERS,
        data: {
          emailAddress: ADMINUSER.email,
        },
      })
        .then((response) => {
          commit("SET_REVERSAL", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          handleError(error.message);
        });
    },

    fetchExternalReversals: ({ commit }) => {
      commit("SET_LOADING", true);
      axios({
        method: "POST",
        url:
          POSTURL +
          "local-transfer/localtransgermgt/external-reversal-get-all-external-reversal",
        headers: HEADERS,
        data: {
          emailAddress: ADMINUSER.email,
        },
      })
        .then((response) => {
          commit("SET_EXTERNAL_REVERSAL", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          handleError(error.message);
        });
    },

    fetchLoggedReversals: ({ commit }) => {
      commit("SET_LOADING", true);
      axios({
        method: "POST",
        url:
          POSTURL +
          "local-transfer/localtransgermgt/external-reversal-get-all-active-logged-ext-reversal",
        headers: HEADERS,
        data: {
          emailAddress: ADMINUSER.email,
        },
      })
        .then((response) => {
          commit("SET_LOGGED_REVERSAL", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          handleError(error.message);
        });
    },

    // getReconciliationVariance: ({ commit }) => {
    //   axios({
    //     method: "POST",
    //     url:
    //       POSTURL +
    //       "local-transfer/localtransgermgt/get-reconciliation-variance",
    //     headers: HEADERS,
    //     data: {
    //       processId: "",
    //       poolAccountBalance: "",
    //       totalWalletBalance: "",
    //       investmentFund: "",
    //       commissionEarned: "",
    //       paystackFundsNotPaid: "",
    //       otherCharges: "",
    //       emailAddress: "",
    //     },
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       commit("SET_RECONCILIATION", response);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
