<template>
  <div>
    <HeaderComponent :title="'Funds Withdrawal'" @closeBtn="closeModal" />

    <div class="my-3">
      <CenterCard
        :title="'Wallet Balance'"
        :value="transactiondetails.reversalAmount"
        :type="'currency'"
      />
    </div>
    <v-form @submit.prevent="nextStep">
      <div>
        <label>Amount to be deducted</label>
        <v-text-field
          v-model="amount"
          outlined
          dense
          type="number"
          input-mode="numeric"
          style="height: 55px"
        />
      </div>

      <div>
        <label>Destination Wallet number</label>
        <v-text-field
          v-model="walletnumber"
          outlined
          dense
          type="number"
          input-mode="numeric"
          style="height: 10px"
          @keyup="handleNameLookup"
        />
        <div class="brandcolor mb-3"> {{ customerName }}</div>
      </div>

      <div class="mb-6">
        <label>Reason for deduction </label>
        <v-textarea
          v-model="reason"
          outlined
          dense
          height="100px"
          style="margin-bottom: 60px"
        />
      </div>

      <div>
        <label>Upload Evidence (Providus)</label>
        <DragAndDrop @setImage="setKuleanpayEvidence" />
      </div>

      <div class="mt-3">
        <label>Authorization ID</label>
        <v-text-field
          v-model="authorizationKey"
          outlined
          dense
          style="height: 55px"
        />
      </div>

      <div>
        <label>OTP</label>
        <v-text-field
          v-model="otp"
          outlined
          dense
          type="number"
          input-mode="numeric"
          style="height: 55px"
        />

        <button class="mb-3" small text :color="BRANDCOLOR">Resend OTP</button>
      </div>

      <div class="grid">
        <v-btn
          depressed
          x-large
          color="#F0F5FD"
          class="blue--text rounded-lg"
          @click="closeModal"
        >
          Back</v-btn
        >

        <v-btn
          depressed
          x-large
          :color="BRANDCOLOR"
          class="white--text rounded-lg"
          @click="nextStep"
          :loading="loading"
        >
          Submit</v-btn
        >
      </div>
    </v-form>

    <OverlayLoader :loading="loading" />
  </div>
</template>
  
  
<script>
import HeaderComponent from "@/components/Misc/HeaderComponent.vue";
import { ADMINUSER, BRANDCOLOR } from "@/constants/constants";
import api from "@/services/api";
import { handleError, handleResponse } from "@/utils/handleErrors";
import DragAndDrop from "@/components/Misc/DragAndDrop.vue";
import CenterCard from "@/components/Dashboard/Cards/CenterCard.vue";
import OverlayLoader from "@/components/Misc/OverlayLoader.vue";
export default {
  components: {
    HeaderComponent,
    DragAndDrop,
    CenterCard,
    OverlayLoader,
  },
  props: {
    transactiondetails: {
      type: Object,
    },
  },
  data() {
    return {
      BRANDCOLOR: BRANDCOLOR,
      disabled: true,
      loading: false,
      walletnumber: "",
      referencenumber: "",
      requestId: "",
      otp: "",
      amount: "",
      authorizationKey: "",
      reason: "",
      evidence: "",
      customerName: "",
    };
  },
  methods: {
    async handleNameLookup() {
      if (this.walletnumber.length === 11) {
        this.loading = true;
        try {
          const response = await api.postRequest(
            "/local-transfer/localtransgermgt/external-reversal-log-reversal-name-lookUp",
            {
              emailAddress: ADMINUSER.email,
              walletNo: this.walletnumber,
            }
          );
          this.customerName = response.data.data['customerName;'];
          this.loading = false;
        } catch (error) {
          handleError(error);
        }
      }
    },
    async nextStep() {
      try {
        this.loading = true;
        const response = await api.postRequest(
          "local-transfer/localtransgermgt/external-reversal-process-reversal-external",
          {
            requestId: this.transactiondetails.requestId,
            otp: this.otp,
            amount: this.transactiondetails.reversalAmount,
            authorizationKey: this.authorizationKey,
            uploadedEvidence: this.evidence,
            reasonForDeduction: this.reason,
            accountToDebit: this.transactiondetails.customerWalletNumber,
            accountToCredit: this.walletnumber,
            emailAddress: ADMINUSER.email,
            processId: this.transactiondetails.processId,
          }
        );
        this.loading = false;
        handleResponse(response, { reload: false });
        const data = response.data.data;
        if (response.data.statusCode === 200) {
          this.$emit("nextStep", data);
        }
      } catch (error) {
        this.loading = false;
        handleError(error.message);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },

    setKuleanpayEvidence(image) {
      console.log(image);
      this.evidence = image;
    },
  },
};
</script>


<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>